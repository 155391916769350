// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import User from './User'
import {
  MessageDto,
  MessageDtoParentTypeEnum,
  MessageDtoStatusEnum,
} from '~/remote/api-spec'
import { userStore } from '~/store'

export type NormalizedMessage = Omit<MessageDto, 'author'> & {
  authorId: string
}

const messageSchema = new schema.Entity<MessageDto>(
  'messages',
  {
    authorId: User.schema,
  },
  {
    processStrategy: (value: MessageDto) => ({
      ...value,
      authorId: value.author,
    }),
  }
)

export default class Message extends RemoteModel implements NormalizedMessage {
  read!: boolean
  authorId!: string
  parentId!: string
  parentType!: MessageDtoParentTypeEnum
  text?: string
  createdOn!: string
  status!: MessageDtoStatusEnum

  static schema: schema.Entity = messageSchema
  static arraySchema: schema.Array = new schema.Array(messageSchema)

  get author() {
    return userStore.getUserById(this.authorId)!
  }

  get createdOnDate() {
    return new Date(this.createdOn)
  }
}
