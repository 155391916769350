import WorkflowStep from '~/models/WorkflowStep'
import { TimelineStepType, WorkflowStepDtoTypeEnum } from '~/remote/api-spec'

export interface TimelineStepData {
  step: WorkflowStep
  type: TimelineStepType
  timestamp: Date
  isSelf: boolean
  returnedBy?: string
}

export function findOriginalOrderStep(
  steps: WorkflowStep[],
  step: WorkflowStep
): WorkflowStep {
  if (step.type === WorkflowStepDtoTypeEnum.Returned) {
    const s = steps.find((s) => s.id === step.payload?.workflowStepId)
    return findOriginalOrderStep(steps, s!)
  } else if (step.type === WorkflowStepDtoTypeEnum.Approved) {
    if (step.payload && step.payload?.workflowStepId) {
      const s = steps.find((s) => s.id === step.payload?.workflowStepId)
      return findOriginalOrderStep(steps, s!)
    } else {
      const s = steps.find(
        (s) =>
          s.actorId === step.actorId &&
          s.type === WorkflowStepDtoTypeEnum.Assigned
      )
      return s!
    }
  }
  return step
}

export function workflowStepToTimelineStepType(
  step: WorkflowStep,
  allQuotationWorkflowSteps: WorkflowStep[],
  prevStep: WorkflowStep | undefined,
  returnedStep: WorkflowStep | undefined
): TimelineStepType {
  let stepType = step.type
  if (step.payload && step.payload.timelineStepType) {
    if (step.payload.timelineStepType === TimelineStepType.RevokedApproved) {
      stepType = WorkflowStepDtoTypeEnum.Assigned
    } else if (
      step.payload.timelineStepType === TimelineStepType.RegainedApproved
    ) {
      stepType = WorkflowStepDtoTypeEnum.Returned
    } else {
      return step.payload!.timelineStepType!
    }
  }

  switch (stepType) {
    case WorkflowStepDtoTypeEnum.Requested:
      return TimelineStepType.Requested
    case WorkflowStepDtoTypeEnum.InProgress:
      return TimelineStepType.InProgress
    case WorkflowStepDtoTypeEnum.Assigned: {
      const hasApprovedForSameActor = allQuotationWorkflowSteps.some(
        (s) =>
          s.type === WorkflowStepDtoTypeEnum.Approved &&
          s.actorId === step.actorId
      )
      if (
        prevStep &&
        [
          WorkflowStepDtoTypeEnum.Approved,
          WorkflowStepDtoTypeEnum.InProgress,
        ].includes(prevStep.type)
      ) {
        if (
          step.payload &&
          step.payload.timelineStepType &&
          step.payload.timelineStepType === TimelineStepType.RevokedApproved
        ) {
          return TimelineStepType.RevokedApproved
        }
        if (hasApprovedForSameActor) {
          return TimelineStepType.AssignedToReApprove
        } else {
          return TimelineStepType.Assigned
        }
      }
      const returnedBy = returnedStep
        ? returnedStep.payload?.returnedBy
        : undefined
      if (!!returnedBy && step.actorId === returnedBy) {
        return TimelineStepType.WaitingReturnedByThisStep
      }

      if (hasApprovedForSameActor) {
        return TimelineStepType.WaitingToReApprove
      } else {
        return TimelineStepType.WaitingToApprove
      }
    }
    case WorkflowStepDtoTypeEnum.Approved: {
      const originalStep = findOriginalOrderStep(
        allQuotationWorkflowSteps,
        step
      )

      if (originalStep.type === WorkflowStepDtoTypeEnum.InProgress) {
        return TimelineStepType.FixedInProgress
      }

      const hasApprovedForSameActor = allQuotationWorkflowSteps.some(
        (s) =>
          s.type === WorkflowStepDtoTypeEnum.Approved &&
          s.actorId === step.actorId &&
          s.id !== step.id
      )

      if (hasApprovedForSameActor) {
        return TimelineStepType.ReApproved
      } else {
        return TimelineStepType.Approved
      }
    }
    case WorkflowStepDtoTypeEnum.Returned: {
      if (returnedStep && returnedStep.id !== step.id) {
        const returnedBy = returnedStep
          ? returnedStep.payload?.returnedBy
          : undefined
        const isReturnedByThisStep = !!returnedBy && step.actorId === returnedBy
        return isReturnedByThisStep
          ? TimelineStepType.WaitingReturnedByThisStep
          : TimelineStepType.WaitingToReApprove
      }

      const originalStep = findOriginalOrderStep(
        allQuotationWorkflowSteps,
        step
      )
      const isRegain =
        step.payload?.timelineStepType === TimelineStepType.RegainedApproved

      if (originalStep.type === WorkflowStepDtoTypeEnum.InProgress) {
        return isRegain
          ? TimelineStepType.RegainedInProgress
          : TimelineStepType.ReturnedInProgress
      } else {
        return isRegain
          ? TimelineStepType.RegainedApproved
          : TimelineStepType.ReturnedApproved
      }
    }
    case WorkflowStepDtoTypeEnum.Sent:
      return TimelineStepType.Sent
    default:
      return TimelineStepType.Requested
  }
}

export function sortWorkflowSteps(steps: WorkflowStep[]) {
  const order = [
    WorkflowStepDtoTypeEnum.Requested,
    WorkflowStepDtoTypeEnum.InProgress,
    WorkflowStepDtoTypeEnum.Assigned,
    WorkflowStepDtoTypeEnum.Approved,
    WorkflowStepDtoTypeEnum.Returned,
    WorkflowStepDtoTypeEnum.Ready,
    WorkflowStepDtoTypeEnum.Sent,
  ]
  return (stepA: WorkflowStep, stepB: WorkflowStep) => {
    const a = findOriginalOrderStep(steps, stepA)
    const b = findOriginalOrderStep(steps, stepB)
    const orderIndexA = order.indexOf(a.type)
    const orderIndexB = order.indexOf(b.type)

    if (orderIndexA !== orderIndexB) {
      return orderIndexA > orderIndexB ? 1 : -1
    }

    return a.timestampDate.getTime() - b.timestampDate.getTime()
  }
}

/**
 * This function is only for use inside compressWorkflowStepsToTimeline
 */
function compressWorkflowSteps(steps: WorkflowStep[]) {
  const returned = steps.filter(
    (step) => step.type === WorkflowStepDtoTypeEnum.Returned
  )

  const reApproved = steps.filter(
    (step) => step.type === WorkflowStepDtoTypeEnum.Approved && step.payload
  )

  const returnedSteps = returned.map((step) => step.payload?.workflowStepId!)

  const returnedRevokedApprovedSteps = ([] as string[]).concat(
    ...returned.map((step) => step.payload?.revokedStepIds!)
  )

  const reApprovedSteps = reApproved.map(
    (step) => step.payload?.workflowStepId!
  )

  const results = steps
    .filter((step) => {
      return !(
        step.type === WorkflowStepDtoTypeEnum.Ready ||
        returnedSteps.includes(step.id) ||
        returnedRevokedApprovedSteps.includes(step.id) ||
        reApprovedSteps.includes(step.id)
      )
    })
    .sort(sortWorkflowSteps(steps))

  const reApprovedAssignments = results
    .filter((s) => s.type === WorkflowStepDtoTypeEnum.Approved && s.payload)
    .map((s) => findOriginalOrderStep(steps, s))

  const reApprovedAssignmentsIds = reApprovedAssignments.map((s) => s.id)

  return results.filter((step, index, all) => {
    if (reApprovedAssignmentsIds.includes(step.id)) {
      return false
    }
    const prevIndex = index - 1
    const prevStep = prevIndex > -1 ? all[prevIndex] : undefined
    const nextIndex = index + 1
    const nextStep = nextIndex < all.length ? all[nextIndex] : undefined
    if (
      step.type === WorkflowStepDtoTypeEnum.Assigned &&
      prevStep &&
      prevStep.type === WorkflowStepDtoTypeEnum.Returned &&
      step.actorId === prevStep.actorId
    ) {
      const orig = findOriginalOrderStep(steps, prevStep)
      return orig.id !== step.id
    } else if (
      step.type === WorkflowStepDtoTypeEnum.Assigned &&
      nextStep &&
      nextStep.type === WorkflowStepDtoTypeEnum.Returned &&
      step.actorId === nextStep.actorId
    ) {
      const orig = findOriginalOrderStep(steps, nextStep)
      return orig.id !== step.id
    } else if (
      step.type === WorkflowStepDtoTypeEnum.Assigned &&
      all.some(
        (s) =>
          s.type === WorkflowStepDtoTypeEnum.Approved &&
          s.actorId === step.actorId &&
          (s.payload ? s.payload.workflowStepId === step.id : true)
      )
    ) {
      return false
    }
    return true
  })
}

export function compressWorkflowStepsToTimeline(
  steps: WorkflowStep[],
  currentUserId: string
) {
  const compressed = compressWorkflowSteps(steps)
  const returnedStep = compressed.find(
    (s) =>
      s.type === WorkflowStepDtoTypeEnum.Returned &&
      (!s.payload ||
        !s.payload.timelineStepType ||
        s.payload.timelineStepType !== TimelineStepType.RevokedApproved)
  )
  return compressed.map((step, index, all) => {
    const prevStep = index - 1 > -1 ? all[index - 1] : undefined
    const tsType = workflowStepToTimelineStepType(
      step,
      steps,
      prevStep,
      returnedStep
    )
    let timestamp = step.timestampDate
    if (tsType === TimelineStepType.RevokedApproved) {
      const approvedStep = steps.find((s) => s.id === step.id)
      if (approvedStep) {
        timestamp = approvedStep?.timestampDate
      }
    }

    return {
      step,
      type: tsType,
      timestamp,
      isSelf: step.actorId === currentUserId,
      returnedBy: step.payload?.returnedBy,
    }
  })
}

export function getAssignedTotalCount(steps: TimelineStepData[]): number {
  return steps.reduce((sum: number, step: TimelineStepData) => {
    if (
      [
        TimelineStepType.Assigned,
        TimelineStepType.AssignedToReApprove,
        TimelineStepType.RegainedApproved,
        TimelineStepType.WaitingToApprove,
        TimelineStepType.WaitingToReApprove,
        TimelineStepType.WaitingReturnedByThisStep,
        TimelineStepType.ReturnedApproved,
        TimelineStepType.RevokedApproved,
        TimelineStepType.Approved,
        TimelineStepType.ReApproved,
      ].includes(step.type)
    ) {
      sum += 1
    }

    return sum
  }, 0)
}

export function getApprovedCount(steps: TimelineStepData[]): number {
  return steps.reduce((sum: number, step: TimelineStepData) => {
    if (
      [TimelineStepType.Approved, TimelineStepType.ReApproved].includes(
        step.type
      )
    ) {
      sum += 1
    }

    return sum
  }, 0)
}

export function isInReturnedState(steps: TimelineStepData[]): boolean {
  return steps.some((s) =>
    [
      TimelineStepType.RegainedApproved,
      TimelineStepType.RegainedInProgress,
      TimelineStepType.ReturnedApproved,
      TimelineStepType.ReturnedInProgress,
    ].includes(s.type)
  )
}

export function isSelfActiveActor(
  steps: TimelineStepData[],
  currentUserId: string
): boolean {
  const timelineStep = steps.find((s) =>
    [
      TimelineStepType.Assigned,
      TimelineStepType.AssignedToReApprove,
      TimelineStepType.RegainedApproved,
      TimelineStepType.RegainedInProgress,
      TimelineStepType.ReturnedApproved,
      TimelineStepType.ReturnedInProgress,
      TimelineStepType.RevokedApproved,
    ].includes(s.type)
  )
  if (!timelineStep) return false
  return timelineStep.step.actorId === currentUserId
}
