// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import User from './User'
import {
  AllQuotationStepPayloadTypes,
  WorkflowStepDto,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'
import { userStore } from '~/store'

export type NormalizedWorkflowStep = Omit<WorkflowStepDto, 'actor'> & {
  actorId: string
  payload?: AllQuotationStepPayloadTypes
}

export default class WorkflowStep
  extends RemoteModel
  implements NormalizedWorkflowStep
{
  actorId!: string
  timestamp!: string
  type!: WorkflowStepDtoTypeEnum
  quotationId!: string
  payload?: AllQuotationStepPayloadTypes

  static workflowOrder = [
    WorkflowStepDtoTypeEnum.Requested,
    WorkflowStepDtoTypeEnum.InProgress,
    WorkflowStepDtoTypeEnum.Assigned,
    WorkflowStepDtoTypeEnum.Approved,
    WorkflowStepDtoTypeEnum.Returned,
    WorkflowStepDtoTypeEnum.Ready,
    WorkflowStepDtoTypeEnum.Sent,
  ]

  static schema: schema.Entity = new schema.Entity<WorkflowStepDto>(
    'workflowSteps',
    {
      actorId: User.schema,
    },
    {
      processStrategy: (value: WorkflowStepDto) => ({
        ...value,
        actorId: value.actor,
      }),
    }
  )

  get workflowOrderIndex() {
    return WorkflowStep.workflowOrder.indexOf(this.type)
  }

  get actor() {
    return userStore.getUserById(this.actorId)!
  }

  get timestampDate() {
    return new Date(this.timestamp)
  }
}
