import { Vue, Component } from 'nuxt-property-decorator'
import { subscriptionStore } from '~/store'
import { ItemSubscription } from '~/store/SubscriptionStore'

@Component
export default class SubscriptionMixin extends Vue {
  activeSubscriptions: Array<ItemSubscription<any>> = []

  get dataLoaded() {
    for (const subscription of this.activeSubscriptions) {
      if (!subscriptionStore.getIsDataReady(subscription)) return false
    }
    return true
  }

  // overload me
  get subscriptions(): Array<ItemSubscription<any>> {
    return []
  }

  addSubscription(subscription: ItemSubscription<any>) {
    subscriptionStore.addSubscription(subscription)
    this.activeSubscriptions.push(subscription)
  }

  registerSubscriptions() {
    for (const subscription of this.subscriptions) {
      this.addSubscription(subscription)
    }
  }

  removeSubscriptions() {
    for (const subscription of this.activeSubscriptions) {
      subscriptionStore.removeSubscription(subscription)
    }
    this.activeSubscriptions = []
  }

  created() {
    this.registerSubscriptions()
  }

  destroyed() {
    this.removeSubscriptions()
  }
}
