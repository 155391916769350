import { Template } from '~/components/templates/Template'
import { isStringNotEmpty } from '~/utils/validation-helper'

export function appendTemplate(
  template: Template,
  current: string | undefined
): string {
  const newTemplate = template.template

  if (isStringNotEmpty(current)) {
    const parts = current.split('\n')
    const last = parts[parts.length - 1]

    if (isStringNotEmpty(last)) {
      return current + '\n' + newTemplate
    }

    parts[parts.length - 1] = newTemplate
    return parts.join('\n')
  }

  return newTemplate
}

interface HasId {
  id: string
}

export function stripId<T extends HasId>(entries: T[], idPrefix: string): T[] {
  const out: T[] = []
  for (const entry of entries) {
    if (entry.id.startsWith(idPrefix)) {
      entry.id = ''
    }
    out.push(entry)
  }
  return out
}

export const InsertTemplateEvent: string = 'insert-template'

export const TemplateTemporaryIdPrefix: string = 'tmp_'

export default {}
