import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import remote from '~/remote'

import { teamStore, userStore } from '~/store'
import { UserDtoRoleEnum } from '~/remote/api-spec'
import { clearStores } from '~/utils/store-accessor'

export enum AuthorizationLevel {
  NONE,
  USER,
  ADMIN,
}

export enum AuthorizationType {
  NONE,
  CUSTOMER,
  INTERNAL,
}

@Module({
  name: 'AuthStore',
  namespaced: true,
  stateFactory: true,
})
export default class AuthStore extends VuexModule {
  // from auth objects
  cognitoUser: any = undefined

  get cognitoUserId() {
    return this.cognitoUser
  }

  get authorizationType(): AuthorizationType {
    if (!userStore.ownUser) {
      return AuthorizationType.NONE
    } else if (
      userStore.ownUser.role === UserDtoRoleEnum.Admin ||
      userStore.ownUser.role === UserDtoRoleEnum.Internal
    ) {
      return AuthorizationType.INTERNAL
    } else if (userStore.ownUser.role === UserDtoRoleEnum.Customer) {
      return AuthorizationType.CUSTOMER
    } else {
      return AuthorizationType.NONE
    }
  }

  get getIsAuthorizedForLevel() {
    return (authorizationLevel: AuthorizationLevel) => {
      switch (authorizationLevel) {
        case AuthorizationLevel.ADMIN:
          return (
            userStore.ownUser &&
            userStore.ownUser.role === UserDtoRoleEnum.Admin
          )
        case AuthorizationLevel.USER:
          return (
            userStore.ownUser &&
            (userStore.ownUser.role === UserDtoRoleEnum.Admin ||
              userStore.ownUser.role === UserDtoRoleEnum.Internal ||
              userStore.ownUser.role === UserDtoRoleEnum.Customer)
          )
        case AuthorizationLevel.NONE:
          return true
      }
    }
  }

  get getIsAdmin() {
    return userStore.ownUser && userStore.ownUser.role === UserDtoRoleEnum.Admin
  }

  get getIsInternal() {
    return this.authorizationType === AuthorizationType.INTERNAL
  }

  get getIsCustomer() {
    return this.authorizationType === AuthorizationType.CUSTOMER
  }

  get userId(): string | null {
    if (!userStore.ownUser) {
      return null
    }
    return userStore.ownUser.id
  }

  @Mutation
  handleAuthStateChange(token: any) {
    this.cognitoUser = token ? token.idToken.payload.sub : undefined

    if (token && token.accessToken) {
      const jwtToken = token.accessToken.getJwtToken()
      remote.setAuthCredentials(jwtToken)
    }
  }

  @Mutation
  clearData(): void {
    this.cognitoUser = undefined
  }

  @Action({ rawError: true })
  clearAuthState() {
    console.log('clear auth')
    this.handleAuthStateChange(undefined)
    userStore.clearOwnUser()
    teamStore.clearOwnTeam()
    clearStores()
  }
}
