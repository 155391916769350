// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/priorityEnabled.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mailbox-list-item>span[data-v-4c6036df]{display:block;position:absolute;font-family:\"Roboto\",sans-serif;font-style:normal;font-weight:400;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.mailbox-list-item[data-v-4c6036df]{height:98px;border-bottom:.5px solid #c4c4c4;overflow:hidden}.topline-text[data-v-4c6036df]{max-width:180px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.mail-topline[data-v-4c6036df]{top:14px;align-items:flex-end}.mail-midline[data-v-4c6036df],.mail-topline[data-v-4c6036df]{position:absolute;display:flex;flex-direction:row;box-sizing:border-box;left:0;padding:0 16px;width:100%}.mail-midline[data-v-4c6036df]{top:42px}.mail-bottomline[data-v-4c6036df]{position:absolute;display:flex;flex-direction:row;box-sizing:border-box;left:0;padding:0 16px;width:100%;top:66px;align-items:flex-end}.priority-icon[data-v-4c6036df]{display:inline-block;width:23px;height:23px;background-size:100% 100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.awaiting-approval[data-v-4c6036df]{height:23px;background:#ffd600;border-radius:2px;padding:4px;white-space:nowrap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
