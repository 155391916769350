// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import QuotationRequest from './QuotationRequest'
import QuotationItem from './QuotationItem'
import WorkflowStep from './WorkflowStep'
import User from './User'
import Team from './Team'
import {
  authStore,
  partPriceStore,
  quotationRequestStore,
  quotationStore,
  teamStore,
} from '~/store'
import {
  OtherPriceDto,
  QuotationDto,
  QuotationTerms,
  RelatedQuotationDto,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'
import { AuthorizationType } from '~/store/AuthStore'
import { computeQuotationCost } from '~/utils/cost-helper'
import { compressWorkflowStepsToTimeline } from '~/utils/timeline-steps'

export type NormalizedQuotation = Omit<
  QuotationDto,
  'request' | 'items' | 'workflow' | 'internalTeam'
> & {
  requestId: string
  internalTeamId: string
}

const quotationSchema = new schema.Entity<QuotationDto>(
  'quotations',
  {
    requestId: QuotationRequest.schema,
    items: [QuotationItem.schema],
    workflow: [WorkflowStep.schema],
    internalTeamId: Team.schema,
  },
  {
    processStrategy: (value) => ({
      ...value,
      requestId: value.request,
      internalTeamId: value.internalTeam,
    }),
  }
)

export interface QuotationDisplaySettings {
  showFixedCosts: boolean
  showSpecialDiscount: boolean
  showSalesRate: boolean
}

export default class Quotation
  extends RemoteModel
  implements NormalizedQuotation
{
  requestId!: string
  terms!: QuotationTerms
  isPriority!: boolean
  createdOn!: string
  externalIdSuffix!: string
  internalTeamId!: string
  relatedQuotations!: RelatedQuotationDto[]

  showFixedCosts?: boolean
  showSpecialDiscount?: boolean
  showSalesRate?: boolean
  additionalCosts!: OtherPriceDto[]

  static schema: schema.Entity = quotationSchema
  static arraySchema = new schema.Array(quotationSchema)

  get request() {
    return quotationRequestStore.getQuotationRequestById(this.requestId)!
  }

  get items(): Array<QuotationItem> {
    return quotationStore
      .getQuotationItemsByQuotationId(this.id)
      .sort((itemA, itemB) => itemA.details.ordinal - itemB.details.ordinal)
  }

  get internalTeam() {
    return teamStore.getTeamByTeamId(this.internalTeamId)!
  }

  get workflowSteps() {
    return quotationStore.getWorkflowStepsByQuotationId(this.id)
  }

  get timelineSteps() {
    return (userId: string) =>
      compressWorkflowStepsToTimeline(
        quotationStore.getWorkflowStepsByQuotationId(this.id),
        userId
      )
  }

  get externalId() {
    return (
      this.request.externalId +
      (this.externalIdSuffix ? '-' + this.externalIdSuffix : '')
    )
  }

  workflowStepOfType(workflowType: WorkflowStepDtoTypeEnum) {
    const steps = quotationStore.getWorkflowStepsByQuotationId(this.id)
    for (const workflowStep of steps) {
      if (workflowStep?.type === workflowType) {
        return workflowStep
      }
    }
  }

  get assignedUser(): User {
    return this.workflowStepOfType(WorkflowStepDtoTypeEnum.InProgress)!.actor
  }

  get status() {
    const sortedWorkflows = this.workflowSteps
      .filter(
        (step) =>
          step.type !== WorkflowStepDtoTypeEnum.Assigned &&
          step.type !== WorkflowStepDtoTypeEnum.Approved
      )
      .sort(
        (workflowA, workflowB) =>
          workflowA.workflowOrderIndex - workflowB.workflowOrderIndex
      )
    return sortedWorkflows[sortedWorkflows.length - 1]!.type
  }

  static isStatusInProgress(status: WorkflowStepDtoTypeEnum) {
    return (
      status === WorkflowStepDtoTypeEnum.InProgress ||
      status === WorkflowStepDtoTypeEnum.Assigned ||
      status === WorkflowStepDtoTypeEnum.Approved ||
      status === WorkflowStepDtoTypeEnum.Returned
    )
  }

  get isInProgress(): boolean {
    return Quotation.isStatusInProgress(this.status)
  }

  get isReadyToSend(): boolean {
    return this.status === WorkflowStepDtoTypeEnum.Ready
  }

  get isSent(): boolean {
    return this.status === WorkflowStepDtoTypeEnum.Sent
  }

  get progressBegunDate() {
    const workflowStep = this.workflowStepOfType(
      WorkflowStepDtoTypeEnum.InProgress
    )
    return workflowStep ? new Date(workflowStep.timestamp) : undefined
  }

  get confirmedDate() {
    const workflowStep = this.workflowStepOfType(WorkflowStepDtoTypeEnum.Ready)
    return workflowStep ? new Date(workflowStep.timestamp) : undefined
  }

  get sentDate() {
    const workflowStep = this.workflowStepOfType(WorkflowStepDtoTypeEnum.Sent)
    return workflowStep ? new Date(workflowStep.timestamp) : undefined
  }

  get colorClass() {
    if (authStore.authorizationType === AuthorizationType.CUSTOMER) {
      if (!this.isSent)
        console.error('No valid color for this quotation status')
      return this.request.archived ? 'inbox' : 'inbox-archived'
    } else if (this.isInProgress) {
      return 'in-progress'
    } else if (this.isSent) {
      return 'sent'
    } else {
      return 'waiting-send'
    }
  }

  get totalPrice(): number {
    return computeQuotationCost(this).netTotalAmount
  }

  get hasPrecomputedPrice(): boolean {
    return this.showFixedCosts !== undefined && this.showFixedCosts !== null
  }

  get displaySettings(): QuotationDisplaySettings {
    if (this.hasPrecomputedPrice) {
      return {
        showFixedCosts: this.showFixedCosts || false,
        showSpecialDiscount: this.showSpecialDiscount || false,
        showSalesRate: this.showSalesRate || false,
      }
    }

    const teamSettings = partPriceStore.getPartPricesByTeamId(
      this.request.customerTeamId
    )[0]

    return {
      showFixedCosts: teamSettings?.showFixedCosts || false,
      showSpecialDiscount: teamSettings?.showSpecialDiscount || false,
      showSalesRate: teamSettings?.showSalesRate || false,
    }
  }

  get hasAnyTerms(): boolean {
    return Boolean(
      this.terms.manufacturerName ||
        this.terms.sendTo ||
        this.terms.receiveConditions ||
        this.terms.receiveConditions ||
        this.terms.paymentConditions ||
        this.terms.shippingExpenses ||
        this.terms.valid
    )
  }
}
