/* tslint:disable */
/* eslint-disable */
/**
 * SMX-PTC
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line1?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line2?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  prefecture?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country?: string
}
/**
 *
 * @export
 * @interface AllQuotationStepPayloadTypes
 */
export interface AllQuotationStepPayloadTypes {
  /**
   *
   * @type {string}
   * @memberof AllQuotationStepPayloadTypes
   */
  workflowStepId?: string
  /**
   *
   * @type {string}
   * @memberof AllQuotationStepPayloadTypes
   */
  returnedBy?: string
  /**
   *
   * @type {Array<string>}
   * @memberof AllQuotationStepPayloadTypes
   */
  revokedStepIds?: Array<string>
  /**
   *
   * @type {TimelineStepType}
   * @memberof AllQuotationStepPayloadTypes
   */
  timelineStepType?: TimelineStepType
}
/**
 *
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  visibility?: AttachmentDtoVisibilityEnum
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  parentType: AttachmentDtoParentTypeEnum
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof AttachmentDto
   */
  status: AttachmentDtoStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum AttachmentDtoVisibilityEnum {
  Customer = 'customer',
  Internal = 'internal',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum AttachmentDtoParentTypeEnum {
  RequestItem = 'requestItem',
  QuotationItem = 'quotationItem',
  Quotation = 'quotation',
  QuotationEmail = 'quotationEmail',
  Message = 'message',
}
/**
 * @export
 * @enum {string}
 */
export enum AttachmentDtoStatusEnum {
  Uploading = 'uploading',
  Scanning = 'scanning',
  Clean = 'clean',
  Skipped = 'skipped',
  Infected = 'infected',
  Error = 'error',
}

/**
 *
 * @export
 * @interface AttachmentLinksDto
 */
export interface AttachmentLinksDto {
  /**
   *
   * @type {string}
   * @memberof AttachmentLinksDto
   */
  downloadLink: string
  /**
   *
   * @type {string}
   * @memberof AttachmentLinksDto
   */
  previewDownloadLink: string
}
/**
 *
 * @export
 * @interface CommonTemplateDto
 */
export interface CommonTemplateDto {
  /**
   *
   * @type {string}
   * @memberof CommonTemplateDto
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CommonTemplateDto
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof CommonTemplateDto
   */
  template: string
  /**
   *
   * @type {Array<string>}
   * @memberof CommonTemplateDto
   */
  automaticForTeams?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CommonTemplateDto
   */
  createdOn?: string
  /**
   *
   * @type {boolean}
   * @memberof CommonTemplateDto
   */
  enabled: boolean
}
/**
 *
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
  /**
   *
   * @type {string}
   * @memberof CreateMessageDto
   */
  parentId: string
  /**
   *
   * @type {string}
   * @memberof CreateMessageDto
   */
  parentType: CreateMessageDtoParentTypeEnum
  /**
   *
   * @type {string}
   * @memberof CreateMessageDto
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof CreateMessageDto
   */
  status: CreateMessageDtoStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum CreateMessageDtoParentTypeEnum {
  Request = 'request',
  Quotation = 'quotation',
}
/**
 * @export
 * @enum {string}
 */
export enum CreateMessageDtoStatusEnum {
  Draft = 'draft',
  Sent = 'sent',
}

/**
 *
 * @export
 * @interface CreatePartPriceItemDto
 */
export interface CreatePartPriceItemDto {
  /**
   *
   * @type {string}
   * @memberof CreatePartPriceItemDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof CreatePartPriceItemDto
   */
  hasFixedPrice?: boolean
  /**
   *
   * @type {RoundingSettings}
   * @memberof CreatePartPriceItemDto
   */
  fixedPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof CreatePartPriceItemDto
   */
  salesPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof CreatePartPriceItemDto
   */
  purchasePriceSettings: RoundingSettings
}
/**
 *
 * @export
 * @interface CreatePhoneDto
 */
export interface CreatePhoneDto {
  /**
   *
   * @type {string}
   * @memberof CreatePhoneDto
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof CreatePhoneDto
   */
  type: CreatePhoneDtoTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof CreatePhoneDto
   */
  primary: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum CreatePhoneDtoTypeEnum {
  LandLine = 'landLine',
  CellPhone = 'cellPhone',
  Fax = 'fax',
  IpPhone = 'ipPhone',
}

/**
 *
 * @export
 * @interface CreateQuotationDto
 */
export interface CreateQuotationDto {
  /**
   *
   * @type {string}
   * @memberof CreateQuotationDto
   */
  requestId: string
}
/**
 *
 * @export
 * @interface CreateQuotationItemDto
 */
export interface CreateQuotationItemDto {
  /**
   *
   * @type {string}
   * @memberof CreateQuotationItemDto
   */
  requestItemId?: string
}
/**
 *
 * @export
 * @interface CreateTeamDto
 */
export interface CreateTeamDto {
  /**
   *
   * @type {Array<CreatePhoneDto>}
   * @memberof CreateTeamDto
   */
  phones: Array<CreatePhoneDto>
  /**
   *
   * @type {string}
   * @memberof CreateTeamDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateTeamDto
   */
  companyName: string
  /**
   *
   * @type {string}
   * @memberof CreateTeamDto
   */
  prefix?: string
  /**
   *
   * @type {Address}
   * @memberof CreateTeamDto
   */
  address: Address
  /**
   *
   * @type {QuotationTerms}
   * @memberof CreateTeamDto
   */
  defaultQuotationTerms: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof CreateTeamDto
   */
  representativeId?: string
  /**
   *
   * @type {string}
   * @memberof CreateTeamDto
   */
  type: CreateTeamDtoTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof CreateTeamDto
   */
  managedByIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateTeamDto
   */
  ccUserIds: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum CreateTeamDtoTypeEnum {
  Customer = 'customer',
  Internal = 'internal',
  AddressBook = 'addressBook',
}

/**
 *
 * @export
 * @interface CreateTeamPartsPriceDto
 */
export interface CreateTeamPartsPriceDto {
  /**
   *
   * @type {Array<CreatePartPriceItemDto>}
   * @memberof CreateTeamPartsPriceDto
   */
  items: Array<CreatePartPriceItemDto>
  /**
   *
   * @type {string}
   * @memberof CreateTeamPartsPriceDto
   */
  teamId: string
  /**
   *
   * @type {string}
   * @memberof CreateTeamPartsPriceDto
   */
  fixedCostsName: string
  /**
   *
   * @type {number}
   * @memberof CreateTeamPartsPriceDto
   */
  fixedCosts: number
  /**
   *
   * @type {boolean}
   * @memberof CreateTeamPartsPriceDto
   */
  showFixedCosts: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTeamPartsPriceDto
   */
  showSpecialDiscount: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateTeamPartsPriceDto
   */
  showSalesRate: boolean
}
/**
 *
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateUserDto
   */
  sendInvitation?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  email: string
  /**
   *
   * @type {UserName}
   * @memberof CreateUserDto
   */
  name: UserName
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  teamId: string
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  role: CreateUserDtoRoleEnum
  /**
   *
   * @type {string}
   * @memberof CreateUserDto
   */
  seal?: string
  /**
   *
   * @type {boolean}
   * @memberof CreateUserDto
   */
  toCustomerEmails: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CreateUserDto
   */
  managedTeamsIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateUserDto
   */
  ccTeamIds: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum CreateUserDtoRoleEnum {
  Customer = 'customer',
  Internal = 'internal',
  Admin = 'admin',
}

/**
 *
 * @export
 * @interface ItemDetails
 */
export interface ItemDetails {
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  ordinal: number
  /**
   *
   * @type {string}
   * @memberof ItemDetails
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ItemDetails
   */
  modelId?: string
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  quantity: number
  /**
   *
   * @type {string}
   * @memberof ItemDetails
   */
  specificationType?: ItemDetailsSpecificationTypeEnum
  /**
   *
   * @type {string}
   * @memberof ItemDetails
   */
  specificationId?: string
  /**
   *
   * @type {SpecificationDetails}
   * @memberof ItemDetails
   */
  specificationDetails: SpecificationDetails
  /**
   *
   * @type {string}
   * @memberof ItemDetails
   */
  manufacturerName?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ItemDetailsSpecificationTypeEnum {
  Specification = 'specification',
  SerialNumber = 'serialNumber',
  ManufacturerId = 'manufacturerId',
}

/**
 *
 * @export
 * @interface Login
 */
export interface Login {
  /**
   *
   * @type {string}
   * @memberof Login
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Login
   */
  password: string
}
/**
 *
 * @export
 * @interface MailboxDto
 */
export interface MailboxDto {
  /**
   *
   * @type {Array<MailboxItemDto>}
   * @memberof MailboxDto
   */
  quotations: Array<MailboxItemDto>
  /**
   *
   * @type {Array<MailboxItemDto>}
   * @memberof MailboxDto
   */
  requests: Array<MailboxItemDto>
}
/**
 *
 * @export
 * @interface MailboxItemDto
 */
export interface MailboxItemDto {
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  id: string
  /**
   *
   * @type {object}
   * @memberof MailboxItemDto
   */
  status: object
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  externalId: string
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  referenceId?: string
  /**
   *
   * @type {number}
   * @memberof MailboxItemDto
   */
  unreadMessages: number
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  customerName: string
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  createdOn: string
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  sentDate?: string
  /**
   *
   * @type {boolean}
   * @memberof MailboxItemDto
   */
  isPriority?: boolean
  /**
   *
   * @type {boolean}
   * @memberof MailboxItemDto
   */
  archived?: boolean
  /**
   *
   * @type {string}
   * @memberof MailboxItemDto
   */
  companyName: string
}
/**
 *
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
  /**
   *
   * @type {boolean}
   * @memberof MessageDto
   */
  read: boolean
  /**
   *
   * @type {UserDto}
   * @memberof MessageDto
   */
  author: UserDto
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  createdOn: string
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  parentId: string
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  parentType: MessageDtoParentTypeEnum
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof MessageDto
   */
  status: MessageDtoStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum MessageDtoParentTypeEnum {
  Request = 'request',
  Quotation = 'quotation',
}
/**
 * @export
 * @enum {string}
 */
export enum MessageDtoStatusEnum {
  Draft = 'draft',
  Sent = 'sent',
}

/**
 *
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
  /**
   *
   * @type {NotificationDtoTypeEnum}
   * @memberof NotificationDto
   */
  type: NotificationDtoTypeEnum
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  updated_at?: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  userId: string
  /**
   *
   * @type {User}
   * @memberof NotificationDto
   */
  user: User
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  operatorName: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  companyName: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  parentId: string
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  parentType: NotificationDtoParentTypeEnum
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  message?: string | null
  /**
   *
   * @type {boolean}
   * @memberof NotificationDto
   */
  isRead: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationDtoParentTypeEnum {
  Quotation = 'quotation',
  Request = 'request',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum NotificationDtoTypeEnum {
  NewQuotationRequest = 'newQuotationRequest',
  UserAssignedNew = 'userAssignedNew',
  UserAssignedBack = 'userAssignedBack',
  CustomerMessage = 'customerMessage',
  InternalMessage = 'internalMessage',
  PriorityChange = 'priorityChange',
  CompleteQuotation = 'completeQuotation',
}

/**
 *
 * @export
 * @interface NotificationsDto
 */
export interface NotificationsDto {
  /**
   *
   * @type {Array<NotificationDtoTypeEnum>}
   * @memberof NotificationsDto
   */
  unreadTypes: Array<NotificationDtoTypeEnum>
  /**
   *
   * @type {Array<NotificationDto>}
   * @memberof NotificationsDto
   */
  notifications: Array<NotificationDto>
  /**
   *
   * @type {number}
   * @memberof NotificationsDto
   */
  uncheckedCount: number
}
/**
 *
 * @export
 * @interface OtherPriceDto
 */
export interface OtherPriceDto {
  /**
   *
   * @type {string}
   * @memberof OtherPriceDto
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OtherPriceDto
   */
  type: OtherPriceDtoTypeEnum
  /**
   *
   * @type {string}
   * @memberof OtherPriceDto
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof OtherPriceDto
   */
  amountSelling: number
  /**
   *
   * @type {number}
   * @memberof OtherPriceDto
   */
  amountPurchase: number
  /**
   *
   * @type {boolean}
   * @memberof OtherPriceDto
   */
  display: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum OtherPriceDtoTypeEnum {
  Discount = 'discount',
  AdditionalCost = 'additionalCost',
  Fixed = 'fixed',
  SpecialDiscount = 'specialDiscount',
}

/**
 *
 * @export
 * @interface PartPriceItemDto
 */
export interface PartPriceItemDto {
  /**
   *
   * @type {string}
   * @memberof PartPriceItemDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PartPriceItemDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof PartPriceItemDto
   */
  hasFixedPrice?: boolean
  /**
   *
   * @type {RoundingSettings}
   * @memberof PartPriceItemDto
   */
  fixedPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof PartPriceItemDto
   */
  salesPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof PartPriceItemDto
   */
  purchasePriceSettings: RoundingSettings
  /**
   *
   * @type {string}
   * @memberof PartPriceItemDto
   */
  partsPriceId: string
}
/**
 *
 * @export
 * @interface Phone
 */
export interface Phone {
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  type: PhoneTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof Phone
   */
  primary: boolean
  /**
   *
   * @type {Team}
   * @memberof Phone
   */
  team: Team
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  updated_at?: string
}

/**
 * @export
 * @enum {string}
 */
export enum PhoneTypeEnum {
  LandLine = 'landLine',
  CellPhone = 'cellPhone',
  Fax = 'fax',
  IpPhone = 'ipPhone',
}

/**
 *
 * @export
 * @interface PhoneDto
 */
export interface PhoneDto {
  /**
   *
   * @type {string}
   * @memberof PhoneDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PhoneDto
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof PhoneDto
   */
  type: PhoneDtoTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof PhoneDto
   */
  primary: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum PhoneDtoTypeEnum {
  LandLine = 'landLine',
  CellPhone = 'cellPhone',
  Fax = 'fax',
  IpPhone = 'ipPhone',
}

/**
 *
 * @export
 * @interface QuotationCombineDto
 */
export interface QuotationCombineDto {
  /**
   *
   * @type {string}
   * @memberof QuotationCombineDto
   */
  requestId: string
  /**
   *
   * @type {Array<string>}
   * @memberof QuotationCombineDto
   */
  requestItemIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof QuotationCombineDto
   */
  quotationItemIds: Array<string>
}
/**
 *
 * @export
 * @interface QuotationDto
 */
export interface QuotationDto {
  /**
   *
   * @type {Array<QuotationItemDto>}
   * @memberof QuotationDto
   */
  items: Array<QuotationItemDto>
  /**
   *
   * @type {QuotationRequestDto}
   * @memberof QuotationDto
   */
  request: QuotationRequestDto
  /**
   *
   * @type {Array<WorkflowStepDto>}
   * @memberof QuotationDto
   */
  workflow: Array<WorkflowStepDto>
  /**
   *
   * @type {string}
   * @memberof QuotationDto
   */
  createdOn: string
  /**
   *
   * @type {TeamDto}
   * @memberof QuotationDto
   */
  internalTeam: TeamDto
  /**
   *
   * @type {Array<RelatedQuotationDto>}
   * @memberof QuotationDto
   */
  relatedQuotations: Array<RelatedQuotationDto>
  /**
   *
   * @type {Array<OtherPriceDto>}
   * @memberof QuotationDto
   */
  additionalCosts: Array<OtherPriceDto>
  /**
   *
   * @type {string}
   * @memberof QuotationDto
   */
  id: string
  /**
   *
   * @type {QuotationTerms}
   * @memberof QuotationDto
   */
  terms: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof QuotationDto
   */
  requestId: string
  /**
   *
   * @type {string}
   * @memberof QuotationDto
   */
  externalIdSuffix?: string
  /**
   *
   * @type {boolean}
   * @memberof QuotationDto
   */
  isPriority: boolean
  /**
   *
   * @type {string}
   * @memberof QuotationDto
   */
  internalTeamId: string
  /**
   *
   * @type {boolean}
   * @memberof QuotationDto
   */
  showFixedCosts?: boolean
  /**
   *
   * @type {boolean}
   * @memberof QuotationDto
   */
  showSpecialDiscount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof QuotationDto
   */
  showSalesRate?: boolean
}
/**
 *
 * @export
 * @interface QuotationItemDto
 */
export interface QuotationItemDto {
  /**
   *
   * @type {QuotationItemTypeEnum}
   * @memberof QuotationItemDto
   */
  itemType: QuotationItemTypeEnum
  /**
   *
   * @type {QuotationItemPriceDto}
   * @memberof QuotationItemDto
   */
  partPrice: QuotationItemPriceDto
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  quotationId: string
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  requestItemId?: string
  /**
   *
   * @type {ItemDetails}
   * @memberof QuotationItemDto
   */
  details: ItemDetails
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  conditions?: string
  /**
   *
   * @type {boolean}
   * @memberof QuotationItemDto
   */
  isAS?: boolean | null
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  factoryQuotationId?: string
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  estimatedCustomerResponseDate?: string
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  estimatedCustomerResponseModifiedDate?: string
  /**
   *
   * @type {object}
   * @memberof QuotationItemDto
   */
  computedCost?: object
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  expectedDelivery?: string
  /**
   *
   * @type {string}
   * @memberof QuotationItemDto
   */
  requestBody?: string
}
/**
 *
 * @export
 * @interface QuotationItemPriceAutoDto
 */
export interface QuotationItemPriceAutoDto {
  /**
   *
   * @type {string}
   * @memberof QuotationItemPriceAutoDto
   */
  partName: string
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceAutoDto
   */
  basePrice: number
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceAutoDto
   */
  fixedPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceAutoDto
   */
  salesPriceSettings: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceAutoDto
   */
  purchasePriceSettings: RoundingSettings
  /**
   *
   * @type {Array<OtherPriceDto>}
   * @memberof QuotationItemPriceAutoDto
   */
  additionalCosts: Array<OtherPriceDto>
}
/**
 *
 * @export
 * @interface QuotationItemPriceDto
 */
export interface QuotationItemPriceDto {
  /**
   *
   * @type {Array<OtherPriceDto>}
   * @memberof QuotationItemPriceDto
   */
  additionalCosts: Array<OtherPriceDto>
  /**
   *
   * @type {string}
   * @memberof QuotationItemPriceDto
   */
  partName?: string
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceDto
   */
  salePrice?: number
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceDto
   */
  purchasePrice?: number
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceDto
   */
  basePrice?: number
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceDto
   */
  fixedPriceSettings?: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceDto
   */
  salesPriceSettings?: RoundingSettings
  /**
   *
   * @type {RoundingSettings}
   * @memberof QuotationItemPriceDto
   */
  purchasePriceSettings?: RoundingSettings
}
/**
 *
 * @export
 * @interface QuotationItemPriceFixedDto
 */
export interface QuotationItemPriceFixedDto {
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceFixedDto
   */
  salePrice: number
  /**
   *
   * @type {number}
   * @memberof QuotationItemPriceFixedDto
   */
  purchasePrice: number
  /**
   *
   * @type {Array<OtherPriceDto>}
   * @memberof QuotationItemPriceFixedDto
   */
  additionalCosts: Array<OtherPriceDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export enum QuotationItemTypeEnum {
  Unit = 'unit',
  Repair = 'repair',
  Part = 'part',
  Other = 'other',
  Unset = 'unset',
}

/**
 *
 * @export
 * @interface QuotationRequestDto
 */
export interface QuotationRequestDto {
  /**
   *
   * @type {UserDto}
   * @memberof QuotationRequestDto
   */
  customer: UserDto
  /**
   *
   * @type {Array<QuotationRequestItemDto>}
   * @memberof QuotationRequestDto
   */
  items: Array<QuotationRequestItemDto>
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  createdOn: string
  /**
   *
   * @type {TeamDto}
   * @memberof QuotationRequestDto
   */
  customerTeam: TeamDto
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  externalId: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  referenceId?: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  sentDate?: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  status: QuotationRequestDtoStatusEnum
  /**
   *
   * @type {boolean}
   * @memberof QuotationRequestDto
   */
  archived: boolean
  /**
   *
   * @type {string}
   * @memberof QuotationRequestDto
   */
  customerTeamId: string
}

/**
 * @export
 * @enum {string}
 */
export enum QuotationRequestDtoStatusEnum {
  Creating = 'creating',
  Complete = 'complete',
  Quoting = 'quoting',
  Resolved = 'resolved',
}

/**
 *
 * @export
 * @interface QuotationRequestItemDto
 */
export interface QuotationRequestItemDto {
  /**
   *
   * @type {QuotationItemTypeEnum}
   * @memberof QuotationRequestItemDto
   */
  itemType: QuotationItemTypeEnum
  /**
   *
   * @type {string}
   * @memberof QuotationRequestItemDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof QuotationRequestItemDto
   */
  requestId: string
  /**
   *
   * @type {ItemDetails}
   * @memberof QuotationRequestItemDto
   */
  details: ItemDetails
  /**
   *
   * @type {string}
   * @memberof QuotationRequestItemDto
   */
  requestBody?: string
}
/**
 *
 * @export
 * @interface QuotationSplitDto
 */
export interface QuotationSplitDto {
  /**
   *
   * @type {Array<string>}
   * @memberof QuotationSplitDto
   */
  quotationItemIds: Array<string>
}
/**
 *
 * @export
 * @interface QuotationStepDto
 */
export interface QuotationStepDto {
  /**
   *
   * @type {AllQuotationStepPayloadTypes}
   * @memberof QuotationStepDto
   */
  payload?: AllQuotationStepPayloadTypes
  /**
   *
   * @type {string}
   * @memberof QuotationStepDto
   */
  assigneeId: string
}
/**
 *
 * @export
 * @interface QuotationTerms
 */
export interface QuotationTerms {
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  manufacturerName?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  sendTo?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  receiveConditions?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  paymentConditions?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  shippingExpenses?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  delivery?: string
  /**
   *
   * @type {string}
   * @memberof QuotationTerms
   */
  valid?: string
}
/**
 *
 * @export
 * @interface ReadNotificationsByTypesDto
 */
export interface ReadNotificationsByTypesDto {
  /**
   *
   * @type {Array<NotificationDtoTypeEnum>}
   * @memberof ReadNotificationsByTypesDto
   */
  types: Array<NotificationDtoTypeEnum>
}
/**
 *
 * @export
 * @interface RelatedQuotationDto
 */
export interface RelatedQuotationDto {
  /**
   *
   * @type {string}
   * @memberof RelatedQuotationDto
   */
  status: RelatedQuotationDtoStatusEnum
  /**
   *
   * @type {string}
   * @memberof RelatedQuotationDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RelatedQuotationDto
   */
  externalIdSuffix?: string
}

/**
 * @export
 * @enum {string}
 */
export enum RelatedQuotationDtoStatusEnum {
  Requested = 'requested',
  InProgress = 'inProgress',
  Assigned = 'assigned',
  Approved = 'approved',
  Ready = 'ready',
  Sent = 'sent',
  Returned = 'returned',
}

/**
 *
 * @export
 * @interface RoundingSettings
 */
export interface RoundingSettings {
  /**
   *
   * @type {number}
   * @memberof RoundingSettings
   */
  percent: number
  /**
   *
   * @type {number}
   * @memberof RoundingSettings
   */
  roundingStep: number
  /**
   *
   * @type {string}
   * @memberof RoundingSettings
   */
  roundingType: RoundingSettingsRoundingTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum RoundingSettingsRoundingTypeEnum {
  Up = 'up',
  Down = 'down',
  Nearest = 'nearest',
}

/**
 *
 * @export
 * @interface SendEmailDto
 */
export interface SendEmailDto {
  /**
   *
   * @type {Array<string>}
   * @memberof SendEmailDto
   */
  to: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof SendEmailDto
   */
  cc: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof SendEmailDto
   */
  includeQuotation: boolean
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  subject: string
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  text: string
  /**
   *
   * @type {Array<string>}
   * @memberof SendEmailDto
   */
  attachmentIds: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof SendEmailDto
   */
  changeStep?: boolean
}
/**
 *
 * @export
 * @interface SpecificationDetails
 */
export interface SpecificationDetails {
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  poles?: string
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  phase?: string
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  voltage?: string
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  frequency?: string
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  capacity?: string
  /**
   *
   * @type {boolean}
   * @memberof SpecificationDetails
   */
  brake?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SpecificationDetails
   */
  inverter?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SpecificationDetails
   */
  differentSpecOk?: boolean
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  environment?: string
  /**
   *
   * @type {string}
   * @memberof SpecificationDetails
   */
  additional?: string
}
/**
 *
 * @export
 * @interface Team
 */
export interface Team {
  /**
   *
   * @type {string}
   * @memberof Team
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Team
   */
  companyName: string
  /**
   *
   * @type {string}
   * @memberof Team
   */
  prefix?: string
  /**
   *
   * @type {Array<Phone>}
   * @memberof Team
   */
  phones: Array<Phone>
  /**
   *
   * @type {Array<User>}
   * @memberof Team
   */
  users: Array<User>
  /**
   *
   * @type {Address}
   * @memberof Team
   */
  address: Address
  /**
   *
   * @type {Array<User>}
   * @memberof Team
   */
  managedBy: Array<User>
  /**
   *
   * @type {QuotationTerms}
   * @memberof Team
   */
  defaultQuotationTerms: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof Team
   */
  representativeId?: string
  /**
   *
   * @type {User}
   * @memberof Team
   */
  representative?: User
  /**
   *
   * @type {string}
   * @memberof Team
   */
  type: TeamTypeEnum
  /**
   *
   * @type {Array<User>}
   * @memberof Team
   */
  ccUsers?: Array<User>
  /**
   *
   * @type {string}
   * @memberof Team
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Team
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof Team
   */
  updated_at?: string
}

/**
 * @export
 * @enum {string}
 */
export enum TeamTypeEnum {
  Customer = 'customer',
  Internal = 'internal',
  AddressBook = 'addressBook',
}

/**
 *
 * @export
 * @interface TeamDto
 */
export interface TeamDto {
  /**
   *
   * @type {Array<PhoneDto>}
   * @memberof TeamDto
   */
  phones: Array<PhoneDto>
  /**
   *
   * @type {Array<UserDto>}
   * @memberof TeamDto
   */
  managedBy: Array<UserDto>
  /**
   *
   * @type {Array<UserDto>}
   * @memberof TeamDto
   */
  users: Array<UserDto>
  /**
   *
   * @type {UserDto}
   * @memberof TeamDto
   */
  representative?: UserDto
  /**
   *
   * @type {Array<string>}
   * @memberof TeamDto
   */
  managedByIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof TeamDto
   */
  ccUserIds: Array<string>
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  companyName: string
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  prefix?: string
  /**
   *
   * @type {Address}
   * @memberof TeamDto
   */
  address: Address
  /**
   *
   * @type {QuotationTerms}
   * @memberof TeamDto
   */
  defaultQuotationTerms: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  representativeId?: string
  /**
   *
   * @type {string}
   * @memberof TeamDto
   */
  type: TeamDtoTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TeamDtoTypeEnum {
  Customer = 'customer',
  Internal = 'internal',
  AddressBook = 'addressBook',
}

/**
 *
 * @export
 * @interface TeamPartsPriceDto
 */
export interface TeamPartsPriceDto {
  /**
   *
   * @type {Array<PartPriceItemDto>}
   * @memberof TeamPartsPriceDto
   */
  items: Array<PartPriceItemDto>
  /**
   *
   * @type {string}
   * @memberof TeamPartsPriceDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TeamPartsPriceDto
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof TeamPartsPriceDto
   */
  updated_at?: string
  /**
   *
   * @type {string}
   * @memberof TeamPartsPriceDto
   */
  teamId: string
  /**
   *
   * @type {string}
   * @memberof TeamPartsPriceDto
   */
  fixedCostsName: string
  /**
   *
   * @type {number}
   * @memberof TeamPartsPriceDto
   */
  fixedCosts: number
  /**
   *
   * @type {boolean}
   * @memberof TeamPartsPriceDto
   */
  showFixedCosts: boolean
  /**
   *
   * @type {boolean}
   * @memberof TeamPartsPriceDto
   */
  showSpecialDiscount: boolean
  /**
   *
   * @type {boolean}
   * @memberof TeamPartsPriceDto
   */
  showSalesRate: boolean
}
/**
 *
 * @export
 * @interface TeamTemplateDto
 */
export interface TeamTemplateDto {
  /**
   *
   * @type {string}
   * @memberof TeamTemplateDto
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TeamTemplateDto
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof TeamTemplateDto
   */
  template: string
  /**
   *
   * @type {Array<string>}
   * @memberof TeamTemplateDto
   */
  automaticForTeams?: Array<string>
  /**
   *
   * @type {string}
   * @memberof TeamTemplateDto
   */
  createdOn?: string
  /**
   *
   * @type {boolean}
   * @memberof TeamTemplateDto
   */
  enabled: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TimelineStepType {
  Requested = 'requested',
  InProgress = 'inProgress',
  Assigned = 'assigned',
  AssignedToReApprove = 'assignedToReApprove',
  Approved = 'approved',
  ReApproved = 'reApproved',
  RegainedApproved = 'regainedApproved',
  RegainedInProgress = 'regainedInProgress',
  RevokedApproved = 'revokedApproved',
  ReturnedApproved = 'returnedApproved',
  ReturnedInProgress = 'returnedInProgress',
  WaitingToApprove = 'waitingToApprove',
  WaitingToReApprove = 'waitingToReApprove',
  WaitingReturnedByThisStep = 'waitingReturnedByThisStep',
  FixedInProgress = 'fixedInProgress',
  Sent = 'sent',
}

/**
 *
 * @export
 * @interface UnreadNotificationsDto
 */
export interface UnreadNotificationsDto {
  /**
   *
   * @type {Array<string>}
   * @memberof UnreadNotificationsDto
   */
  ids: Array<string>
}
/**
 *
 * @export
 * @interface UpdateAttachmentDto
 */
export interface UpdateAttachmentDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAttachmentDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateAttachmentDto
   */
  status?: UpdateAttachmentDtoStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateAttachmentDtoStatusEnum {
  Uploading = 'uploading',
  Scanning = 'scanning',
  Clean = 'clean',
  Skipped = 'skipped',
  Infected = 'infected',
  Error = 'error',
}

/**
 *
 * @export
 * @interface UpdateMessageDto
 */
export interface UpdateMessageDto {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateMessageDto
   */
  attachmentIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateMessageDto
   */
  text?: string
}
/**
 *
 * @export
 * @interface UpdateQuotationDto
 */
export interface UpdateQuotationDto {
  /**
   *
   * @type {QuotationTerms}
   * @memberof UpdateQuotationDto
   */
  terms?: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationDto
   */
  externalIdSuffix?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationDto
   */
  isPriority?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationDto
   */
  showFixedCosts?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationDto
   */
  showSpecialDiscount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationDto
   */
  showSalesRate?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationDto
   */
  createdOn?: string
  /**
   *
   * @type {Array<OtherPriceDto>}
   * @memberof UpdateQuotationDto
   */
  additionalCosts?: Array<OtherPriceDto>
}
/**
 *
 * @export
 * @interface UpdateQuotationItemDto
 */
export interface UpdateQuotationItemDto {
  /**
   *
   * @type {QuotationItemTypeEnum}
   * @memberof UpdateQuotationItemDto
   */
  itemType?: QuotationItemTypeEnum
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  requestBody?: string | null
  /**
   *
   * @type {ItemDetails}
   * @memberof UpdateQuotationItemDto
   */
  details?: ItemDetails
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  conditions?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationItemDto
   */
  isAS?: boolean | null
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  factoryQuotationId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  estimatedCustomerResponseDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  estimatedCustomerResponseModifiedDate?: string
  /**
   *
   * @type {object}
   * @memberof UpdateQuotationItemDto
   */
  computedCost?: object
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationItemDto
   */
  expectedDelivery?: string
  /**
   *
   * @type {QuotationItemPriceDto}
   * @memberof UpdateQuotationItemDto
   */
  partPrice?: QuotationItemPriceDto
}
/**
 *
 * @export
 * @interface UpdateQuotationRequestDto
 */
export interface UpdateQuotationRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationRequestDto
   */
  referenceId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationRequestDto
   */
  customerId?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateQuotationRequestDto
   */
  archived?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationRequestDto
   */
  createdOn?: string
}
/**
 *
 * @export
 * @interface UpdateQuotationRequestItemDto
 */
export interface UpdateQuotationRequestItemDto {
  /**
   *
   * @type {QuotationItemTypeEnum}
   * @memberof UpdateQuotationRequestItemDto
   */
  itemType?: QuotationItemTypeEnum
  /**
   *
   * @type {ItemDetails}
   * @memberof UpdateQuotationRequestItemDto
   */
  details?: ItemDetails
  /**
   *
   * @type {string}
   * @memberof UpdateQuotationRequestItemDto
   */
  requestBody?: string
}
/**
 *
 * @export
 * @interface UpdateTeamDto
 */
export interface UpdateTeamDto {
  /**
   *
   * @type {string}
   * @memberof UpdateTeamDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateTeamDto
   */
  companyName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateTeamDto
   */
  prefix?: string
  /**
   *
   * @type {Address}
   * @memberof UpdateTeamDto
   */
  address?: Address
  /**
   *
   * @type {QuotationTerms}
   * @memberof UpdateTeamDto
   */
  defaultQuotationTerms?: QuotationTerms
  /**
   *
   * @type {string}
   * @memberof UpdateTeamDto
   */
  representativeId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateTeamDto
   */
  managedByIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateTeamDto
   */
  ccUserIds?: Array<string>
  /**
   *
   * @type {Array<CreatePhoneDto>}
   * @memberof UpdateTeamDto
   */
  phones?: Array<CreatePhoneDto>
}
/**
 *
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
  /**
   *
   * @type {UserName}
   * @memberof UpdateUserDto
   */
  name?: UserName
  /**
   *
   * @type {string}
   * @memberof UpdateUserDto
   */
  teamId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserDto
   */
  role?: UpdateUserDtoRoleEnum
  /**
   *
   * @type {string}
   * @memberof UpdateUserDto
   */
  seal?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserDto
   */
  toCustomerEmails?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateUserDto
   */
  status?: UpdateUserDtoStatusEnum
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserDto
   */
  managedTeamsIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserDto
   */
  ccTeamIds?: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserDtoRoleEnum {
  Customer = 'customer',
  Internal = 'internal',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateUserDtoStatusEnum {
  Invited = 'invited',
  Active = 'active',
  Blocked = 'blocked',
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string
  /**
   *
   * @type {UserName}
   * @memberof User
   */
  name: UserName
  /**
   *
   * @type {string}
   * @memberof User
   */
  teamId: string
  /**
   *
   * @type {Team}
   * @memberof User
   */
  team: Team
  /**
   *
   * @type {string}
   * @memberof User
   */
  role: UserRoleEnum
  /**
   *
   * @type {string}
   * @memberof User
   */
  seal?: string
  /**
   *
   * @type {Array<Team>}
   * @memberof User
   */
  managedTeams: Array<Team>
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  toCustomerEmails: boolean
  /**
   *
   * @type {Array<Team>}
   * @memberof User
   */
  ccTeams: Array<Team>
  /**
   *
   * @type {string}
   * @memberof User
   */
  status: UserStatusEnum
  /**
   *
   * @type {string}
   * @memberof User
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  updated_at?: string
}

/**
 * @export
 * @enum {string}
 */
export enum UserRoleEnum {
  Customer = 'customer',
  Internal = 'internal',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum UserStatusEnum {
  Invited = 'invited',
  Active = 'active',
  Blocked = 'blocked',
}

/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {Array<string>}
   * @memberof UserDto
   */
  managedTeamsIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UserDto
   */
  ccTeamIds: Array<string>
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  fullName: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  email: string
  /**
   *
   * @type {UserName}
   * @memberof UserDto
   */
  name: UserName
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  teamId: string
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  role: UserDtoRoleEnum
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  seal?: string
  /**
   *
   * @type {boolean}
   * @memberof UserDto
   */
  toCustomerEmails: boolean
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  status: UserDtoStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum UserDtoRoleEnum {
  Customer = 'customer',
  Internal = 'internal',
  Admin = 'admin',
}
/**
 * @export
 * @enum {string}
 */
export enum UserDtoStatusEnum {
  Invited = 'invited',
  Active = 'active',
  Blocked = 'blocked',
}

/**
 *
 * @export
 * @interface UserName
 */
export interface UserName {
  /**
   *
   * @type {string}
   * @memberof UserName
   */
  givenNames?: string
  /**
   *
   * @type {string}
   * @memberof UserName
   */
  familyName?: string
  /**
   *
   * @type {string}
   * @memberof UserName
   */
  initials?: string
}
/**
 *
 * @export
 * @interface WorkflowStepDto
 */
export interface WorkflowStepDto {
  /**
   *
   * @type {AllQuotationStepPayloadTypes}
   * @memberof WorkflowStepDto
   */
  payload?: AllQuotationStepPayloadTypes
  /**
   *
   * @type {string}
   * @memberof WorkflowStepDto
   */
  timestamp: string
  /**
   *
   * @type {UserDto}
   * @memberof WorkflowStepDto
   */
  actor: UserDto
  /**
   *
   * @type {string}
   * @memberof WorkflowStepDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WorkflowStepDto
   */
  quotationId: string
  /**
   *
   * @type {string}
   * @memberof WorkflowStepDto
   */
  type: WorkflowStepDtoTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum WorkflowStepDtoTypeEnum {
  Requested = 'requested',
  InProgress = 'inProgress',
  Assigned = 'assigned',
  Approved = 'approved',
  Ready = 'ready',
  Sent = 'sent',
  Returned = 'returned',
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} parentType
     * @param {string} name
     * @param {string} key
     * @param {any} file
     * @param {string} [parentId]
     * @param {string} [visibility]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerCreate: async (
      parentType: string,
      name: string,
      key: string,
      file: any,
      parentId?: string,
      visibility?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'parentType' is not null or undefined
      assertParamExists('attachmentsControllerCreate', 'parentType', parentType)
      // verify required parameter 'name' is not null or undefined
      assertParamExists('attachmentsControllerCreate', 'name', name)
      // verify required parameter 'key' is not null or undefined
      assertParamExists('attachmentsControllerCreate', 'key', key)
      // verify required parameter 'file' is not null or undefined
      assertParamExists('attachmentsControllerCreate', 'file', file)
      const localVarPath = `/attachments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)()

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (parentId !== undefined) {
        localVarFormParams.append('parentId', parentId as any)
      }

      if (parentType !== undefined) {
        localVarFormParams.append('parentType', parentType as any)
      }

      if (name !== undefined) {
        localVarFormParams.append('name', name as any)
      }

      if (key !== undefined) {
        localVarFormParams.append('key', key as any)
      }

      if (visibility !== undefined) {
        localVarFormParams.append('visibility', visibility as any)
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerDownload: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attachmentsControllerDownload', 'id', id)
      const localVarPath = `/attachments/download/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerFindByParent: async (
      type: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('attachmentsControllerFindByParent', 'type', type)
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attachmentsControllerFindByParent', 'id', id)
      const localVarPath = `/attachments/{type}/{id}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attachmentsControllerRemove', 'id', id)
      const localVarPath = `/attachments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttachmentDto} updateAttachmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerUpdate: async (
      id: string,
      updateAttachmentDto: UpdateAttachmentDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attachmentsControllerUpdate', 'id', id)
      // verify required parameter 'updateAttachmentDto' is not null or undefined
      assertParamExists(
        'attachmentsControllerUpdate',
        'updateAttachmentDto',
        updateAttachmentDto
      )
      const localVarPath = `/attachments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAttachmentDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerLogin: async (
      login: Login,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists('authenticationControllerLogin', 'login', login)
      const localVarPath = `/authentication/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        login,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autifyControllerResetTestData: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/autify/reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailboxControllerGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/mailbox`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateMessageDto} createMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerCreate: async (
      createMessageDto: CreateMessageDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMessageDto' is not null or undefined
      assertParamExists(
        'messagesControllerCreate',
        'createMessageDto',
        createMessageDto
      )
      const localVarPath = `/messages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMessageDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerFindByParent: async (
      type: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('messagesControllerFindByParent', 'type', type)
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesControllerFindByParent', 'id', id)
      const localVarPath = `/messages/{type}/{id}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerRead: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesControllerRead', 'id', id)
      const localVarPath = `/messages/{id}/read`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesControllerRemove', 'id', id)
      const localVarPath = `/messages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerSend: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesControllerSend', 'id', id)
      const localVarPath = `/messages/{id}/send`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMessageDto} updateMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerUpdate: async (
      id: string,
      updateMessageDto: UpdateMessageDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messagesControllerUpdate', 'id', id)
      // verify required parameter 'updateMessageDto' is not null or undefined
      assertParamExists(
        'messagesControllerUpdate',
        'updateMessageDto',
        updateMessageDto
      )
      const localVarPath = `/messages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMessageDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearUncheckedCount: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/notification/clear-unchecked-count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerDelete: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationsControllerDelete', 'id', id)
      const localVarPath = `/notification/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} skip
     * @param {Array<'newQuotationRequest' | 'userAssignedNew' | 'userAssignedBack' | 'customerMessage' | 'internalMessage' | 'priorityChange' | 'completeQuotation'>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerListNotifications: async (
      skip: number,
      types?: Array<
        | 'newQuotationRequest'
        | 'userAssignedNew'
        | 'userAssignedBack'
        | 'customerMessage'
        | 'internalMessage'
        | 'priorityChange'
        | 'completeQuotation'
      >,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'skip' is not null or undefined
      assertParamExists(
        'notificationsControllerListNotifications',
        'skip',
        skip
      )
      const localVarPath = `/notification`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (types) {
        localVarQueryParameter['types'] = types
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerRead: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationsControllerRead', 'id', id)
      const localVarPath = `/notification/read/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ReadNotificationsByTypesDto} readNotificationsByTypesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerReadByTypes: async (
      readNotificationsByTypesDto: ReadNotificationsByTypesDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'readNotificationsByTypesDto' is not null or undefined
      assertParamExists(
        'notificationsControllerReadByTypes',
        'readNotificationsByTypesDto',
        readNotificationsByTypesDto
      )
      const localVarPath = `/notification/read-by-types`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        readNotificationsByTypesDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UnreadNotificationsDto} unreadNotificationsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerUnread: async (
      unreadNotificationsDto: UnreadNotificationsDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'unreadNotificationsDto' is not null or undefined
      assertParamExists(
        'notificationsControllerUnread',
        'unreadNotificationsDto',
        unreadNotificationsDto
      )
      const localVarPath = `/notification/unread`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        unreadNotificationsDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculatorControllerFindPartsPrice: async (
      team: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'team' is not null or undefined
      assertParamExists('priceCalculatorControllerFindPartsPrice', 'team', team)
      const localVarPath = `/price-calculator/partsPrice/{team}`.replace(
        `{${'team'}}`,
        encodeURIComponent(String(team))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateTeamPartsPriceDto} createTeamPartsPriceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculatorControllerUpdatePartsPrice: async (
      createTeamPartsPriceDto: CreateTeamPartsPriceDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTeamPartsPriceDto' is not null or undefined
      assertParamExists(
        'priceCalculatorControllerUpdatePartsPrice',
        'createTeamPartsPriceDto',
        createTeamPartsPriceDto
      )
      const localVarPath = `/price-calculator/partsPrice`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTeamPartsPriceDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {CreateQuotationItemDto} createQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerCreate: async (
      id: string,
      createQuotationItemDto: CreateQuotationItemDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationItemsControllerCreate', 'id', id)
      // verify required parameter 'createQuotationItemDto' is not null or undefined
      assertParamExists(
        'quotationItemsControllerCreate',
        'createQuotationItemDto',
        createQuotationItemDto
      )
      const localVarPath = `/quotations/{id}/items`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createQuotationItemDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerRemove: async (
      itemId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('quotationItemsControllerRemove', 'itemId', itemId)
      const localVarPath = `/quotations/items/{itemId}`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationItemDto} updateQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdate: async (
      itemId: string,
      updateQuotationItemDto: UpdateQuotationItemDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('quotationItemsControllerUpdate', 'itemId', itemId)
      // verify required parameter 'updateQuotationItemDto' is not null or undefined
      assertParamExists(
        'quotationItemsControllerUpdate',
        'updateQuotationItemDto',
        updateQuotationItemDto
      )
      const localVarPath = `/quotations/items/{itemId}`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuotationItemDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceAutoDto} quotationItemPriceAutoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdatePriceAuto: async (
      itemId: string,
      quotationItemPriceAutoDto: QuotationItemPriceAutoDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'quotationItemsControllerUpdatePriceAuto',
        'itemId',
        itemId
      )
      // verify required parameter 'quotationItemPriceAutoDto' is not null or undefined
      assertParamExists(
        'quotationItemsControllerUpdatePriceAuto',
        'quotationItemPriceAutoDto',
        quotationItemPriceAutoDto
      )
      const localVarPath = `/quotations/items/{itemId}/price-auto`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        quotationItemPriceAutoDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceFixedDto} quotationItemPriceFixedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdatePriceFixed: async (
      itemId: string,
      quotationItemPriceFixedDto: QuotationItemPriceFixedDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'quotationItemsControllerUpdatePriceFixed',
        'itemId',
        itemId
      )
      // verify required parameter 'quotationItemPriceFixedDto' is not null or undefined
      assertParamExists(
        'quotationItemsControllerUpdatePriceFixed',
        'quotationItemPriceFixedDto',
        quotationItemPriceFixedDto
      )
      const localVarPath = `/quotations/items/{itemId}/price-fixed`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        quotationItemPriceFixedDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerCopy: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationRequestsControllerCopy', 'id', id)
      const localVarPath = `/quotation-requests/{id}/copy`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerCreate: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/quotation-requests`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerFind: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationRequestsControllerFind', 'id', id)
      const localVarPath = `/quotation-requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationRequestsControllerRemove', 'id', id)
      const localVarPath = `/quotation-requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerSend: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationRequestsControllerSend', 'id', id)
      const localVarPath = `/quotation-requests/{id}/send`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationRequestDto} updateQuotationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerUpdate: async (
      id: string,
      updateQuotationRequestDto: UpdateQuotationRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationRequestsControllerUpdate', 'id', id)
      // verify required parameter 'updateQuotationRequestDto' is not null or undefined
      assertParamExists(
        'quotationRequestsControllerUpdate',
        'updateQuotationRequestDto',
        updateQuotationRequestDto
      )
      const localVarPath = `/quotation-requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuotationRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {QuotationCombineDto} quotationCombineDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCombine: async (
      quotationCombineDto: QuotationCombineDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'quotationCombineDto' is not null or undefined
      assertParamExists(
        'quotationsControllerCombine',
        'quotationCombineDto',
        quotationCombineDto
      )
      const localVarPath = `/quotations/combine`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        quotationCombineDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateQuotationDto} createQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCreate: async (
      createQuotationDto: CreateQuotationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createQuotationDto' is not null or undefined
      assertParamExists(
        'quotationsControllerCreate',
        'createQuotationDto',
        createQuotationDto
      )
      const localVarPath = `/quotations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createQuotationDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCreateForTeam: async (
      teamId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('quotationsControllerCreateForTeam', 'teamId', teamId)
      const localVarPath = `/quotations/team/{teamId}`.replace(
        `{${'teamId'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerFind: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerFind', 'id', id)
      const localVarPath = `/quotations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerFindByRequestId: async (
      requestId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists(
        'quotationsControllerFindByRequestId',
        'requestId',
        requestId
      )
      const localVarPath = `/quotations/by-request-id/{requestId}`.replace(
        `{${'requestId'}}`,
        encodeURIComponent(String(requestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetHtmlPdf: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerGetHtmlPdf', 'id', id)
      const localVarPath = `/quotations/{id}/html-pdf`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetPdf: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerGetPdf', 'id', id)
      const localVarPath = `/quotations/{id}/pdf`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetPdfUrl: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerGetPdfUrl', 'id', id)
      const localVarPath = `/quotations/{id}/pdf/url`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerRemove', 'id', id)
      const localVarPath = `/quotations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerRemoveStep: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerRemoveStep', 'id', id)
      const localVarPath = `/quotations/step/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerResetSteps: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerResetSteps', 'id', id)
      const localVarPath = `/quotations/{id}/reset-steps`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerSendAsEmail: async (
      id: string,
      sendEmailDto: SendEmailDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerSendAsEmail', 'id', id)
      // verify required parameter 'sendEmailDto' is not null or undefined
      assertParamExists(
        'quotationsControllerSendAsEmail',
        'sendEmailDto',
        sendEmailDto
      )
      const localVarPath = `/quotations/{id}/email`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {QuotationSplitDto} quotationSplitDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerSplit: async (
      id: string,
      quotationSplitDto: QuotationSplitDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerSplit', 'id', id)
      // verify required parameter 'quotationSplitDto' is not null or undefined
      assertParamExists(
        'quotationsControllerSplit',
        'quotationSplitDto',
        quotationSplitDto
      )
      const localVarPath = `/quotations/{id}/split`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        quotationSplitDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} step
     * @param {QuotationStepDto} quotationStepDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerStep: async (
      id: string,
      step: string,
      quotationStepDto: QuotationStepDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerStep', 'id', id)
      // verify required parameter 'step' is not null or undefined
      assertParamExists('quotationsControllerStep', 'step', step)
      // verify required parameter 'quotationStepDto' is not null or undefined
      assertParamExists(
        'quotationsControllerStep',
        'quotationStepDto',
        quotationStepDto
      )
      const localVarPath = `/quotations/{id}/step/{step}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'step'}}`, encodeURIComponent(String(step)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        quotationStepDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationDto} updateQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerUpdate: async (
      id: string,
      updateQuotationDto: UpdateQuotationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('quotationsControllerUpdate', 'id', id)
      // verify required parameter 'updateQuotationDto' is not null or undefined
      assertParamExists(
        'quotationsControllerUpdate',
        'updateQuotationDto',
        updateQuotationDto
      )
      const localVarPath = `/quotations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuotationDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerCreate: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('requestItemsControllerCreate', 'id', id)
      const localVarPath = `/quotation-requests/{id}/items`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerRemove: async (
      itemId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('requestItemsControllerRemove', 'itemId', itemId)
      const localVarPath = `/quotation-requests/items/{itemId}`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationRequestItemDto} updateQuotationRequestItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerUpdate: async (
      itemId: string,
      updateQuotationRequestItemDto: UpdateQuotationRequestItemDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('requestItemsControllerUpdate', 'itemId', itemId)
      // verify required parameter 'updateQuotationRequestItemDto' is not null or undefined
      assertParamExists(
        'requestItemsControllerUpdate',
        'updateQuotationRequestItemDto',
        updateQuotationRequestItemDto
      )
      const localVarPath = `/quotation-requests/items/{itemId}`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuotationRequestItemDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateTeamDto} createTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerCreate: async (
      createTeamDto: CreateTeamDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTeamDto' is not null or undefined
      assertParamExists('teamsControllerCreate', 'createTeamDto', createTeamDto)
      const localVarPath = `/teams`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTeamDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerCustomer: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/teams/customer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerFind: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('teamsControllerFind', 'id', id)
      const localVarPath = `/teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerFindAll: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/teams`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('teamsControllerRemove', 'id', id)
      const localVarPath = `/teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateTeamDto} updateTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerUpdate: async (
      id: string,
      updateTeamDto: UpdateTeamDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('teamsControllerUpdate', 'id', id)
      // verify required parameter 'updateTeamDto' is not null or undefined
      assertParamExists('teamsControllerUpdate', 'updateTeamDto', updateTeamDto)
      const localVarPath = `/teams/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeamDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerGetCommon: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/templates/common`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerGetTeam: async (
      teamId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('templatesControllerGetTeam', 'teamId', teamId)
      const localVarPath = `/templates/team/{teamId}`.replace(
        `{${'teamId'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<CommonTemplateDto>} commonTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerUpdateCommon: async (
      commonTemplateDto: Array<CommonTemplateDto>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commonTemplateDto' is not null or undefined
      assertParamExists(
        'templatesControllerUpdateCommon',
        'commonTemplateDto',
        commonTemplateDto
      )
      const localVarPath = `/templates/common`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        commonTemplateDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} teamId
     * @param {Array<TeamTemplateDto>} teamTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerUpdateTeam: async (
      teamId: string,
      teamTemplateDto: Array<TeamTemplateDto>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('templatesControllerUpdateTeam', 'teamId', teamId)
      // verify required parameter 'teamTemplateDto' is not null or undefined
      assertParamExists(
        'templatesControllerUpdateTeam',
        'teamTemplateDto',
        teamTemplateDto
      )
      const localVarPath = `/templates/team/{teamId}`.replace(
        `{${'teamId'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        teamTemplateDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerBlock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerBlock', 'id', id)
      const localVarPath = `/users/{id}/block`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateUserDto} createUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerCreate: async (
      createUserDto: CreateUserDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserDto' is not null or undefined
      assertParamExists('usersControllerCreate', 'createUserDto', createUserDto)
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFind: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerFind', 'id', id)
      const localVarPath = `/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindAll: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindAssignableUsers: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/assignable`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindMe: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/me`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerRemove: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerRemove', 'id', id)
      const localVarPath = `/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerResendUserInvitation: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerResendUserInvitation', 'id', id)
      const localVarPath = `/users/{id}/resend`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerResetUserPassword: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerResetUserPassword', 'id', id)
      const localVarPath = `/users/{id}/reset`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUnblock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerUnblock', 'id', id)
      const localVarPath = `/users/{id}/unblock`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserDto} updateUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdate: async (
      id: string,
      updateUserDto: UpdateUserDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersControllerUpdate', 'id', id)
      // verify required parameter 'updateUserDto' is not null or undefined
      assertParamExists('usersControllerUpdate', 'updateUserDto', updateUserDto)
      const localVarPath = `/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication access-token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} parentType
     * @param {string} name
     * @param {string} key
     * @param {any} file
     * @param {string} [parentId]
     * @param {string} [visibility]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attachmentsControllerCreate(
      parentType: string,
      name: string,
      key: string,
      file: any,
      parentId?: string,
      visibility?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attachmentsControllerCreate(
          parentType,
          name,
          key,
          file,
          parentId,
          visibility,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attachmentsControllerDownload(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AttachmentLinksDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attachmentsControllerDownload(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attachmentsControllerFindByParent(
      type: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AttachmentDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attachmentsControllerFindByParent(
          type,
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attachmentsControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attachmentsControllerRemove(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttachmentDto} updateAttachmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attachmentsControllerUpdate(
      id: string,
      updateAttachmentDto: UpdateAttachmentDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attachmentsControllerUpdate(
          id,
          updateAttachmentDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticationControllerLogin(
      login: Login,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authenticationControllerLogin(
          login,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async autifyControllerResetTestData(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.autifyControllerResetTestData(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailboxControllerGet(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailboxDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.mailboxControllerGet(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {CreateMessageDto} createMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerCreate(
      createMessageDto: CreateMessageDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerCreate(
          createMessageDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerFindByParent(
      type: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<MessageDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerFindByParent(
          type,
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerRead(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerRead(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerRemove(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerSend(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerSend(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMessageDto} updateMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messagesControllerUpdate(
      id: string,
      updateMessageDto: UpdateMessageDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.messagesControllerUpdate(
          id,
          updateMessageDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerClearUncheckedCount(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerClearUncheckedCount(
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerDelete(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {number} skip
     * @param {Array<'newQuotationRequest' | 'userAssignedNew' | 'userAssignedBack' | 'customerMessage' | 'internalMessage' | 'priorityChange' | 'completeQuotation'>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerListNotifications(
      skip: number,
      types?: Array<
        | 'newQuotationRequest'
        | 'userAssignedNew'
        | 'userAssignedBack'
        | 'customerMessage'
        | 'internalMessage'
        | 'priorityChange'
        | 'completeQuotation'
      >,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerListNotifications(
          skip,
          types,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerRead(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerRead(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {ReadNotificationsByTypesDto} readNotificationsByTypesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerReadByTypes(
      readNotificationsByTypesDto: ReadNotificationsByTypesDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerReadByTypes(
          readNotificationsByTypesDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {UnreadNotificationsDto} unreadNotificationsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerUnread(
      unreadNotificationsDto: UnreadNotificationsDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerUnread(
          unreadNotificationsDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceCalculatorControllerFindPartsPrice(
      team: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TeamPartsPriceDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.priceCalculatorControllerFindPartsPrice(
          team,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {CreateTeamPartsPriceDto} createTeamPartsPriceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceCalculatorControllerUpdatePartsPrice(
      createTeamPartsPriceDto: CreateTeamPartsPriceDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TeamPartsPriceDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.priceCalculatorControllerUpdatePartsPrice(
          createTeamPartsPriceDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {CreateQuotationItemDto} createQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationItemsControllerCreate(
      id: string,
      createQuotationItemDto: CreateQuotationItemDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationItemDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationItemsControllerCreate(
          id,
          createQuotationItemDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationItemsControllerRemove(
      itemId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationItemsControllerRemove(
          itemId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationItemDto} updateQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationItemsControllerUpdate(
      itemId: string,
      updateQuotationItemDto: UpdateQuotationItemDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationItemsControllerUpdate(
          itemId,
          updateQuotationItemDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceAutoDto} quotationItemPriceAutoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationItemsControllerUpdatePriceAuto(
      itemId: string,
      quotationItemPriceAutoDto: QuotationItemPriceAutoDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationItemDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationItemsControllerUpdatePriceAuto(
          itemId,
          quotationItemPriceAutoDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceFixedDto} quotationItemPriceFixedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationItemsControllerUpdatePriceFixed(
      itemId: string,
      quotationItemPriceFixedDto: QuotationItemPriceFixedDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationItemDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationItemsControllerUpdatePriceFixed(
          itemId,
          quotationItemPriceFixedDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerCopy(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationRequestDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerCopy(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerCreate(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationRequestDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerCreate(
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerFind(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationRequestDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerFind(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerRemove(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerSend(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerSend(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationRequestDto} updateQuotationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationRequestsControllerUpdate(
      id: string,
      updateQuotationRequestDto: UpdateQuotationRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationRequestsControllerUpdate(
          id,
          updateQuotationRequestDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {QuotationCombineDto} quotationCombineDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerCombine(
      quotationCombineDto: QuotationCombineDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerCombine(
          quotationCombineDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {CreateQuotationDto} createQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerCreate(
      createQuotationDto: CreateQuotationDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerCreate(
          createQuotationDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerCreateForTeam(
      teamId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerCreateForTeam(
          teamId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerFind(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerFind(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerFindByRequestId(
      requestId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<QuotationDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerFindByRequestId(
          requestId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerGetHtmlPdf(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerGetHtmlPdf(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerGetPdf(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerGetPdf(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerGetPdfUrl(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerGetPdfUrl(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerRemove(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerRemoveStep(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerRemoveStep(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerResetSteps(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerResetSteps(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerSendAsEmail(
      id: string,
      sendEmailDto: SendEmailDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WorkflowStepDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerSendAsEmail(
          id,
          sendEmailDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {QuotationSplitDto} quotationSplitDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerSplit(
      id: string,
      quotationSplitDto: QuotationSplitDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerSplit(
          id,
          quotationSplitDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {string} step
     * @param {QuotationStepDto} quotationStepDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerStep(
      id: string,
      step: string,
      quotationStepDto: QuotationStepDto,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WorkflowStepDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerStep(
          id,
          step,
          quotationStepDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationDto} updateQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async quotationsControllerUpdate(
      id: string,
      updateQuotationDto: UpdateQuotationDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.quotationsControllerUpdate(
          id,
          updateQuotationDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestItemsControllerCreate(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QuotationRequestItemDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestItemsControllerCreate(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestItemsControllerRemove(
      itemId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestItemsControllerRemove(
          itemId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationRequestItemDto} updateQuotationRequestItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestItemsControllerUpdate(
      itemId: string,
      updateQuotationRequestItemDto: UpdateQuotationRequestItemDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestItemsControllerUpdate(
          itemId,
          updateQuotationRequestItemDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {CreateTeamDto} createTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerCreate(
      createTeamDto: CreateTeamDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerCreate(
          createTeamDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerCustomer(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerCustomer(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerFind(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerFind(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerFindAll(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerFindAll(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerRemove(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateTeamDto} updateTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async teamsControllerUpdate(
      id: string,
      updateTeamDto: UpdateTeamDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.teamsControllerUpdate(
          id,
          updateTeamDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesControllerGetCommon(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CommonTemplateDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.templatesControllerGetCommon(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesControllerGetTeam(
      teamId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TeamTemplateDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.templatesControllerGetTeam(
          teamId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {Array<CommonTemplateDto>} commonTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesControllerUpdateCommon(
      commonTemplateDto: Array<CommonTemplateDto>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CommonTemplateDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.templatesControllerUpdateCommon(
          commonTemplateDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} teamId
     * @param {Array<TeamTemplateDto>} teamTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesControllerUpdateTeam(
      teamId: string,
      teamTemplateDto: Array<TeamTemplateDto>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TeamTemplateDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.templatesControllerUpdateTeam(
          teamId,
          teamTemplateDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerBlock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerBlock(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {CreateUserDto} createUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerCreate(
      createUserDto: CreateUserDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerCreate(
          createUserDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFind(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerFind(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindAll(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerFindAll(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindAssignableUsers(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerFindAssignableUsers(
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindMe(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerFindMe(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerRemove(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerRemove(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerResendUserInvitation(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerResendUserInvitation(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerResetUserPassword(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerResetUserPassword(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerUnblock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerUnblock(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserDto} updateUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerUpdate(
      id: string,
      updateUserDto: UpdateUserDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerUpdate(
          id,
          updateUserDto,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     *
     * @param {string} parentType
     * @param {string} name
     * @param {string} key
     * @param {any} file
     * @param {string} [parentId]
     * @param {string} [visibility]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerCreate(
      parentType: string,
      name: string,
      key: string,
      file: any,
      parentId?: string,
      visibility?: string,
      options?: any
    ): AxiosPromise<AttachmentDto> {
      return localVarFp
        .attachmentsControllerCreate(
          parentType,
          name,
          key,
          file,
          parentId,
          visibility,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerDownload(
      id: string,
      options?: any
    ): AxiosPromise<AttachmentLinksDto> {
      return localVarFp
        .attachmentsControllerDownload(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerFindByParent(
      type: string,
      id: string,
      options?: any
    ): AxiosPromise<Array<AttachmentDto>> {
      return localVarFp
        .attachmentsControllerFindByParent(type, id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .attachmentsControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttachmentDto} updateAttachmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attachmentsControllerUpdate(
      id: string,
      updateAttachmentDto: UpdateAttachmentDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .attachmentsControllerUpdate(id, updateAttachmentDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerLogin(
      login: Login,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .authenticationControllerLogin(login, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autifyControllerResetTestData(options?: any): AxiosPromise<void> {
      return localVarFp
        .autifyControllerResetTestData(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailboxControllerGet(options?: any): AxiosPromise<MailboxDto> {
      return localVarFp
        .mailboxControllerGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateMessageDto} createMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerCreate(
      createMessageDto: CreateMessageDto,
      options?: any
    ): AxiosPromise<MessageDto> {
      return localVarFp
        .messagesControllerCreate(createMessageDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} type
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerFindByParent(
      type: string,
      id: string,
      options?: any
    ): AxiosPromise<Array<MessageDto>> {
      return localVarFp
        .messagesControllerFindByParent(type, id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerRead(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .messagesControllerRead(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .messagesControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerSend(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .messagesControllerSend(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMessageDto} updateMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messagesControllerUpdate(
      id: string,
      updateMessageDto: UpdateMessageDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .messagesControllerUpdate(id, updateMessageDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearUncheckedCount(
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerClearUncheckedCount(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerDelete(
      id: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerDelete(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} skip
     * @param {Array<'newQuotationRequest' | 'userAssignedNew' | 'userAssignedBack' | 'customerMessage' | 'internalMessage' | 'priorityChange' | 'completeQuotation'>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerListNotifications(
      skip: number,
      types?: Array<
        | 'newQuotationRequest'
        | 'userAssignedNew'
        | 'userAssignedBack'
        | 'customerMessage'
        | 'internalMessage'
        | 'priorityChange'
        | 'completeQuotation'
      >,
      options?: any
    ): AxiosPromise<NotificationsDto> {
      return localVarFp
        .notificationsControllerListNotifications(skip, types, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerRead(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerRead(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ReadNotificationsByTypesDto} readNotificationsByTypesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerReadByTypes(
      readNotificationsByTypesDto: ReadNotificationsByTypesDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerReadByTypes(
          readNotificationsByTypesDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UnreadNotificationsDto} unreadNotificationsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerUnread(
      unreadNotificationsDto: UnreadNotificationsDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerUnread(unreadNotificationsDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculatorControllerFindPartsPrice(
      team: string,
      options?: any
    ): AxiosPromise<TeamPartsPriceDto> {
      return localVarFp
        .priceCalculatorControllerFindPartsPrice(team, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateTeamPartsPriceDto} createTeamPartsPriceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculatorControllerUpdatePartsPrice(
      createTeamPartsPriceDto: CreateTeamPartsPriceDto,
      options?: any
    ): AxiosPromise<TeamPartsPriceDto> {
      return localVarFp
        .priceCalculatorControllerUpdatePartsPrice(
          createTeamPartsPriceDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {CreateQuotationItemDto} createQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerCreate(
      id: string,
      createQuotationItemDto: CreateQuotationItemDto,
      options?: any
    ): AxiosPromise<QuotationItemDto> {
      return localVarFp
        .quotationItemsControllerCreate(id, createQuotationItemDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerRemove(
      itemId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationItemsControllerRemove(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationItemDto} updateQuotationItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdate(
      itemId: string,
      updateQuotationItemDto: UpdateQuotationItemDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationItemsControllerUpdate(itemId, updateQuotationItemDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceAutoDto} quotationItemPriceAutoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdatePriceAuto(
      itemId: string,
      quotationItemPriceAutoDto: QuotationItemPriceAutoDto,
      options?: any
    ): AxiosPromise<QuotationItemDto> {
      return localVarFp
        .quotationItemsControllerUpdatePriceAuto(
          itemId,
          quotationItemPriceAutoDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {QuotationItemPriceFixedDto} quotationItemPriceFixedDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationItemsControllerUpdatePriceFixed(
      itemId: string,
      quotationItemPriceFixedDto: QuotationItemPriceFixedDto,
      options?: any
    ): AxiosPromise<QuotationItemDto> {
      return localVarFp
        .quotationItemsControllerUpdatePriceFixed(
          itemId,
          quotationItemPriceFixedDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerCopy(
      id: string,
      options?: any
    ): AxiosPromise<QuotationRequestDto> {
      return localVarFp
        .quotationRequestsControllerCopy(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerCreate(
      options?: any
    ): AxiosPromise<QuotationRequestDto> {
      return localVarFp
        .quotationRequestsControllerCreate(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerFind(
      id: string,
      options?: any
    ): AxiosPromise<QuotationRequestDto> {
      return localVarFp
        .quotationRequestsControllerFind(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerRemove(
      id: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationRequestsControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerSend(
      id: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationRequestsControllerSend(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationRequestDto} updateQuotationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationRequestsControllerUpdate(
      id: string,
      updateQuotationRequestDto: UpdateQuotationRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationRequestsControllerUpdate(
          id,
          updateQuotationRequestDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {QuotationCombineDto} quotationCombineDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCombine(
      quotationCombineDto: QuotationCombineDto,
      options?: any
    ): AxiosPromise<QuotationDto> {
      return localVarFp
        .quotationsControllerCombine(quotationCombineDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateQuotationDto} createQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCreate(
      createQuotationDto: CreateQuotationDto,
      options?: any
    ): AxiosPromise<QuotationDto> {
      return localVarFp
        .quotationsControllerCreate(createQuotationDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerCreateForTeam(
      teamId: string,
      options?: any
    ): AxiosPromise<QuotationDto> {
      return localVarFp
        .quotationsControllerCreateForTeam(teamId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerFind(
      id: string,
      options?: any
    ): AxiosPromise<QuotationDto> {
      return localVarFp
        .quotationsControllerFind(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerFindByRequestId(
      requestId: string,
      options?: any
    ): AxiosPromise<Array<QuotationDto>> {
      return localVarFp
        .quotationsControllerFindByRequestId(requestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetHtmlPdf(
      id: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .quotationsControllerGetHtmlPdf(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetPdf(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .quotationsControllerGetPdf(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerGetPdfUrl(
      id: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .quotationsControllerGetPdfUrl(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .quotationsControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerRemoveStep(
      id: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationsControllerRemoveStep(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerResetSteps(
      id: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .quotationsControllerResetSteps(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerSendAsEmail(
      id: string,
      sendEmailDto: SendEmailDto,
      options?: any
    ): AxiosPromise<WorkflowStepDto> {
      return localVarFp
        .quotationsControllerSendAsEmail(id, sendEmailDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {QuotationSplitDto} quotationSplitDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerSplit(
      id: string,
      quotationSplitDto: QuotationSplitDto,
      options?: any
    ): AxiosPromise<QuotationDto> {
      return localVarFp
        .quotationsControllerSplit(id, quotationSplitDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} step
     * @param {QuotationStepDto} quotationStepDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerStep(
      id: string,
      step: string,
      quotationStepDto: QuotationStepDto,
      options?: any
    ): AxiosPromise<WorkflowStepDto> {
      return localVarFp
        .quotationsControllerStep(id, step, quotationStepDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateQuotationDto} updateQuotationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quotationsControllerUpdate(
      id: string,
      updateQuotationDto: UpdateQuotationDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .quotationsControllerUpdate(id, updateQuotationDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerCreate(
      id: string,
      options?: any
    ): AxiosPromise<QuotationRequestItemDto> {
      return localVarFp
        .requestItemsControllerCreate(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerRemove(
      itemId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .requestItemsControllerRemove(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} itemId
     * @param {UpdateQuotationRequestItemDto} updateQuotationRequestItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestItemsControllerUpdate(
      itemId: string,
      updateQuotationRequestItemDto: UpdateQuotationRequestItemDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .requestItemsControllerUpdate(
          itemId,
          updateQuotationRequestItemDto,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateTeamDto} createTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerCreate(
      createTeamDto: CreateTeamDto,
      options?: any
    ): AxiosPromise<TeamDto> {
      return localVarFp
        .teamsControllerCreate(createTeamDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerCustomer(options?: any): AxiosPromise<Array<TeamDto>> {
      return localVarFp
        .teamsControllerCustomer(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerFind(id: string, options?: any): AxiosPromise<TeamDto> {
      return localVarFp
        .teamsControllerFind(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerFindAll(options?: any): AxiosPromise<Array<TeamDto>> {
      return localVarFp
        .teamsControllerFindAll(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .teamsControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateTeamDto} updateTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    teamsControllerUpdate(
      id: string,
      updateTeamDto: UpdateTeamDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .teamsControllerUpdate(id, updateTeamDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerGetCommon(
      options?: any
    ): AxiosPromise<Array<CommonTemplateDto>> {
      return localVarFp
        .templatesControllerGetCommon(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerGetTeam(
      teamId: string,
      options?: any
    ): AxiosPromise<Array<TeamTemplateDto>> {
      return localVarFp
        .templatesControllerGetTeam(teamId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Array<CommonTemplateDto>} commonTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerUpdateCommon(
      commonTemplateDto: Array<CommonTemplateDto>,
      options?: any
    ): AxiosPromise<Array<CommonTemplateDto>> {
      return localVarFp
        .templatesControllerUpdateCommon(commonTemplateDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} teamId
     * @param {Array<TeamTemplateDto>} teamTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesControllerUpdateTeam(
      teamId: string,
      teamTemplateDto: Array<TeamTemplateDto>,
      options?: any
    ): AxiosPromise<Array<TeamTemplateDto>> {
      return localVarFp
        .templatesControllerUpdateTeam(teamId, teamTemplateDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerBlock(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersControllerBlock(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateUserDto} createUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerCreate(
      createUserDto: CreateUserDto,
      options?: any
    ): AxiosPromise<UserDto> {
      return localVarFp
        .usersControllerCreate(createUserDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFind(id: string, options?: any): AxiosPromise<UserDto> {
      return localVarFp
        .usersControllerFind(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindAll(options?: any): AxiosPromise<Array<UserDto>> {
      return localVarFp
        .usersControllerFindAll(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindAssignableUsers(
      options?: any
    ): AxiosPromise<Array<UserDto>> {
      return localVarFp
        .usersControllerFindAssignableUsers(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindMe(options?: any): AxiosPromise<UserDto> {
      return localVarFp
        .usersControllerFindMe(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerRemove(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersControllerRemove(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerResendUserInvitation(
      id: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .usersControllerResendUserInvitation(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerResetUserPassword(
      id: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .usersControllerResetUserPassword(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUnblock(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersControllerUnblock(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserDto} updateUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdate(
      id: string,
      updateUserDto: UpdateUserDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .usersControllerUpdate(id, updateUserDto, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {string} parentType
   * @param {string} name
   * @param {string} key
   * @param {any} file
   * @param {string} [parentId]
   * @param {string} [visibility]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public attachmentsControllerCreate(
    parentType: string,
    name: string,
    key: string,
    file: any,
    parentId?: string,
    visibility?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .attachmentsControllerCreate(
        parentType,
        name,
        key,
        file,
        parentId,
        visibility,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public attachmentsControllerDownload(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .attachmentsControllerDownload(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} type
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public attachmentsControllerFindByParent(
    type: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .attachmentsControllerFindByParent(type, id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public attachmentsControllerRemove(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .attachmentsControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateAttachmentDto} updateAttachmentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public attachmentsControllerUpdate(
    id: string,
    updateAttachmentDto: UpdateAttachmentDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .attachmentsControllerUpdate(id, updateAttachmentDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Login} login
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authenticationControllerLogin(
    login: Login,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .authenticationControllerLogin(login, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public autifyControllerResetTestData(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .autifyControllerResetTestData(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public mailboxControllerGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .mailboxControllerGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateMessageDto} createMessageDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerCreate(
    createMessageDto: CreateMessageDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .messagesControllerCreate(createMessageDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} type
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerFindByParent(
    type: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .messagesControllerFindByParent(type, id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerRead(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messagesControllerRead(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerRemove(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messagesControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerSend(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messagesControllerSend(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateMessageDto} updateMessageDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messagesControllerUpdate(
    id: string,
    updateMessageDto: UpdateMessageDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .messagesControllerUpdate(id, updateMessageDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerClearUncheckedCount(
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerClearUncheckedCount(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerDelete(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerDelete(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} skip
   * @param {Array<'newQuotationRequest' | 'userAssignedNew' | 'userAssignedBack' | 'customerMessage' | 'internalMessage' | 'priorityChange' | 'completeQuotation'>} [types]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerListNotifications(
    skip: number,
    types?: Array<
      | 'newQuotationRequest'
      | 'userAssignedNew'
      | 'userAssignedBack'
      | 'customerMessage'
      | 'internalMessage'
      | 'priorityChange'
      | 'completeQuotation'
    >,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerListNotifications(skip, types, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerRead(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerRead(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ReadNotificationsByTypesDto} readNotificationsByTypesDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerReadByTypes(
    readNotificationsByTypesDto: ReadNotificationsByTypesDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerReadByTypes(readNotificationsByTypesDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UnreadNotificationsDto} unreadNotificationsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public notificationsControllerUnread(
    unreadNotificationsDto: UnreadNotificationsDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .notificationsControllerUnread(unreadNotificationsDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} team
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public priceCalculatorControllerFindPartsPrice(
    team: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .priceCalculatorControllerFindPartsPrice(team, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateTeamPartsPriceDto} createTeamPartsPriceDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public priceCalculatorControllerUpdatePartsPrice(
    createTeamPartsPriceDto: CreateTeamPartsPriceDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .priceCalculatorControllerUpdatePartsPrice(
        createTeamPartsPriceDto,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {CreateQuotationItemDto} createQuotationItemDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationItemsControllerCreate(
    id: string,
    createQuotationItemDto: CreateQuotationItemDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationItemsControllerCreate(id, createQuotationItemDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationItemsControllerRemove(
    itemId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationItemsControllerRemove(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {UpdateQuotationItemDto} updateQuotationItemDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationItemsControllerUpdate(
    itemId: string,
    updateQuotationItemDto: UpdateQuotationItemDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationItemsControllerUpdate(itemId, updateQuotationItemDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {QuotationItemPriceAutoDto} quotationItemPriceAutoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationItemsControllerUpdatePriceAuto(
    itemId: string,
    quotationItemPriceAutoDto: QuotationItemPriceAutoDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationItemsControllerUpdatePriceAuto(
        itemId,
        quotationItemPriceAutoDto,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {QuotationItemPriceFixedDto} quotationItemPriceFixedDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationItemsControllerUpdatePriceFixed(
    itemId: string,
    quotationItemPriceFixedDto: QuotationItemPriceFixedDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationItemsControllerUpdatePriceFixed(
        itemId,
        quotationItemPriceFixedDto,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerCopy(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerCopy(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerCreate(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerCreate(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerFind(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerFind(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerRemove(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerSend(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerSend(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateQuotationRequestDto} updateQuotationRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationRequestsControllerUpdate(
    id: string,
    updateQuotationRequestDto: UpdateQuotationRequestDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationRequestsControllerUpdate(id, updateQuotationRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {QuotationCombineDto} quotationCombineDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerCombine(
    quotationCombineDto: QuotationCombineDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerCombine(quotationCombineDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateQuotationDto} createQuotationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerCreate(
    createQuotationDto: CreateQuotationDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerCreate(createQuotationDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerCreateForTeam(
    teamId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerCreateForTeam(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerFind(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerFind(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerFindByRequestId(
    requestId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerFindByRequestId(requestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerGetHtmlPdf(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerGetHtmlPdf(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerGetPdf(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerGetPdf(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerGetPdfUrl(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerGetPdfUrl(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerRemove(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerRemoveStep(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerRemoveStep(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerResetSteps(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerResetSteps(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {SendEmailDto} sendEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerSendAsEmail(
    id: string,
    sendEmailDto: SendEmailDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerSendAsEmail(id, sendEmailDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {QuotationSplitDto} quotationSplitDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerSplit(
    id: string,
    quotationSplitDto: QuotationSplitDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerSplit(id, quotationSplitDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} step
   * @param {QuotationStepDto} quotationStepDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerStep(
    id: string,
    step: string,
    quotationStepDto: QuotationStepDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerStep(id, step, quotationStepDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateQuotationDto} updateQuotationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quotationsControllerUpdate(
    id: string,
    updateQuotationDto: UpdateQuotationDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .quotationsControllerUpdate(id, updateQuotationDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public requestItemsControllerCreate(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .requestItemsControllerCreate(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public requestItemsControllerRemove(
    itemId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .requestItemsControllerRemove(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} itemId
   * @param {UpdateQuotationRequestItemDto} updateQuotationRequestItemDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public requestItemsControllerUpdate(
    itemId: string,
    updateQuotationRequestItemDto: UpdateQuotationRequestItemDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .requestItemsControllerUpdate(
        itemId,
        updateQuotationRequestItemDto,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateTeamDto} createTeamDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerCreate(
    createTeamDto: CreateTeamDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .teamsControllerCreate(createTeamDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerCustomer(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .teamsControllerCustomer(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerFind(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .teamsControllerFind(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerFindAll(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .teamsControllerFindAll(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerRemove(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .teamsControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateTeamDto} updateTeamDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public teamsControllerUpdate(
    id: string,
    updateTeamDto: UpdateTeamDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .teamsControllerUpdate(id, updateTeamDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public templatesControllerGetCommon(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .templatesControllerGetCommon(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public templatesControllerGetTeam(
    teamId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .templatesControllerGetTeam(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<CommonTemplateDto>} commonTemplateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public templatesControllerUpdateCommon(
    commonTemplateDto: Array<CommonTemplateDto>,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .templatesControllerUpdateCommon(commonTemplateDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} teamId
   * @param {Array<TeamTemplateDto>} teamTemplateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public templatesControllerUpdateTeam(
    teamId: string,
    teamTemplateDto: Array<TeamTemplateDto>,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .templatesControllerUpdateTeam(teamId, teamTemplateDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerBlock(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerBlock(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateUserDto} createUserDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerCreate(
    createUserDto: CreateUserDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .usersControllerCreate(createUserDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFind(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerFind(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindAll(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerFindAll(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindAssignableUsers(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerFindAssignableUsers(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindMe(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerFindMe(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerRemove(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerRemove(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerResendUserInvitation(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .usersControllerResendUserInvitation(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerResetUserPassword(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .usersControllerResetUserPassword(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerUnblock(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersControllerUnblock(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {UpdateUserDto} updateUserDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerUpdate(
    id: string,
    updateUserDto: UpdateUserDto,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .usersControllerUpdate(id, updateUserDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
