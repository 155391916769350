import { Module, Mutation, Action } from 'vuex-module-decorators'
import { DeepPartial } from 'tsdef'
import BaseRemoteStore, {
  RemoteFieldQuery,
  RemoteFieldQueryDict,
  RemoteIdDict,
} from './BaseRemoteStore'
import { mailboxStore, tabStore } from '.'
import Quotation from '~/models/Quotation'

import QuotationRequest from '~/models/QuotationRequest'
import QuotationRequestItem from '~/models/QuotationRequestItem'
import remote from '~/remote'
import normalizedUpdator from '~/utils/normalizedUpdator'
import { PartialRemoteModel } from '~/utils/store'
import {
  ItemDetails,
  QuotationRequestDtoStatusEnum,
  QuotationItemTypeEnum,
  UpdateQuotationRequestDto,
  UpdateQuotationRequestItemDto,
} from '~/remote/api-spec'

export enum QuotationRequestSourceType {
  AS_ONLY,
  FACTORY_ONLY,
  MIXED,
}

export enum QuotationRequestExternalStatus {
  CREATING,
  REQUEST_SENT,
  REQUEST_ACKNOWLEDGED,
  QUOTATION_IN_PROGESS,
  APPROVAL_COMPLETE,
  DONE,
}

@Module({
  name: 'QuotationRequestStore',
  namespaced: true,
  stateFactory: true,
})
export default class QuotationRequestStore extends BaseRemoteStore {
  readonly quotationRequestsById: RemoteIdDict<QuotationRequest> = {}
  readonly quotationRequestItemsById: RemoteIdDict<QuotationRequestItem> = {}
  readonly quotationsItemsByQuotationId: RemoteFieldQueryDict<QuotationRequestItem> =
    {}

  // Driven by the tabs components. This is defined if a tab is visiable and has a request loaded.
  _activeLoadedQuotationRequestId: string | null = null

  _itemParentReferenceField: RemoteFieldQuery<QuotationRequestItem> = {
    field: 'requestId',
    fieldDict: this.quotationsItemsByQuotationId,
  }

  get quotationRequests() {
    return BaseRemoteStore.listModels(this.quotationRequestsById)
  }

  get getQuotationRequestById() {
    return BaseRemoteStore.getById(this.quotationRequestsById)
  }

  get quotationRequestItems() {
    return BaseRemoteStore.listModels(this.quotationRequestItemsById)
  }

  get getQuotationRequestItemById() {
    return BaseRemoteStore.getById(this.quotationRequestItemsById)
  }

  get getQuotationRequestItemByQuotationRequestId() {
    return BaseRemoteStore.getListByField(this.quotationsItemsByQuotationId)
  }

  get getCanReRequestActiveRequest() {
    if (!this.loadedQuotationRequestId) return false
    const activeQuotationRequest = this.getQuotationRequestById(
      this.loadedQuotationRequestId!
    )!
    return (
      activeQuotationRequest.status ===
        QuotationRequestDtoStatusEnum.Resolved ||
      activeQuotationRequest.status ===
        QuotationRequestDtoStatusEnum.Complete ||
      activeQuotationRequest.status === QuotationRequestDtoStatusEnum.Quoting
    )
  }

  get loadedQuotationRequestId() {
    return this._activeLoadedQuotationRequestId
  }

  @Mutation
  setActiveLoadedQuotationRequestId(quotationRequestId: string | null) {
    this._activeLoadedQuotationRequestId = quotationRequestId
  }

  @Mutation
  _setQuotationRequest({
    model,
    isLocal,
  }: {
    model: QuotationRequest
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(this.quotationRequestsById, model, isLocal ?? true)
  }

  @Mutation
  _setQuotationRequestPartial(
    quotationRequestPartial: PartialRemoteModel<QuotationRequest>
  ) {
    BaseRemoteStore.setPartialById(
      this.quotationRequestsById,
      quotationRequestPartial
    )
  }

  @Mutation
  _setQuotationRequestDeleted(quotationRequestId: string) {
    BaseRemoteStore.deleteById(this.quotationRequestsById, quotationRequestId)
  }

  @Mutation
  _setQuotationRequestItem({
    model,
    isLocal,
  }: {
    model: QuotationRequestItem
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(
      this.quotationRequestItemsById,
      model,
      isLocal ?? true,
      this._itemParentReferenceField
    )
  }

  @Mutation
  _setQuotationRequestItemPartial(
    quotationRequestItemPartial: PartialRemoteModel<QuotationRequestItem>
  ) {
    BaseRemoteStore.setPartialById(
      this.quotationRequestItemsById,
      quotationRequestItemPartial,
      this._itemParentReferenceField
    )
  }

  @Mutation
  _setQuotationRequestItemDeleted(quotationRequestItemId: string) {
    BaseRemoteStore.deleteById(
      this.quotationRequestItemsById,
      quotationRequestItemId,
      this._itemParentReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(
      this.quotationRequestsById,
      this.quotationRequestItemsById,
      this.quotationsItemsByQuotationId
    )
    this._activeLoadedQuotationRequestId = null
  }

  @Action
  async loadQuotationRequest(quotationRequestId: string) {
    const quotationRequestQuery =
      remote.api.quotationRequestsControllerFind(quotationRequestId)
    const quotationQuery =
      remote.api.quotationsControllerFindByRequestId(quotationRequestId)
    const [quotationRequest, quotations] = await Promise.all([
      quotationRequestQuery,
      quotationQuery,
    ])
    normalizedUpdator.normalizeAndUpdate(quotations.data, Quotation.arraySchema)
    normalizedUpdator.normalizeAndUpdate(
      quotationRequest.data,
      QuotationRequest.schema
    )

    const quotationRequestData =
      this.getQuotationRequestById(quotationRequestId)!
    BaseRemoteStore.purgeMissingByField(
      quotationRequestData.items.map((item) => item.id),
      quotationRequestId,
      this._itemParentReferenceField,
      this._setQuotationRequestItemDeleted
    )

    return quotationRequestId
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      QuotationRequest.schema,
      QuotationRequest,
      this._handleQuotationRequestUpdate
    )
    normalizedUpdator.registerSchemaListener(
      QuotationRequestItem.schema,
      QuotationRequestItem,
      this._setQuotationRequestItem
    )
  }

  @Action
  async createQuotationRequest() {
    const quotationRequestResults =
      await remote.api.quotationRequestsControllerCreate()
    const quotationRequestId = normalizedUpdator.normalizeAndUpdate(
      quotationRequestResults.data,
      QuotationRequest.schema
    )
    this._updateMailboxes({
      quotationRequest: this.quotationRequestsById[quotationRequestId]!,
      isCreate: true,
    })
    return quotationRequestId
  }

  @Action
  async updateQuotationRequestPartial(
    quotationRequestUpdate: PartialRemoteModel<
      UpdateQuotationRequestDto & { id: string }
    >
  ) {
    this._setQuotationRequestPartial(
      quotationRequestUpdate as PartialRemoteModel<QuotationRequest>
    )
    this._updateMailboxes({
      quotationRequest: this.quotationRequestsById[quotationRequestUpdate.id]!,
    })
    await remote.api.quotationRequestsControllerUpdate(
      quotationRequestUpdate.id,
      quotationRequestUpdate
    )
  }

  @Action
  async deleteQuotationRequest(quotationRequestId: string) {
    this._setQuotationRequestItemDeleted(quotationRequestId)
    mailboxStore.handleQuotationRequestDelete(quotationRequestId)
    tabStore.closeQuotationRequestTab(quotationRequestId)
    await remote.api.quotationRequestsControllerRemove(quotationRequestId)
  }

  @Action
  _handleQuotationRequestUpdate({
    model,
    isLocal,
  }: {
    model: QuotationRequest
    isLocal?: boolean
  }) {
    this._setQuotationRequest({ model, isLocal })
    this._updateMailboxes({ quotationRequest: model })
  }

  @Action
  _updateMailboxes({
    quotationRequest,
    isCreate,
  }: {
    quotationRequest: QuotationRequest
    isCreate?: boolean
  }) {
    setTimeout(() => {
      mailboxStore.handleQuotationRequestUpdate({
        quotationRequest,
        isCreate: isCreate ?? false,
      })
      for (const quotation of quotationRequest.quotations) {
        mailboxStore.handleQuotationUpdate({
          quotation,
          isCreate: isCreate ?? false,
        })
      }
    })
  }

  @Action
  async sendQuotationRequest(quotationRequestId: string) {
    await remote.api.quotationRequestsControllerSend(quotationRequestId)
    this._setQuotationRequestPartial({
      id: quotationRequestId,
      status: QuotationRequestDtoStatusEnum.Complete,
      sentDate: new Date().toISOString(),
    })
    await this.loadQuotationRequest(quotationRequestId)
  }

  @Action
  async createQuotationRequestItem(quotationRequestId: string) {
    const quotationRequestItemResults =
      await remote.api.requestItemsControllerCreate(quotationRequestId)
    return normalizedUpdator.normalizeAndUpdate(
      quotationRequestItemResults.data,
      QuotationRequestItem.schema
    )
  }

  @Action
  async deleteQuotationRequestItem(quotationRequestItemId: string) {
    this._setQuotationRequestItemDeleted(quotationRequestItemId)
    await remote.api.requestItemsControllerRemove(quotationRequestItemId)
  }

  @Action
  async updateQuotationRequestItemPartial(quotationRequestItemUpdate: {
    id: string
    details: DeepPartial<ItemDetails>
    itemType: QuotationItemTypeEnum
    requestBody?: string
  }) {
    this._setQuotationRequestItemPartial(
      quotationRequestItemUpdate as PartialRemoteModel<QuotationRequestItem>
    )
    const quotationRequestItem = this.getQuotationRequestItemById(
      quotationRequestItemUpdate.id
    )!
    const dto: UpdateQuotationRequestItemDto = {
      details: quotationRequestItem.details,
      itemType: quotationRequestItem.itemType,
    }
    if (quotationRequestItem.requestBody !== undefined) {
      Object.assign(dto, {
        requestBody: quotationRequestItem.requestBody,
      })
    }
    await remote.api.requestItemsControllerUpdate(
      quotationRequestItemUpdate.id,
      dto
    )
  }

  @Action
  async reRequestActiveQuotationRequest() {
    const quotationRequestResults =
      await remote.api.quotationRequestsControllerCopy(
        this.loadedQuotationRequestId!
      )
    const quotationRequestId = normalizedUpdator.normalizeAndUpdate(
      quotationRequestResults.data,
      QuotationRequest.schema
    )
    this._updateMailboxes({
      quotationRequest: this.quotationRequestsById[quotationRequestId]!,
      isCreate: true,
    })
    return quotationRequestId
  }
}
