import _ from 'lodash'
import { VuexModule } from 'vuex-module-decorators'
import Vue from 'vue'
import { PartialRemoteModel } from '~/utils/store'

import RemoteModel from '~/models/RemoteModel'

export type RemoteIdDict<T extends RemoteModel> = { [key: string]: T }
export type RemoteFieldQueryDict<T extends RemoteModel> = {
  [key: string]: { [key: string]: T }
}
export type RemoteFieldQuery<T extends RemoteModel> = {
  fieldDict: RemoteFieldQueryDict<T>
  field: keyof T
}

const DIRTY_TIMEOUT = 30 * 1000

// Maintains a global list of object Ids that have been recently edited locally and the timestamp.
// Server polls do not update this list
// Blocks out server based updates for DIRTY_TIMEOUT whenever an object is updated
// Object id => timestamp
const dirtyFlagMap: {
  [key: string]: number
} = {}

export default class BaseRemoteStore extends VuexModule {
  static isDirty(modelId: string) {
    const dirtyFlag = dirtyFlagMap[modelId]
    if (!dirtyFlag) return false
    if (new Date().getTime() - DIRTY_TIMEOUT > dirtyFlag) {
      return false
    } else {
      return true
    }
  }

  static setDirty(modelId: string) {
    dirtyFlagMap[modelId] = new Date().getTime()
  }

  static clearDirty() {
    for (const member in dirtyFlagMap) delete dirtyFlagMap[member]
  }

  static clearDirtyByIds(ids: string[]) {
    for (const id of ids) delete dirtyFlagMap[id]
  }

  static clearDicts(
    ...dicts: Array<RemoteIdDict<any> | RemoteFieldQueryDict<any>>
  ) {
    for (const dict of dicts) {
      for (const member in dict) Vue.delete(dict, member)
    }
  }

  static listModels<T extends RemoteModel>(modelList: RemoteIdDict<T>) {
    return Object.values(modelList)
  }

  static getById<T extends RemoteModel>(modelList: RemoteIdDict<T>) {
    return (modelId: string) => {
      return modelList[modelId]
    }
  }

  static getListByField<T extends RemoteModel>(
    modelList: RemoteFieldQueryDict<T>
  ) {
    return (fieldValue: string) => {
      return modelList[fieldValue] ? Object.values(modelList[fieldValue]!) : []
    }
  }

  static setById<T extends RemoteModel>(
    modelList: RemoteIdDict<T>,
    model: T,
    isLocal: boolean,
    ...remoteFieldQueries: RemoteFieldQuery<T>[]
  ) {
    if (!isLocal) {
      if (this.isDirty(model.id)) {
        return
      }
    }

    Vue.set(modelList, model.id, model)
    if (remoteFieldQueries) {
      for (const fieldQuery of remoteFieldQueries) {
        const field = model[fieldQuery.field] as unknown as string
        if (field === undefined) continue
        if (!fieldQuery.fieldDict[field]) {
          Vue.set(fieldQuery.fieldDict, field, [])
        }
        Vue.set(fieldQuery.fieldDict[field]!, model.id, model)
      }
    }

    if (isLocal) {
      this.setDirty(model.id)
    }
  }

  static mergeWith<T extends { [id: string]: any }>(
    current: T,
    partial: { [id: string]: any }
  ): T {
    const currentKeys: string[] = Object.keys(current)
    const newKeys: Set<string> = new Set<string>(Object.keys(partial))

    for (const key of currentKeys) {
      newKeys.delete(key)

      if (Object.hasOwnProperty.apply(partial, [key])) {
        const newValue = partial[key]
        const oldValue = current[key]
        if (newValue === oldValue) {
          continue
        }
        if (newValue === undefined) {
          Vue.delete(current, key)
          continue
        }

        if (typeof newValue !== typeof oldValue) {
          Vue.set(current, key, newValue)
          continue
        }
        if (_.isArray(newValue)) {
          Vue.set(current, key, newValue)
          continue
        }
        if (_.isObjectLike(newValue)) {
          BaseRemoteStore.mergeWith(current[key], partial[key])
          continue
        }
        Vue.set(current, key, newValue)
      }
    }

    for (const newKey of newKeys) {
      Vue.set(current, newKey, partial[newKey])
    }

    return current
  }

  static setPartialById<T extends RemoteModel>(
    modelList: RemoteIdDict<T>,
    modelPartial: PartialRemoteModel<T>,
    ...remoteFieldQueries: RemoteFieldQuery<T>[]
  ) {
    const currentModel = modelList[modelPartial.id]
    if (!currentModel) {
      throw new Error("Tried to partial update an object that doesn't exist")
    }

    const mergedData = BaseRemoteStore.mergeWith(currentModel, modelPartial)

    Vue.set(modelList, currentModel.id, mergedData)
    if (remoteFieldQueries) {
      for (const fieldQuery of remoteFieldQueries) {
        const field = modelPartial[fieldQuery.field] as unknown as string
        if (field === undefined) continue
        if (!fieldQuery.fieldDict[field]) {
          Vue.set(fieldQuery.fieldDict, field, [])
        }
        Vue.set(fieldQuery.fieldDict[field]!, modelPartial.id, mergedData)
      }
    }

    this.setDirty(modelPartial.id)
  }

  static deleteById<T extends RemoteModel>(
    modelList: RemoteIdDict<T>,
    modelId: string,
    ...remoteFieldQueries: RemoteFieldQuery<T>[]
  ) {
    const existingModel = modelList[modelId]
    Vue.delete(modelList, modelId)
    if (remoteFieldQueries && existingModel) {
      for (const fieldQuery of remoteFieldQueries) {
        const field = existingModel[fieldQuery.field] as unknown as string
        if (field === undefined) continue
        if (!fieldQuery.fieldDict[field]) {
          continue
        }
        Vue.delete(fieldQuery.fieldDict[field]!, modelId)
      }
    }

    this.setDirty(modelId)
  }

  // Removes all missing objects by field
  static purgeMissingByField<T extends RemoteModel>(
    modelIds: string[],
    fieldValue: string,
    parentReferenceField: RemoteFieldQuery<T>,
    deletionFunction: (modelId: string) => void
  ) {
    const existingModelDict = parentReferenceField.fieldDict[fieldValue] ?? {}
    for (const existingModel of Object.values(existingModelDict)) {
      if (!modelIds.includes(existingModel.id)) {
        deletionFunction(existingModel.id)
      }
    }
  }

  // Removes all missing objects by id
  static purgeMissingById<T extends RemoteModel>(
    modelIds: string[],
    existingModelDict: RemoteIdDict<T>,
    deletionFunction: (modelId: string) => void
  ) {
    for (const existingModel of Object.values(existingModelDict)) {
      if (!modelIds.includes(existingModel.id)) {
        deletionFunction(existingModel.id)
      }
    }
  }
}
