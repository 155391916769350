




















import { Vue, Component, Watch } from 'nuxt-property-decorator'
import Footer from '~/components/Footer.vue'
import Logo from '~/components/Logo.vue'
import UserWidget from '~/components/UserWidget.vue'
import SearchWidget from '~/components/search/SearchWidget.vue'
import SyncIndicator from '~/components/SyncIndicator.vue'
import { uiStore } from '~/store'
import NotificationsWidget from '~/components/notifications/NotificationsWidget.vue'

@Component({
  components: {
    SearchWidget,
    Logo,
    UserWidget,
    Footer,
    SyncIndicator,
    NotificationsWidget,
  },
})
export default class Default extends Vue {
  pageScrollX: number = 0
  pageScrollY: number = 0

  get isOverlayOpen() {
    return uiStore.isOverlayOpen
  }

  @Watch('isOverlayOpen')
  overlayVisibilityChanged() {
    const container = this.$refs.overlayContainer as HTMLDivElement
    if (this.isOverlayOpen) {
      this.pageScrollX = window.pageXOffset
      this.pageScrollY = window.pageYOffset
      container.style.left = -this.pageScrollX + 'px'
      container.style.top = -this.pageScrollY + 'px'
    } else {
      container.style.left = '0px'
      container.style.top = '0px'
      this.$nextTick(() => {
        window.scrollTo(this.pageScrollX, this.pageScrollY)
      })
    }
  }
}
