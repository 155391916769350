import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({
  name: 'UiStore',
  namespaced: true,
  stateFactory: true,
})
export default class UiStore extends VuexModule {
  overlayCount: number = 0

  get isOverlayOpen() {
    return this.overlayCount > 0
  }

  @Mutation
  incrementOverlayCount() {
    this.overlayCount++
  }

  @Mutation
  decrementOverlayCount() {
    this.overlayCount--
  }

  @Action
  addOverlay() {
    this.incrementOverlayCount()
  }

  @Action
  removeOverlay() {
    this.decrementOverlayCount()
  }
}
