import { Configuration, DefaultApi } from './api-spec'

class Remote {
  private path = process.env.apiBaseUrl

  _api: DefaultApi = new DefaultApi(new Configuration({ basePath: this.path }))

  get api(): DefaultApi {
    return this._api
  }

  setAuthCredentials(jwtToken: string) {
    this._api = new DefaultApi(
      new Configuration({
        basePath: this.path,
        accessToken: jwtToken,
      })
    )
  }
}

export default new Remote()
