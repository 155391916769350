import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import AuthStore from '~/store/AuthStore'
import AttachmentStore from '~/store/AttachmentStore'
import TabStore from '~/store/TabStore'
import QuotationStore from '~/store/QuotationStore'
import QuotationRequestStore from '~/store/QuotationRequestStore'
import UserStore from '~/store/UserStore'
import SubscriptionStore from '~/store/SubscriptionStore'
import MessageStore from '~/store/MessageStore'
import MailboxStore from '~/store/MailboxStore'
import TeamStore from '~/store/TeamStore'
import SyncStatusStore from '~/store/SyncStatusStore'
import PartPriceStore from '~/store/PartPriceStore'
import UiStore from '~/store/UiStore'
import BaseRemoteStore from '~/store/BaseRemoteStore'
import NotificationsStore from '~/store/NotificationsStore'
import CommonTemplateStore from '~/store/CommonTemplateStore'
import TeamTemplateStore from '~/store/TeamTemplateStore'

/* eslint-disable import/no-mutable-exports */
let tabStore: TabStore
let authStore: AuthStore
let quotationStore: QuotationStore
let quotationRequestStore: QuotationRequestStore
let userStore: UserStore
let messageStore: MessageStore
let subscriptionStore: SubscriptionStore
let attachmentStore: AttachmentStore
let mailboxStore: MailboxStore
let teamStore: TeamStore
let syncStatusStore: SyncStatusStore
let partPriceStore: PartPriceStore
let uiStore: UiStore
let notificationsStore: NotificationsStore
let commonTemplateStore: CommonTemplateStore
let teamTemplateStore: TeamTemplateStore
/* eslint-enable import/no-mutable-exports */

function initialiseStores(store: Store<any>): void {
  authStore = getModule(AuthStore, store)
  tabStore = getModule(TabStore, store)
  quotationStore = getModule(QuotationStore, store)
  quotationStore.registerListeners()
  quotationRequestStore = getModule(QuotationRequestStore, store)
  quotationRequestStore.registerListeners()
  userStore = getModule(UserStore, store)
  userStore.registerListeners()
  attachmentStore = getModule(AttachmentStore, store)
  attachmentStore.registerListeners()
  messageStore = getModule(MessageStore, store)
  messageStore.registerListeners()
  mailboxStore = getModule(MailboxStore, store)
  mailboxStore.registerListeners()
  teamStore = getModule(TeamStore, store)
  teamStore.registerListeners()
  subscriptionStore = getModule(SubscriptionStore, store)
  syncStatusStore = getModule(SyncStatusStore, store)
  syncStatusStore.registerListeners()
  partPriceStore = getModule(PartPriceStore, store)
  partPriceStore.registerListeners()
  uiStore = getModule(UiStore, store)
  notificationsStore = getModule(NotificationsStore, store)
  notificationsStore.registerListeners()
  commonTemplateStore = getModule(CommonTemplateStore, store)
  commonTemplateStore.registerListeners()
  teamTemplateStore = getModule(TeamTemplateStore, store)
  teamTemplateStore.registerListeners()

  console.log('stores loaded')
}

function clearStores() {
  BaseRemoteStore.clearDirty()
  attachmentStore.clearData()
  authStore.clearData()
  mailboxStore.clearData()
  messageStore.clearData()
  quotationRequestStore.clearData()
  quotationStore.clearData()
  subscriptionStore.clearData()
  syncStatusStore.clearData()
  tabStore.clearData()
  teamStore.clearData()
  userStore.clearData()
  partPriceStore.clearData()
  commonTemplateStore.clearData()
  teamTemplateStore.clearData()
}

export {
  initialiseStores,
  clearStores,
  tabStore,
  authStore,
  quotationStore,
  quotationRequestStore,
  userStore,
  messageStore,
  subscriptionStore,
  attachmentStore,
  mailboxStore,
  teamStore,
  syncStatusStore,
  partPriceStore,
  uiStore,
  notificationsStore,
  commonTemplateStore,
  teamTemplateStore,
}
