// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import Quotation from './Quotation'
import QuotationItem from './QuotationItem'
import QuotationRequestItem from './QuotationRequestItem'
import RemoteModel from './RemoteModel'
import User from './User'
import Team from './Team'
import {
  quotationRequestStore,
  quotationStore,
  teamStore,
  userStore,
} from '~/store'
import {
  QuotationItemTypeEnum,
  QuotationRequestDto,
  QuotationRequestDtoStatusEnum,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'

export type NormalizedQuotationRequest = Omit<
  QuotationRequestDto,
  'customer' | 'items' | 'customerTeam'
> & {
  customerId: string
  customerTeamId: string
}

export enum QuotationRequestSourceType {
  AS_ONLY,
  FACTORY_ONLY,
  MIXED,
}

export enum QuotationRequestExternalStatus {
  CREATING,
  REQUEST_SENT,
  REQUEST_ACKNOWLEDGED,
  QUOTATION_IN_PROGRESS,
  APPROVAL_COMPLETE,
  DONE,
}

export default class QuotationRequest
  extends RemoteModel
  implements NormalizedQuotationRequest
{
  customerId!: string
  externalId!: string
  referenceId!: string
  sentDate!: string
  status!: QuotationRequestDtoStatusEnum
  archived!: boolean
  createdOn!: string
  customerTeamId!: string

  static schema: schema.Entity = new schema.Entity<QuotationRequestDto>(
    'quotationRequests',
    {
      items: [QuotationRequestItem.schema],
      customerId: User.schema,
      customerTeamId: Team.schema,
    },
    {
      processStrategy: (value) => ({
        ...value,
        customerId: value.customer,
        customerTeamId: value.customerTeam,
      }),
    }
  )

  get customer() {
    return userStore.getUserById(this.customerId)!
  }

  get assignedInternalUser() {
    return this.quotations.length > 0
      ? this.quotations[0]!.assignedUser
      : undefined
  }

  get assignedInternalTeam() {
    return this.quotations.length > 0
      ? this.quotations[0]!.internalTeam
      : undefined
  }

  get items() {
    return quotationRequestStore
      .getQuotationRequestItemByQuotationRequestId(this.id)
      .sort((itemA, itemB) => itemA.details.ordinal - itemB.details.ordinal)
  }

  get quotations() {
    return quotationStore.getQuotationsByRequestId(this.id)
  }

  get originalQuotation() {
    const quotations = this.quotations.sort(
      (a, b) =>
        new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
    )
    return quotations[0]
  }

  get customerTeam() {
    return teamStore.getTeamByTeamId(this.customerTeamId)!
  }

  get externalStatus(): QuotationRequestExternalStatus {
    if (this.status === QuotationRequestDtoStatusEnum.Creating) {
      return QuotationRequestExternalStatus.CREATING
    }

    let lowestStatus: QuotationRequestExternalStatus | null = null
    const statusOrder = [
      QuotationRequestExternalStatus.CREATING,
      QuotationRequestExternalStatus.REQUEST_SENT,
      QuotationRequestExternalStatus.REQUEST_ACKNOWLEDGED,
      QuotationRequestExternalStatus.QUOTATION_IN_PROGRESS,
      QuotationRequestExternalStatus.APPROVAL_COMPLETE,
      QuotationRequestExternalStatus.DONE,
    ]
    this.quotations.forEach((quotation: Quotation) => {
      let matchingStatus = null
      switch (quotation.status) {
        case WorkflowStepDtoTypeEnum.Requested:
          matchingStatus = QuotationRequestExternalStatus.REQUEST_ACKNOWLEDGED
          break
        case WorkflowStepDtoTypeEnum.InProgress:
          if (
            quotation.items.some(
              (item) =>
                item.itemType === QuotationItemTypeEnum.Unit &&
                item.isAS === null
            )
          ) {
            matchingStatus = QuotationRequestExternalStatus.REQUEST_ACKNOWLEDGED
          } else {
            matchingStatus =
              QuotationRequestExternalStatus.QUOTATION_IN_PROGRESS
          }
          break
        case WorkflowStepDtoTypeEnum.Returned:
        case WorkflowStepDtoTypeEnum.Assigned:
        case WorkflowStepDtoTypeEnum.Approved:
          matchingStatus = QuotationRequestExternalStatus.QUOTATION_IN_PROGRESS
          break
        case WorkflowStepDtoTypeEnum.Ready:
          matchingStatus = QuotationRequestExternalStatus.APPROVAL_COMPLETE
          break
        case WorkflowStepDtoTypeEnum.Sent:
        default:
          matchingStatus = QuotationRequestExternalStatus.DONE
          break
      }
      if (
        !lowestStatus ||
        statusOrder.indexOf(matchingStatus) < statusOrder.indexOf(lowestStatus)
      ) {
        lowestStatus = matchingStatus
      }
    })
    return lowestStatus || QuotationRequestExternalStatus.REQUEST_SENT
  }

  get requestResponseSource() {
    let hasAS
    let hasFactory

    this.quotations.forEach((quotation: Quotation) => {
      quotation.items.forEach((quotationItem: QuotationItem) => {
        if (quotationItem.isAS) {
          hasAS = true
        } else {
          hasFactory = true
        }
      })
    })
    if (hasAS && hasFactory) {
      return QuotationRequestSourceType.MIXED
    } else if (hasFactory) {
      return QuotationRequestSourceType.FACTORY_ONLY
    } else {
      return QuotationRequestSourceType.AS_ONLY
    }
  }

  get isInternal() {
    // if the quotation requesting user is the assigned user, it was created internally
    return this.quotations.some((quotation) => {
      const requestingUserId = quotation.workflowSteps.find(
        (workflowStep) =>
          workflowStep.type === WorkflowStepDtoTypeEnum.Requested
      )?.actorId
      const startedUserId = quotation.workflowSteps.find(
        (workflowStep) =>
          workflowStep.type === WorkflowStepDtoTypeEnum.InProgress
      )?.actorId

      return requestingUserId === startedUserId
    })
  }

  get requestAcknowledgedDate() {
    if (this.quotations.length === 0) {
      return null
    }
    let earliestDate: Date | null = null
    this.quotations.forEach((quotation: Quotation) => {
      const createdDate = new Date(quotation.createdOn!)
      if (!earliestDate || earliestDate.getTime() > createdDate.getTime()) {
        earliestDate = createdDate
      }
    })
    return earliestDate!
  }

  get splitDate() {
    if (this.quotations.length <= 1) {
      return null
    }
    let latestDate: Date | null = null
    this.quotations.forEach((quotation: Quotation) => {
      const createdDate = new Date(quotation.createdOn!)
      if (!latestDate || latestDate.getTime() < createdDate.getTime()) {
        latestDate = createdDate
      }
    })
    return latestDate
  }

  get inProgressBegunDate() {
    if (this.quotations.length === 0) {
      return null
    }
    let latestDate: Date | null = null
    this.quotations.forEach((quotation: Quotation) => {
      if (!quotation.progressBegunDate) return
      const progressBegunDate = new Date(quotation.progressBegunDate!)
      if (!latestDate || latestDate.getTime() < progressBegunDate.getTime()) {
        latestDate = progressBegunDate
      }
    })
    return latestDate
  }

  get approvalCompleteDate() {
    if (this.quotations.length === 0) {
      return null
    }
    let latestDate: Date | null = null
    this.quotations.forEach((quotation: Quotation) => {
      if (!quotation.confirmedDate) return
      const confirmedDate = new Date(quotation.confirmedDate!)
      if (!latestDate || latestDate.getTime() < confirmedDate.getTime()) {
        latestDate = confirmedDate
      }
    })
    return latestDate
  }

  get estimatedResponseDate(): Date | null {
    if (this.quotations.length === 0) {
      return null
    }
    let latestDate: Date | null = null
    this.quotations.forEach((quotation: Quotation) => {
      quotation.items.forEach((quotationItem: QuotationItem) => {
        const responseDate = quotationItem.estimatedCustomerResponseDate
          ? new Date(quotationItem.estimatedCustomerResponseDate)
          : null
        if (
          responseDate &&
          (!latestDate || latestDate.getTime() < responseDate.getTime())
        ) {
          latestDate = responseDate
        }
      })
    })
    return latestDate
  }

  get requiredMaxBusinessDays() {
    switch (this.requestResponseSource) {
      case QuotationRequestSourceType.AS_ONLY:
        return 3
      default:
        return 10
    }
  }

  get colorClass() {
    if (this.status === QuotationRequestDtoStatusEnum.Creating) {
      return 'in-progress'
    } else if (
      this.status === QuotationRequestDtoStatusEnum.Complete ||
      this.status === QuotationRequestDtoStatusEnum.Quoting
    ) {
      return 'sent'
    } else if (
      this.status === QuotationRequestDtoStatusEnum.Resolved &&
      this.archived
    ) {
      return 'inbox-archived'
    } else if (
      this.status === QuotationRequestDtoStatusEnum.Resolved &&
      !this.archived
    ) {
      return 'inbox'
    }
  }

  canOpenQuotation(isInternal: boolean): boolean {
    if (isInternal) {
      return !!this.originalQuotation
    }
    return this.status === QuotationRequestDtoStatusEnum.Resolved
  }
}
