



















import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import QuotationMailboxItem from '~/models/QuotationMailboxItem'
import QuotationRequestMailboxItem from '~/models/QuotationRequestMailboxItem'
import MailboxItem from '~/components/mailbox/MailboxItem.vue'

@Component({
  components: {
    MailboxItem,
  },
})
export default class SearchTabContent extends Vue {
  @Prop({ required: true }) readonly items!:
    | QuotationMailboxItem[]
    | QuotationRequestMailboxItem[]

  @Prop({ required: true }) readonly isRequests!: Boolean

  @Emit('item-clicked')
  itemClicked() {}
}
