import { render, staticRenderFns } from "./SyncIndicator.vue?vue&type=template&id=3f1129e3&scoped=true&"
import script from "./SyncIndicator.vue?vue&type=script&lang=ts&"
export * from "./SyncIndicator.vue?vue&type=script&lang=ts&"
import style0 from "./SyncIndicator.vue?vue&type=style&index=0&id=3f1129e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f1129e3",
  null
  
)

/* custom blocks */
import block0 from "./SyncIndicator.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
