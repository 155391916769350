import VueI18n from 'vue-i18n'
export default class Response<T> {
  public success: boolean
  public validation: Array<Record<string, string[]>>
  public result: T
  public errorMessage: string
  public error: Error

  public constructor(validation: Array<string>)
  public constructor(value: T)
  public constructor(...args: any[]) {
    if (args[0] instanceof Error) {
      this.result = {} as T
      this.success = false
      this.errorMessage = args[0].message
      this.error = args[0]
      this.validation = []
      const arg = args[0] as any
      if (arg.response && arg.response.data && arg.response.data.message) {
        this.validation = arg.response.data.message as Array<
          Record<string, string[]>
        >
        this.errorMessage = ''
        this.error = {} as Error
      }
    } else {
      this.result = args[0] as T
      this.success = true
      this.validation = []
      this.errorMessage = ''
      this.error = {} as Error
    }
  }

  private translate(t: typeof VueI18n.prototype.t, value: string): string {
    try {
      return t(value).toString()
    } catch (e) {
      console.warn(`error translating key ${value}`, e)
      return value
    }
  }

  public validationKeys() {
    return this.validation.flatMap((validationItem) => {
      return Object.values(validationItem).flat()
    })
  }

  public validationMessages(t: typeof VueI18n.prototype.t) {
    return this.validation.reduce<Record<string, string>>((p, v) => {
      const keys = Object.keys(v)
      if (keys && keys.length > 0) {
        const prop = keys[0] as string
        if (p[prop]) {
          p[prop] += ' ' + v[prop]!.map((s) => this.translate(t, s)).join(' ')
        } else {
          p[prop] = v[prop]!.map((s) => this.translate(t, s)).join(' ')
        }
      }
      return p
    }, {})
  }
}
