import { render, staticRenderFns } from "./UserWidget.vue?vue&type=template&id=49e83a87&scoped=true&"
import script from "./UserWidget.vue?vue&type=script&lang=ts&"
export * from "./UserWidget.vue?vue&type=script&lang=ts&"
import style0 from "./UserWidget.vue?vue&type=style&index=0&id=49e83a87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e83a87",
  null
  
)

/* custom blocks */
import block0 from "./UserWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VContainer,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMenu})
