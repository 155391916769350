




























































import { Vue, Component, Ref } from 'nuxt-property-decorator'
import SearchTab from './SearchTab.vue'
import { authStore, mailboxStore } from '~/store'
import { AuthorizationType } from '~/store/AuthStore'
import QuotationMailboxItem from '~/models/QuotationMailboxItem'
import QuotationRequestMailboxItem from '~/models/QuotationRequestMailboxItem'
import MailboxItemCommon from '~/models/MailboxItemCommon'

interface TabInfo {
  title: string
  isRequests: boolean
  underlineColor: string
  data: Array<QuotationMailboxItem> | Array<QuotationRequestMailboxItem>
}

interface SearchResult {
  items: MailboxItemCommon[]
  totalCount: number
}

@Component({ components: { SearchTab } })
export default class SearchWidget extends Vue {
  searchTerm: string = ''

  get showSearch() {
    return mailboxStore.isLoaded
  }

  get tabs(): TabInfo[] {
    if (authStore.authorizationType === AuthorizationType.INTERNAL) {
      return this.internalTabInfos
    } else {
      return this.customerTabInfos
    }
  }

  get internalTabInfos(): TabInfo[] {
    return [
      {
        title: this.$t('quotation').toString(),
        isRequests: true,
        underlineColor: 'bc-primary-1',
        data: mailboxStore.internalInboxTray,
      },
      {
        title: this.$t('in-progress').toString(),
        isRequests: false,
        underlineColor: 'bc-primary-2',
        data: mailboxStore.internalInProgressTray,
      },
      {
        title: this.$t('waiting-quotation').toString(),
        isRequests: false,
        underlineColor: 'bc-primary-3',
        data: mailboxStore.internalWaitingTray,
      },
      {
        title: this.$t('sent-quotation').toString(),
        isRequests: false,
        underlineColor: 'bc-primary-5',
        data: mailboxStore.internalSentTray,
      },
    ]
  }

  get customerTabInfos(): TabInfo[] {
    return [
      {
        title: this.$t('quotation').toString(),
        isRequests: false,
        underlineColor: 'bc-primary-1',
        data: mailboxStore.customerInboxTray,
      },
      {
        title: this.$t('in-progress').toString(),
        isRequests: true,
        underlineColor: 'bc-primary-2',
        data: mailboxStore.customerInProgressTray,
      },
      {
        title: this.$t('sent-request').toString(),
        isRequests: true,
        underlineColor: 'bc-primary-5',
        data: mailboxStore.customerSentTray,
      },
    ]
  }

  // so that we can tell vue this is always defined
  tabData(tabIndex: number) {
    return this.filteredData[tabIndex]!
  }

  // not defined in the definition itself because it uses properties from the definition
  get filteredData(): SearchResult[] {
    const searchTerm = (this.searchTerm || '').trim().toLowerCase()
    const results = []
    for (const tab of this.tabs) {
      results.push(this.filterMailBoxItems(tab.data, searchTerm))
    }
    return results
  }

  @Ref('searchRef') autocomplete!: any

  private filterMailBoxItems(
    mailboxItems: MailboxItemCommon[],
    term: string
  ): SearchResult {
    let results: MailboxItemCommon[]
    if (term === '') {
      results = []
    } else {
      // as all quotations currently loaded into store, lets search here for now
      results = mailboxItems.filter(
        (mailboxItem) =>
          mailboxItem.referenceId?.toLowerCase().includes(term) ||
          mailboxItem.externalId?.toLowerCase().includes(term)
      )
    }
    // emulate paged search limited by 20
    const items = results.slice(0, 20)
    return {
      items,
      totalCount: results.length,
    }
  }

  itemClicked() {
    this.autocomplete.isMenuActive = false
    this.autocomplete.blur()
    this.clear()
  }

  clear() {
    this.searchTerm = ''
  }
}
