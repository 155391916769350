import { Module, Mutation, Action } from 'vuex-module-decorators'

import { AxiosResponse } from 'axios'
import BaseRemoteStore from './BaseRemoteStore'
import User from '~/models/User'
import normalizedUpdator from '~/utils/normalizedUpdator'
import remote from '~/remote'
import Response from '~/remote/response'
import { CreateUserDto, UpdateUserDto } from '~/remote/api-spec'
import { PartialRemoteModel } from '~/utils/store'

@Module({
  name: 'UserStore',
  namespaced: true,
  stateFactory: true,
})
export default class UserStore extends BaseRemoteStore {
  readonly usersById: { [key: string]: User } = {}
  ownUserId: string | null = null

  get ownUser() {
    return this.ownUserId ? this.usersById[this.ownUserId] : undefined
  }

  get getUserById() {
    return BaseRemoteStore.getById(this.usersById)
  }

  get allUsers() {
    return BaseRemoteStore.listModels(this.usersById)
  }

  get getTeamUsersById() {
    return (teamId: string) => {
      return BaseRemoteStore.listModels(this.usersById).filter(
        (u) => u.teamId === teamId
      )
    }
  }

  @Mutation
  _setUser({ model, isLocal }: { model: User; isLocal?: boolean }) {
    return BaseRemoteStore.setById(this.usersById, model, isLocal ?? true)
  }

  @Mutation
  _setUserPartial(userPartial: PartialRemoteModel<User>) {
    BaseRemoteStore.setPartialById(this.usersById, userPartial)
  }

  @Mutation
  _setOwnUserId(userId: string) {
    this.ownUserId = userId
  }

  @Mutation
  _deleteById(userId: string) {
    BaseRemoteStore.deleteById(this.usersById, userId)
  }

  @Mutation
  clearOwnUser() {
    this.ownUserId = null
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.usersById)
    this.ownUserId = null
  }

  @Action
  async createUser(user: CreateUserDto) {
    try {
      const newUser = await remote.api.usersControllerCreate(user)
      const userId = normalizedUpdator.normalizeAndUpdate(
        newUser.data,
        User.schema
      )
      const resultUser: User = BaseRemoteStore.getById(this.usersById)(userId)!
      return new Response(resultUser)
    } catch (e: any) {
      return new Response<User>(e)
    }
  }

  @Action
  async updateUserPartial(userUpdate: UpdateUserDto & { id: string }) {
    try {
      this._setUserPartial(userUpdate as PartialRemoteModel<User>)
      await remote.api.usersControllerUpdate(userUpdate.id, userUpdate)
      return new Response(userUpdate)
    } catch (e: any) {
      return new Response<UpdateUserDto>(e)
    }
  }

  @Action
  async resetUserPassword(userId: string): Promise<string> {
    return await remote.api
      .usersControllerResetUserPassword(userId)
      .then((response: AxiosResponse<string>) => response.data)
  }

  @Action
  async loadOwnUser() {
    const ownUser = await remote.api.usersControllerFindMe()
    const userId = normalizedUpdator.normalizeAndUpdate(
      ownUser.data,
      User.schema
    )
    this._setOwnUserId(userId)
  }

  @Action
  async loadAllUsers() {
    const ownUser = await remote.api.usersControllerFindAll()
    normalizedUpdator.normalizeAndUpdate(ownUser.data, User.arraySchema)
  }

  @Action
  async loadAssignableUsers() {
    const ownUser = await remote.api.usersControllerFindAssignableUsers()
    normalizedUpdator.normalizeAndUpdate(ownUser.data, User.arraySchema)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(User.schema, User, this._setUser)
  }

  @Action
  async resendInvitation(userId: string) {
    await remote.api.usersControllerResendUserInvitation(userId)
  }

  @Action
  async cancelInvitation(userId: string) {
    await remote.api.usersControllerRemove(userId)
    this._deleteById(userId)
  }
}
