import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseRemoteStore from '~/store/BaseRemoteStore'
import normalizedUpdator from '~/utils/normalizedUpdator'
import remote from '~/remote'
import Notification from '~/models/Notification'
import { NotificationDtoTypeEnum } from '~/remote/api-spec'

@Module({
  name: 'NotificationsStore',
  namespaced: true,
  stateFactory: true,
})
export default class NotificationsStore extends BaseRemoteStore {
  readonly notificationsById: { [key: string]: Notification } = {}

  uncheckedCount: number = 0
  unreadTypes: NotificationDtoTypeEnum[] = []

  get getNotificationById() {
    return BaseRemoteStore.getById(this.notificationsById)
  }

  get allNotifications() {
    return BaseRemoteStore.listModels(this.notificationsById).sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
  }

  @Mutation
  _setNotification({
    model,
    isLocal,
  }: {
    model: Notification
    isLocal?: boolean
  }) {
    return BaseRemoteStore.setById(
      this.notificationsById,
      model,
      isLocal ?? true
    )
  }

  @Mutation
  _deleteById(notificationId: string) {
    BaseRemoteStore.deleteById(this.notificationsById, notificationId)
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDirtyByIds(Object.keys(this.notificationsById))
    BaseRemoteStore.clearDicts(this.notificationsById)
  }

  @Mutation
  _setUncheckedCount(count: number) {
    this.uncheckedCount = count
  }

  @Mutation
  _setUnreadTypes(types: NotificationDtoTypeEnum[]) {
    this.unreadTypes = types
  }

  @Mutation
  _setIsRead({ id, isRead }: { id: string; isRead: boolean }) {
    BaseRemoteStore.setPartialById(this.notificationsById, { id, isRead })
  }

  @Action
  async read(notification: Notification): Promise<void> {
    if (notification.isRead) return

    this._setIsRead({ id: notification.id, isRead: true })
    await remote.api.notificationsControllerRead(notification.id)
  }

  @Action
  async readByTypes(types: NotificationDtoTypeEnum[]): Promise<void> {
    this._setUnreadTypes(this.unreadTypes.filter((t) => !types.includes(t)))
    const ids = this.allNotifications.flatMap((n) => (n.isRead ? [] : n.id))
    if (ids.length === 0) return

    for (const id of ids) {
      this._setIsRead({ id, isRead: true })
    }
    await remote.api.notificationsControllerReadByTypes({ types })
  }

  @Action
  async unread(notification: Notification): Promise<void> {
    if (notification.isRead === false) return

    this._setIsRead({ id: notification.id, isRead: false })
    await remote.api.notificationsControllerUnread({ ids: [notification.id] })
  }

  @Action
  async delete(notification: Notification): Promise<void> {
    this._deleteById(notification.id)
    await remote.api.notificationsControllerDelete(notification.id)
  }

  @Action
  async loadNotifications({
    skip,
    types,
  }: {
    skip: number
    types?: NotificationDtoTypeEnum[]
  }): Promise<void> {
    const response = await remote.api.notificationsControllerListNotifications(
      skip,
      types
    )
    if (skip === 0) {
      this.clearData()
    }
    normalizedUpdator.normalizeAndUpdate(
      response.data.notifications,
      Notification.arraySchema
    )
    this._setUncheckedCount(response.data.uncheckedCount)
    this._setUnreadTypes(response.data.unreadTypes)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      Notification.schema,
      Notification,
      this._setNotification
    )
  }

  @Action
  async clearUncheckedCount() {
    if (this.uncheckedCount === 0) return

    await remote.api.notificationsControllerClearUncheckedCount()
    this._setUncheckedCount(0)
  }
}
