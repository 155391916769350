



































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Footer extends Vue {
  @Prop({ default: false }) fixed!: boolean

  resolveRoute(routeName: string) {
    const routeData = this.$router.resolve(routeName)
    return routeData.href
  }
}
