// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import MailboxItemCommon from './MailboxItemCommon'
import QuotationRequestMailboxItem from './QuotationRequestMailboxItem'
import {
  MailboxDto,
  MailboxItemDto,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'
import { authStore } from '~/store'

export type NormalizedQuotationMailboxItem = Omit<
  MailboxItemDto,
  'status' | 'id'
> & {
  status: WorkflowStepDtoTypeEnum
}

const quotationMailboxSchema = new schema.Entity<MailboxItemDto>(
  'mailboxQuotations'
)

export enum MailboxStatus {
  IN_PROGRESS = 'inProgress',
  READY_TO_SEND = 'readyToSend',
  SENT = 'sent',
}
export default class QuotationMailboxItem
  extends MailboxItemCommon
  implements NormalizedQuotationMailboxItem
{
  status!: WorkflowStepDtoTypeEnum

  get mailboxStatus() {
    switch (this.status) {
      case WorkflowStepDtoTypeEnum.Requested:
      case WorkflowStepDtoTypeEnum.InProgress:
      case WorkflowStepDtoTypeEnum.Assigned:
      case WorkflowStepDtoTypeEnum.Approved:
      case WorkflowStepDtoTypeEnum.Returned:
        return MailboxStatus.IN_PROGRESS
      case WorkflowStepDtoTypeEnum.Ready:
        return MailboxStatus.READY_TO_SEND
      case WorkflowStepDtoTypeEnum.Sent:
        return MailboxStatus.SENT
    }
  }

  static schema: schema.Entity = quotationMailboxSchema
  static arraySchema = new schema.Array(quotationMailboxSchema)

  get colorClass() {
    let result = ''
    if (authStore.getIsCustomer) {
      if (this.mailboxStatus !== MailboxStatus.SENT)
        console.error('No valid color for this quotation status')
      result = this.archived ? 'inbox-archived' : 'inbox'
    } else if (this.mailboxStatus === MailboxStatus.IN_PROGRESS) {
      result = 'in-progress'
    } else if (this.mailboxStatus === MailboxStatus.SENT) {
      result = 'sent'
    } else {
      result = 'waiting-send'
    }
    return result
  }
}

export const mailboxSchema = new schema.Entity<MailboxDto>('mailbox', {
  quotations: QuotationMailboxItem.arraySchema,
  requests: QuotationRequestMailboxItem.arraySchema,
})
