































































































































import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { authStore, notificationsStore, tabStore } from '~/store'
import Notification from '~/models/Notification'
import NotificationsListItem from '~/components/notifications/NotificationsListItem.vue'
import {
  NotificationDtoParentTypeEnum,
  NotificationDtoTypeEnum,
} from '~/remote/api-spec'
import SubscriptionMixin from '~/mixins/SubscriptionMixin'
import { ItemSubscription } from '~/store/SubscriptionStore'
import Quotation from '~/models/Quotation'
import QuotationRequest from '~/models/QuotationRequest'

const FilteringTypes = {
  Quotation: 'quotation',
  Check: 'check',
  CustomerMessage: 'customerMessage',
  InternalMessage: 'internalMessage',
  All: 'all',
} as const
type FilteringType = typeof FilteringTypes[keyof typeof FilteringTypes]

@Component({
  components: { NotificationsListItem },
})
export default class NotificationsWidget extends mixins(SubscriptionMixin) {
  isOpened: boolean = false
  selectedFilteringType: FilteringType = FilteringTypes.All
  itemLoaded: { string?: boolean } = {}
  _scrollTimerId: number | null = null

  get subscriptions(): Array<
    ItemSubscription<{ skip: number; types: NotificationDtoTypeEnum[] }>
  > {
    return [
      {
        getterFunction: notificationsStore.loadNotifications,
        params: {
          skip: 0,
          types: this.filteringTypeToNotificationTypes(
            this.selectedFilteringType
          ),
        },
        eagerRemoving: true,
      },
    ]
  }

  get isInternal() {
    return authStore.getIsInternal
  }

  get notifications(): Notification[] {
    return notificationsStore.allNotifications
  }

  get counter(): number {
    return Math.min(notificationsStore.uncheckedCount, 99)
  }

  @Watch('isOpened')
  async clearUncheckedCount(isOpened: boolean) {
    if (isOpened) {
      await notificationsStore.clearUncheckedCount()
    } else {
      this.selectedFilteringType = FilteringTypes.All
    }
  }

  @Watch('selectedFilteringType')
  filtering() {
    this.itemLoaded = {}
    notificationsStore.clearData()

    // updating subscription params
    this.removeSubscriptions()
    this.registerSubscriptions()
  }

  @Watch('notifications')
  onUpdateNotifications(notifications: Notification[]) {
    this.itemLoaded = notifications.reduce(
      (p, c) => Object.assign({ [c.id]: false }, p),
      this.itemLoaded
    )
  }

  async paging(skip: number) {
    await notificationsStore.loadNotifications({
      skip,
      types: this.filteringTypeToNotificationTypes(this.selectedFilteringType),
    })
  }

  async readAndOpen({
    notification,
    parent,
  }: {
    notification: Notification
    parent: Quotation | QuotationRequest
  }) {
    this.isOpened = false
    switch (notification.parentType) {
      case NotificationDtoParentTypeEnum.Request:
        tabStore.openQuotationOrRequestTab(parent.id)
        break
      case NotificationDtoParentTypeEnum.Quotation:
        tabStore.openQuotationTab(notification.parentId)
        break
      default:
    }
    await this.read(notification)
  }

  async read(notification: Notification) {
    await notificationsStore.read(notification)
  }

  async unread(notification: Notification) {
    await notificationsStore.unread(notification)
  }

  async readByTypes() {
    let types: NotificationDtoTypeEnum[]
    if (this.selectedFilteringType === FilteringTypes.All) {
      types = Object.values(NotificationDtoTypeEnum)
    } else {
      types = this.filteringTypeToNotificationTypes(this.selectedFilteringType)
    }
    await notificationsStore.readByTypes(types)
  }

  async deleteNotification(notification: Notification) {
    await notificationsStore.delete(notification)
  }

  infiniteScrolling(_: any, __: any, isIntersect: boolean) {
    if (
      isIntersect &&
      Object.values(this.itemLoaded).every((i) => i === true)
    ) {
      if (this._scrollTimerId) {
        window.clearTimeout(this._scrollTimerId)
      }
      // debounce
      this._scrollTimerId = window.setTimeout(async () => {
        await this.paging(this.notifications.length)
      }, 500)
    }
  }

  unreadExists(type: FilteringType): boolean {
    const checkingTypes = this.filteringTypeToNotificationTypes(type)
    return notificationsStore.unreadTypes.some((t) => checkingTypes.includes(t))
  }

  filteringTypeToNotificationTypes(
    type: FilteringType
  ): NotificationDtoTypeEnum[] {
    switch (type) {
      case FilteringTypes.Quotation:
        if (this.isInternal) {
          return [
            NotificationDtoTypeEnum.NewQuotationRequest,
            NotificationDtoTypeEnum.PriorityChange,
          ]
        }
        return [NotificationDtoTypeEnum.CompleteQuotation]
      case FilteringTypes.Check:
        return [
          NotificationDtoTypeEnum.UserAssignedNew,
          NotificationDtoTypeEnum.UserAssignedBack,
        ]
      case FilteringTypes.CustomerMessage:
        return [NotificationDtoTypeEnum.CustomerMessage]
      case FilteringTypes.InternalMessage:
        return [NotificationDtoTypeEnum.InternalMessage]
    }
    return []
  }
}
