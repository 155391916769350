








import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class SearchTabAlert extends Vue {}
