import RemoteModel from './RemoteModel'

export default class MailboxItemCommon extends RemoteModel {
  externalId!: string
  referenceId!: string
  unreadMessages!: number
  customerId!: string
  customerName!: string
  createdOn!: string
  sentDate!: string
  isPriority?: boolean
  archived?: boolean
  companyName!: string

  get sentTime() {
    return this.sentDate ? new Date(this.sentDate).getTime() : 0
  }

  get createdTime() {
    return this.createdOn ? new Date(this.createdOn).getTime() : 0
  }
}
