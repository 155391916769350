import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { Context } from '@nuxt/types'
import { Tracking } from '~/modules/tracking'

export default (context: Context) => {
  if (context.env.googleAnalyticsTrackingToken) {
    Vue.use(
      VueGtag,
      {
        config: { id: context.env.googleAnalyticsTrackingToken },
        appName: 'frontend',
      },
      context.app.router
    )
  }

  Vue.use(Tracking)
}
