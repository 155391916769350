// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import globalAxios, { AxiosRequestConfig } from 'axios'
import RemoteModel from './RemoteModel'
import {
  AttachmentDto,
  AttachmentDtoParentTypeEnum,
  AttachmentDtoStatusEnum,
  AttachmentDtoVisibilityEnum,
} from '~/remote/api-spec'
import { attachmentStore } from '~/store'

export type NormalizedAttachment = AttachmentDto

const attachmentSchema = new schema.Entity<AttachmentDto>('attachments')

export default class Attachment
  extends RemoteModel
  implements NormalizedAttachment
{
  parentId!: string
  parentType!: AttachmentDtoParentTypeEnum
  name!: string
  key!: string
  status!: AttachmentDtoStatusEnum
  visibility?: AttachmentDtoVisibilityEnum

  static schema: schema.Entity = attachmentSchema
  static arraySchema: schema.Array = new schema.Array<AttachmentDto>(
    attachmentSchema
  )

  get isClientVisible() {
    return this.visibility !== AttachmentDtoVisibilityEnum.Internal
  }

  get canDelete() {
    return (
      this.parentType !== AttachmentDtoParentTypeEnum.RequestItem &&
      this.parentType !== AttachmentDtoParentTypeEnum.Message
    )
  }

  async fetchAsBlob() {
    const config: AxiosRequestConfig = { responseType: 'blob' }
    const response = await globalAxios.get(await this.getUri(), config)
    return response.data as Blob
  }

  async fetchPReviewAsBlob() {
    const config: AxiosRequestConfig = { responseType: 'blob' }
    const response = await globalAxios.get(await this.getPreviewUri(), config)
    return response.data as Blob
  }

  async fileExists(): Promise<boolean> {
    return (await this.getPreviewUri()) !== null
  }

  async previewFileExists(): Promise<boolean> {
    return (await this.getPreviewUri()) !== null
  }

  async getUri() {
    return (await attachmentStore.getDownloadLink(this.id)).downloadLink
  }

  async getPreviewUri() {
    return (await attachmentStore.getDownloadLink(this.id)).previewDownloadLink
  }

  async getDownloadLinks() {
    return await attachmentStore.getDownloadLink(this.id)
  }
}
