import { OtherPriceDto } from '~/remote/api-spec'

export const DB_INTEGER_UPPER_LIMIT: number = 2147483647
export const DB_INTEGER_LOWER_LIMIT: number = -2147483648

export function isRequired<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null
}

export function isStringNotEmpty(
  value: string | null | undefined
): value is Exclude<string, ''> {
  if (!isRequired(value)) {
    return false
  }
  return String(value).trim() !== ''
}

export function isPositive(value: number | null | undefined): value is number {
  if (!isRequired(value)) {
    return false
  }
  return Number(value) >= 0
}

export function isLessOrEqualThan(
  value: string | number | null | undefined,
  limit: number
): value is number {
  if (!isRequired(value)) {
    return false
  }
  return Number(value) <= limit
}

export function isGreaterOrEqualThan(
  value: string | number | null | undefined,
  limit: number
): value is number {
  if (!isRequired(value)) {
    return false
  }
  return Number(value) >= limit
}

export function isStringEmpty(value: any): value is string {
  if (!isRequired(value)) {
    return false
  }
  return String(value).trim() === ''
}

export function isPercent(
  value: number | null | undefined,
  isFraction: boolean = false
): value is number {
  if (!isRequired(value)) {
    return false
  }

  const minBoundary = 0
  const maxBoundary = isFraction ? 1 : 100

  if (!isFraction && !Number.isInteger(value)) {
    return false
  }

  return (
    value !== undefined &&
    value !== null &&
    Number(value) >= minBoundary &&
    Number(value) <= maxBoundary
  )
}

/**
 * To get proper messages on screen, use instead together:
 * isLessOrEqualThan(DB_INTEGER_UPPER_LIMIT) and isGreaterOrEqualThan(DB_INTEGER_LOWER_LIMIT)
 */
export function isValidDbInteger(
  value: string | number | null | undefined
): boolean {
  if (!isRequired(value)) {
    return false
  }
  return (
    isGreaterOrEqualThan(value, DB_INTEGER_LOWER_LIMIT) &&
    isLessOrEqualThan(value, DB_INTEGER_UPPER_LIMIT)
  )
}

export function isValidAdditionalCosts(
  value: OtherPriceDto[] | null | undefined
): value is OtherPriceDto[] {
  if (!isRequired(value)) {
    return false
  }

  for (const cost of value) {
    if (
      !isPositive(cost.amountPurchase) ||
      !isValidDbInteger(cost.amountPurchase)
    ) {
      return false
    }
    if (
      !isPositive(cost.amountSelling) ||
      !isValidDbInteger(cost.amountSelling)
    ) {
      return false
    }
  }
  return true
}

export default {
  isStringNotEmpty,
  isPositive,
  isPercent,
  isLessOrEqualThan,
  isGreaterOrEqualThan,
  isValidDbInteger,
}
