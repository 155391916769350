// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import MailboxItemCommon from './MailboxItemCommon'
import {
  MailboxItemDto,
  QuotationRequestDtoStatusEnum,
} from '~/remote/api-spec'
import { authStore } from '~/store'

export type NormalizedQuotationRequestMailboxItem = Omit<
  MailboxItemDto,
  'status' | 'id'
> & {
  status: QuotationRequestDtoStatusEnum
}

const quotationRequestMailboxSchema = new schema.Entity<MailboxItemDto>(
  'mailboxQuotationRequests'
)

export default class QuotationRequestMailboxItem
  extends MailboxItemCommon
  implements NormalizedQuotationRequestMailboxItem
{
  status!: QuotationRequestDtoStatusEnum

  static schema: schema.Entity = quotationRequestMailboxSchema
  static arraySchema = new schema.Array(quotationRequestMailboxSchema)

  get colorClass() {
    let result = ''
    if (this.status === QuotationRequestDtoStatusEnum.Creating) {
      result = 'in-progress'
    } else if (this.status === QuotationRequestDtoStatusEnum.Complete) {
      result = authStore.getIsCustomer ? 'sent' : 'inbox'
    } else if (this.status === QuotationRequestDtoStatusEnum.Quoting) {
      return 'sent'
    } else if (
      this.status === QuotationRequestDtoStatusEnum.Resolved &&
      this.archived
    ) {
      result = 'inbox-archived'
    } else if (
      this.status === QuotationRequestDtoStatusEnum.Resolved &&
      !this.archived
    ) {
      result = 'inbox'
    }
    return result
  }
}
