// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import {
  ItemDetails,
  QuotationRequestItemDto,
  QuotationItemTypeEnum,
} from '~/remote/api-spec'
import { quotationRequestStore } from '~/store'

export type NormalizedQuotationRequestItem = QuotationRequestItemDto

type QuotationRequestItemType = QuotationItemTypeEnum

export default class QuotationRequestItem
  extends RemoteModel
  implements NormalizedQuotationRequestItem
{
  requestId!: string
  details!: ItemDetails
  itemType!: QuotationRequestItemType
  requestBody?: string

  static schema: schema.Entity = new schema.Entity<QuotationRequestItemDto>(
    'quotationRequestItems'
  )

  get request() {
    return quotationRequestStore.getQuotationRequestById(this.requestId)
  }
}
