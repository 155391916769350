import { Module, VuexModule, Action } from 'vuex-module-decorators'
import globalAxios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  Method,
} from 'axios'
import { Mutation } from 'nuxt-property-decorator/node_modules/vuex-module-decorators'
import { syncStatusStore } from '.'

const readMethods: Method[] = ['get', 'GET']
const writeMethods: Method[] = [
  'put',
  'PUT',
  'patch',
  'PATCH',
  'post',
  'POST',
  'delete',
  'DELETE',
]

const apiCompatibilityVersionHeader = 'api_compatibility_version'

export enum SyncStatus {
  NONE,
  WRITING,
  OFFLINE,
  ERROR,
}

const handleRequestStarted = (requestConfig: AxiosRequestConfig) => {
  syncStatusStore.trackInProgressCallIncrement(requestConfig.method)
  return requestConfig
}

const handleRequestSuccessful = (axiosResponse: AxiosResponse) => {
  syncStatusStore.trackInProgressCallDecrement(axiosResponse.config.method)
  if (axiosResponse.headers[apiCompatibilityVersionHeader]) {
    const apiCompatibilityNumber = Number(
      axiosResponse.headers[apiCompatibilityVersionHeader]
    )
    if (!isNaN(apiCompatibilityNumber)) {
      syncStatusStore.updateApiCompatibilityNumber(apiCompatibilityNumber)
    }
  }
  return axiosResponse
}

const handleRequestFailure = (error: AxiosError) => {
  if (
    error.isAxiosError &&
    (!error.response || error.response?.status !== 400)
  ) {
    syncStatusStore.trackError(error.config.method)
  } else {
    syncStatusStore.trackInProgressCallDecrement(error.config.method)
  }
  return Promise.reject(error)
}

// TODO this store is a WIP interface
@Module({
  name: 'SyncStatusStore',
  namespaced: true,
  stateFactory: true,
})
export default class SyncStatusStore extends VuexModule {
  readCallsInProgess: number = 0
  writeCallsInProgess: number = 0
  readCallErrors: number = 0
  writeCallErrors: number = 0
  apiCompatibilityNumber: number | null = null
  isOffline: boolean = false

  get syncStatus() {
    if (this.writeCallErrors > 0) {
      return SyncStatus.ERROR
    } else if (this.isOffline) {
      return SyncStatus.OFFLINE
    } else if (this.writeCallsInProgess > 0) {
      return SyncStatus.WRITING
    } else {
      return SyncStatus.NONE
    }
  }

  @Action
  registerListeners() {
    globalAxios.interceptors.request.use(
      handleRequestStarted,
      handleRequestFailure
    )
    globalAxios.interceptors.response.use(
      handleRequestSuccessful,
      handleRequestFailure
    )

    // add "is-offline" class to the html element if user is offline and remove when online again
    window.addEventListener('load', () => {
      window.addEventListener('online', this._updateOnlineStatus)
      window.addEventListener('offline', this._updateOnlineStatus)
    })
  }

  @Mutation
  _setApiCompatibilityNumber(newNumber: number) {
    this.apiCompatibilityNumber = newNumber
  }

  @Mutation
  trackInProgressCallIncrement(method: Method | undefined) {
    if (method && readMethods.includes(method)) {
      this.readCallsInProgess = this.readCallsInProgess + 1
    } else if (method && writeMethods.includes(method)) {
      this.writeCallsInProgess = this.writeCallsInProgess + 1
    }
  }

  @Mutation
  trackInProgressCallDecrement(method: Method | undefined) {
    if (method && readMethods.includes(method)) {
      this.readCallsInProgess = this.readCallsInProgess - 1
    } else if (method && writeMethods.includes(method)) {
      this.writeCallsInProgess = this.writeCallsInProgess - 1
    }
  }

  @Mutation
  _setOnlineStatus(isOnline: boolean) {
    this.isOffline = !isOnline
  }

  @Mutation
  trackError(method: Method | undefined) {
    if (method && readMethods.includes(method)) {
      this.readCallErrors = this.readCallErrors + 1
    } else if (method && writeMethods.includes(method)) {
      this.writeCallErrors = this.writeCallErrors + 1
    }
  }

  @Mutation
  clearData(): void {
    this.readCallsInProgess = 0
    this.writeCallsInProgess = 0
    this.readCallErrors = 0
    this.writeCallErrors = 0
    this.isOffline = false
  }

  @Action
  _updateOnlineStatus() {
    this._setOnlineStatus(navigator.onLine)
  }

  @Action
  updateApiCompatibilityNumber(newCompatibilityNumber: number) {
    if (
      this.apiCompatibilityNumber &&
      newCompatibilityNumber !== this.apiCompatibilityNumber
    ) {
      // refresh the page
      window.location.reload()
    } else if (!this.apiCompatibilityNumber) {
      this._setApiCompatibilityNumber(newCompatibilityNumber)
    }
  }
}
