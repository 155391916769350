/* eslint-disable import/no-duplicates */
import { Context } from '@nuxt/types'
import { format, formatRelative, parseISO } from 'date-fns'
import { enUS, ja } from 'date-fns/locale'
import Vue from 'vue'

export default (context: Context) => {
  function getLocale(): Locale {
    switch (context.app.i18n.locale) {
      case 'en':
        return enUS
      case 'ja':
      default:
        return ja
    }
  }

  Vue.filter('formatShortDate', (date: string | Date) => {
    if (!date) return ''
    const dateObj = typeof date === 'string' ? parseISO(date) : date
    return format(dateObj, 'PP', {
      locale: getLocale(),
    })
  })

  Vue.filter('formatShortTime', (date: string | Date) => {
    const dateObj = typeof date === 'string' ? parseISO(date) : date
    return format(dateObj, 'p', {
      locale: getLocale(),
    })
  })

  Vue.filter('relativeDateFromNow', (date: string | Date) => {
    try {
      const dateObj = typeof date === 'string' ? parseISO(date) : date
      return formatRelative(dateObj, new Date(), {
        locale: getLocale(),
      })
    } catch (e) {
      console.warn('Caught error formatting relative date', e)
      return context.app.i18n.t('unknown')
    }
  })

  Vue.filter('formatDate', (date: string | Date) => {
    try {
      const dateObj = typeof date === 'string' ? parseISO(date) : date
      return format(dateObj, 'yyyy/MM/dd HH:mm')
    } catch (e) {
      console.warn('Caught error formatting date', e)
      return context.app.i18n.t('unknown')
    }
  })

  Vue.filter('formatNumber', (number: number): string => {
    return (number && number.toLocaleString(context.app.i18n.locale)) || '0'
  })

  Vue.filter('formatPrice', (price: string) => {
    return `${price}円`
  })

  Vue.filter('formatPercent', (percent: number) => {
    return `${(percent && percent.toFixed(0)) || 0}%`
  })

  Vue.filter('formatCount', (count: string) => {
    return `${count}台`
  })

  Vue.filter('formatCapacity', (capacity: string) => {
    return capacity + 'Kw'
  })
}
