// eslint-disable-next-line import/named
import { normalize, Schema, schema } from 'normalizr'
import 'reflect-metadata'
import { plainToInstance, ClassConstructor } from 'class-transformer'
import RemoteModel from '~/models/RemoteModel'

type entityCallback<T> = (update: { model: T; isLocal?: boolean }) => void

type UpdatorCallback<E, T extends RemoteModel> = {
  schema: schema.Entity<E>
  classConstructor: ClassConstructor<T>
  callback: entityCallback<T>
}

class NormalizedUpdator {
  schemaMap: { [key: string]: UpdatorCallback<any, any> } = {}

  // TODO: figure out how to strongly type the conversion (the schema and callback types don't match)
  registerSchemaListener<E, T>(
    schema: schema.Entity<E>,
    classConstructor: ClassConstructor<T>,
    callback: entityCallback<T>
  ) {
    this.schemaMap[schema.key] = {
      schema,
      classConstructor,
      callback,
    }
  }

  _internalNormalizeAndUpdate<T>(model: T, modelSchema: Schema<T>) {
    const results = normalize(model, modelSchema)

    for (const registeredKey in this.schemaMap) {
      const updatorCallback = this.schemaMap[registeredKey]!
      const entities = results.entities[registeredKey]
      if (entities) {
        Object.values(entities).forEach((object) =>
          updatorCallback.callback({
            model: plainToInstance(updatorCallback.classConstructor, object),
            isLocal: false,
          })
        )
      }
    }

    return results.result
  }

  // returns array of normalized object ids
  normalizeAndUpdateArray<T>(models: Array<T>, modelSchema: schema.Array<T>) {
    if (models === []) return []
    return this._internalNormalizeAndUpdate(
      models,
      modelSchema
    ) as Array<string>
  }

  // returns normalized object id
  normalizeAndUpdate<T>(model: T, modelSchema: Schema<T>) {
    return this._internalNormalizeAndUpdate(model, modelSchema) as string
  }
}

const normalizedUpdator = new NormalizedUpdator()
export default normalizedUpdator
