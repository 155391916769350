


















































import { Vue, Component } from 'nuxt-property-decorator'
import { syncStatusStore } from '~/store'
import { SyncStatus } from '~/store/SyncStatusStore'

@Component
export default class SyncIndicator extends Vue {
  get isWriting() {
    return syncStatusStore.syncStatus === SyncStatus.WRITING
  }

  get isError() {
    return syncStatusStore.syncStatus === SyncStatus.ERROR
  }

  get isOffline() {
    return syncStatusStore.syncStatus === SyncStatus.OFFLINE
  }
}
