import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseRemoteStore, {
  RemoteFieldQuery,
  RemoteFieldQueryDict,
  RemoteIdDict,
} from '~/store/BaseRemoteStore'
import normalizedUpdator from '~/utils/normalizedUpdator'
import TeamPartsPrice from '~/models/TeamPartsPrice'
import remote from '~/remote'

@Module({
  name: 'PartPriceStore',
  namespaced: true,
  stateFactory: true,
})
export default class PartPriceStore extends BaseRemoteStore {
  readonly partPricesById: RemoteIdDict<TeamPartsPrice> = {}
  readonly partPricesByTeamId: RemoteFieldQueryDict<TeamPartsPrice> = {}

  _teamReferenceField: RemoteFieldQuery<TeamPartsPrice> = {
    field: 'teamId',
    fieldDict: this.partPricesByTeamId,
  }

  get getPartPricesByTeamId() {
    return BaseRemoteStore.getListByField(this.partPricesByTeamId)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      TeamPartsPrice.schema,
      TeamPartsPrice,
      this._setPartPrice
    )
  }

  @Mutation
  _setPartPrice({
    model,
    isLocal,
  }: {
    model: TeamPartsPrice
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(
      this.partPricesById,
      model,
      isLocal ?? true,
      this._teamReferenceField
    )
  }

  @Action
  async loadPartPricesForParentTeam(teamId: string): Promise<string> {
    const prices = await remote.api.priceCalculatorControllerFindPartsPrice(
      teamId
    )
    const pricesId = normalizedUpdator.normalizeAndUpdate(
      prices.data,
      TeamPartsPrice.schema
    )

    BaseRemoteStore.purgeMissingByField(
      [pricesId],
      teamId,
      this._teamReferenceField,
      this._setPartPriceDeleted
    )
    return pricesId
  }

  @Action
  async updatePartsPriceForTeam({
    partPrices,
  }: {
    partPrices: TeamPartsPrice
  }) {
    this._setPartPrice({ model: partPrices })
    const priceResults =
      await remote.api.priceCalculatorControllerUpdatePartsPrice(partPrices, {})
    return normalizedUpdator.normalizeAndUpdate(
      priceResults.data,
      TeamPartsPrice.schema
    )
  }

  @Mutation
  _setPartPriceDeleted(partId: string): void {
    BaseRemoteStore.deleteById(
      this.partPricesById,
      partId,
      this._teamReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.partPricesById, this.partPricesByTeamId)
  }
}
