import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseRemoteStore, { RemoteIdDict } from '~/store/BaseRemoteStore'
import normalizedUpdator from '~/utils/normalizedUpdator'
import remote from '~/remote'
import { CommonTemplate } from '~/components/templates/Template'
import {
  stripId,
  TemplateTemporaryIdPrefix,
} from '~/components/templates/template-utils'

@Module({
  name: 'CommonTemplateStore',
  namespaced: true,
  stateFactory: true,
})
export default class CommonTemplateStore extends BaseRemoteStore {
  readonly templatesById: RemoteIdDict<CommonTemplate> = {}

  get getCommonTemplates() {
    return BaseRemoteStore.listModels(this.templatesById)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      CommonTemplate.schema,
      CommonTemplate,
      this._setTemplate
    )
  }

  @Mutation
  _setTemplate({
    model,
    isLocal,
  }: {
    model: CommonTemplate
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(this.templatesById, model, isLocal ?? true)
  }

  @Action
  async loadCommonTemplates(): Promise<string[]> {
    const templates = await remote.api.templatesControllerGetCommon()
    const templateIds = normalizedUpdator.normalizeAndUpdateArray(
      templates.data,
      CommonTemplate.arraySchema
    )

    BaseRemoteStore.purgeMissingById(
      templateIds,
      this.templatesById,
      this._setTemplateDeleted
    )
    return templateIds
  }

  @Action
  async saveCommonTemplates(templates: CommonTemplate[]): Promise<string[]> {
    const updatedTemplates = await remote.api.templatesControllerUpdateCommon(
      stripId(templates, TemplateTemporaryIdPrefix)
    )
    const templateIds = normalizedUpdator.normalizeAndUpdateArray(
      updatedTemplates.data,
      CommonTemplate.arraySchema
    )

    BaseRemoteStore.purgeMissingById(
      templateIds,
      this.templatesById,
      this._setTemplateDeleted
    )
    return templateIds
  }

  @Mutation
  _setTemplateDeleted(partId: string): void {
    BaseRemoteStore.deleteById(this.templatesById, partId)
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.templatesById)
  }
}
