


















































import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'

import QuotationMailboxItem from '~/models/QuotationMailboxItem'
import QuotationRequestMailboxItem from '~/models/QuotationRequestMailboxItem'
import { WorkflowStepDtoTypeEnum } from '~/remote/api-spec'
import { authStore, tabStore } from '~/store'
import { AuthorizationType } from '~/store/AuthStore'

export enum MailboxItemStyle {
  INTERNAL,
  CUSTOMER,
}

@Component
export default class Tray extends Vue {
  @Prop({ required: true }) item!:
    | QuotationMailboxItem
    | QuotationRequestMailboxItem

  @Prop({ required: true }) isRequest!: boolean
  @Prop({ required: true }) useSentDate!: boolean

  @Emit('click')
  itemClicked() {}

  get itemStyle() {
    return authStore.authorizationType === AuthorizationType.INTERNAL
      ? MailboxItemStyle.INTERNAL
      : MailboxItemStyle.CUSTOMER
  }

  get toplineContent() {
    if (this.itemStyle === MailboxItemStyle.INTERNAL) {
      return this.item.companyName || ''
    } else {
      return this.item.externalId
    }
  }

  get midlineContent() {
    if (this.itemStyle === MailboxItemStyle.INTERNAL) {
      return this.$t('quotation.request-number', {
        requestNumber: this.item.externalId,
      })
    } else if (this.item.referenceId) {
      return this.referenceId
    } else {
      return this.$t('addRefIdText')
    }
  }

  get isPriority() {
    return (
      this.itemStyle === MailboxItemStyle.INTERNAL &&
      !this.isRequest &&
      this.item.isPriority
    )
  }

  get isAwaitingApproval() {
    return (
      this.itemStyle === MailboxItemStyle.INTERNAL &&
      !this.isRequest &&
      (this.item as QuotationMailboxItem).status ===
        WorkflowStepDtoTypeEnum.Ready
    )
  }

  get referenceId() {
    return this.item.referenceId
  }

  get customerName() {
    return this.item.customerName
  }

  get hasNewContent(): boolean {
    return this.hasNewMessage || this.hasNewComment
  }

  // TODO(FIXME): Add logic to detect new comments in item
  get hasNewComment(): boolean {
    return false
  }

  get hasNewMessage() {
    return !!this.item.unreadMessages
  }

  openTab() {
    if (this.isRequest) {
      tabStore.openQuotationRequestTab(this.item.id)
    } else {
      tabStore.openQuotationTab(this.item.id)
    }
    this.itemClicked()
  }
}
