






































import { Vue, Component } from 'nuxt-property-decorator'
import { authStore, userStore } from '~/store'

@Component
export default class UserWidget extends Vue {
  session: any = undefined
  alias: string | undefined = undefined

  get isInternal() {
    return authStore.getIsInternal
  }

  get hasAlias() {
    return !!this.alias
  }

  get groups() {
    return this.session?.idToken.payload['cognito:groups']
  }

  fetch() {
    try {
      const session = (this.$auth.strategy as any).token
      this.alias = userStore.ownUser?.email
      this.session = session
      if (this.$nuxt.context.isDev) console.info('Got session info', session)
    } catch (e) {
      if (this.$nuxt.context.isDev) console.warn('Error fetching user info', e)
    }
  }

  async logout() {
    authStore.clearAuthState()
    await this.$auth.logout()
    this.$auth.redirect('login')
  }
}
