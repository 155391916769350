import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import _ from 'lodash'

const POLLING_INTERNAL = 30 * 1000

export type ItemSubscription<T> = {
  getterFunction: (params: T) => Promise<any>
  params: T
  firstLoadCallback?: (results: any) => any
  eagerRemoving?: boolean
}

type SubscriptionTracker = {
  referenceCount: number
  lastLoadTime: Date | undefined
  lastLoadResults: any
  subscription: ItemSubscription<any>
}

// TODO this store is a WIP interface
@Module({
  name: 'SubscriptionStore',
  namespaced: true,
  stateFactory: true,
})
export default class SubscriptionStore extends VuexModule {
  trackers: Array<SubscriptionTracker> = []

  get getTrackerForSubscription() {
    return (subscription: ItemSubscription<any>) => {
      const results = this.trackers.filter(
        (tracker) =>
          tracker.subscription.getterFunction === subscription.getterFunction &&
          _.isEqual(tracker.subscription.params, subscription.params)
      )
      return results.length > 0 ? results[0] : undefined
    }
  }

  get getIsSubscribed() {
    return (subscription: ItemSubscription<any>) => {
      return !!this.getTrackerForSubscription(subscription)
    }
  }

  get getIsDataReady() {
    return (subscription: ItemSubscription<any>) => {
      const tracker = this.getTrackerForSubscription(subscription)
      return tracker ? !!tracker.lastLoadTime : false
    }
  }

  @Mutation
  decrementTracker(tracker: SubscriptionTracker) {
    tracker.referenceCount--
    if (tracker.referenceCount === 0) {
      this.trackers = this.trackers.filter((item) => item !== tracker)
    }
  }

  @Mutation
  incrementTracker(tracker: SubscriptionTracker) {
    tracker.referenceCount++
  }

  @Mutation
  addTracker(tracker: SubscriptionTracker) {
    this.trackers.push(tracker)
  }

  @Mutation
  addResults({
    tracker,
    results,
  }: {
    tracker: SubscriptionTracker
    results: any
  }) {
    tracker.lastLoadResults = results
  }

  @Mutation
  trackDataLoad(tracker: SubscriptionTracker) {
    tracker.lastLoadTime = new Date()
  }

  @Mutation
  clearData() {
    this.trackers = []
  }

  @Action
  async doDataFetch(tracker: SubscriptionTracker) {
    if (!this.trackers.includes(tracker)) {
      // lazily end poll if tracker has been removed
      return
    }
    let pollIntervalMultiplier = 1
    try {
      const results = await tracker.subscription.getterFunction(
        tracker.subscription.params
      )
      this.addResults({ tracker, results })
      if (!tracker.lastLoadTime && tracker.subscription.firstLoadCallback) {
        tracker.subscription.firstLoadCallback(results)
      }
      this.trackDataLoad(tracker)
    } catch (err) {
      console.error('Error encountered by poll:')
      console.error(err)
      pollIntervalMultiplier = 0.2
    }
    setTimeout(
      () => this.doDataFetch(tracker),
      POLLING_INTERNAL * pollIntervalMultiplier
    )
  }

  @Action
  addSubscription(subscription: ItemSubscription<any>) {
    let tracker = this.getTrackerForSubscription(subscription)
    if (tracker) {
      if (tracker.lastLoadTime && subscription.firstLoadCallback) {
        subscription.firstLoadCallback(tracker.lastLoadResults)
      }
      this.incrementTracker(tracker)
    } else {
      tracker = {
        referenceCount: 1,
        lastLoadTime: undefined,
        subscription,
        lastLoadResults: undefined,
      }
      this.addTracker(tracker)
      this.doDataFetch(tracker)
    }
  }

  @Action
  removeSubscription(subscription: ItemSubscription<any>) {
    const tracker = this.getTrackerForSubscription(subscription)
    if (tracker) {
      if (subscription.eagerRemoving) {
        this.decrementTracker(tracker)
      } else {
        // Be lazy unsubscribing. Someone else might want to use this info and see it's fresh. Or the user might return to this view.
        setTimeout(() => this.decrementTracker(tracker), 10000)
      }
    }
  }
}
