import { Auth } from '@nuxtjs/auth-next'
import globalAxios from 'axios'
import remote from '~/remote'

const AXIOS_TIMEOUT = 0
const TIMEOUT_RETRIES = 0

export default ({ $auth }: { $auth: Auth }) => {
  globalAxios.defaults.timeout = AXIOS_TIMEOUT
  globalAxios.defaults.timeoutErrorMessage = `After ${TIMEOUT_RETRIES} retries with timeout ${AXIOS_TIMEOUT} the request could not be completed`

  globalAxios.interceptors.response.use(undefined, async (error) => {
    const timeoutRetries: number = error.config.timeoutRetries ?? 1
    const backoffPromise = new Promise(function (resolve) {
      // Backoff is retries number of seconds
      setTimeout(resolve, timeoutRetries * 1000)
    })

    if (
      (error.response && error.response.status === 408) ||
      error.code === 'ECONNABORTED' ||
      (error.response && error.response.status === 503) ||
      typeof error.response === 'undefined'
    ) {
      // Retry timeout calls up to TIMEOUT_RETRIES times
      if (timeoutRetries < TIMEOUT_RETRIES) {
        const config = error.config
        config.timeoutRetries = timeoutRetries + 1
        return backoffPromise.then(() => globalAxios.request(config))
      }
    } else if (
      error.config &&
      error.response &&
      error.response.status === 401
    ) {
      if ($auth.$state.loggedIn) {
        const { tokenExpired, refreshTokenExpired, isRefreshable } =
          $auth.check(true)

        if (refreshTokenExpired) {
          $auth.reset()
          return Promise.reject(new Error('Refresh token expired'))
        } else if (tokenExpired) {
          if (isRefreshable) {
            try {
              await $auth.refreshTokens()
            } catch (e) {
              $auth.reset()
              return Promise.reject(e)
            }
          } else {
            $auth.reset()
            return Promise.reject(new Error('Cannot refresh token'))
          }
        }

        const session = ($auth.strategy as any).token.session
        const jwtToken = session.accessToken.getJwtToken()
        remote.setAuthCredentials(jwtToken)

        const config = error.config
        config.headers.Authorization = 'Bearer ' + jwtToken
      }

      // Retry timeout calls up to TIMEOUT_RETRIES times
      if (timeoutRetries < TIMEOUT_RETRIES) {
        const config = error.config
        config.timeoutRetries = timeoutRetries + 1
        return backoffPromise.then(() => globalAxios.request(config))
      }
    }

    if (
      error.response &&
      error.response.status &&
      error.response.status - (error.response.status % 100) === 400
    ) {
      const callUri = error?.config?.url ?? 'unknown URI'
      console.warn(`Got ${error.response.status} error response on ${callUri}`)
      console.warn(error.response.data?.message)
    }
    return Promise.reject(error)
  })
}
