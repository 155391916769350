import { Module, Mutation, Action } from 'vuex-module-decorators'
import BaseRemoteStore, {
  RemoteFieldQuery,
  RemoteFieldQueryDict,
  RemoteIdDict,
} from './BaseRemoteStore'
import { mailboxStore, tabStore, userStore } from '.'
import Quotation from '~/models/Quotation'
import QuotationItem from '~/models/QuotationItem'
import WorkflowStep from '~/models/WorkflowStep'
import remote from '~/remote'
import normalizedUpdator from '~/utils/normalizedUpdator'
import { PartialRemoteModel } from '~/utils/store'
import {
  QuotationItemPriceAutoDto,
  QuotationItemPriceFixedDto,
  SendEmailDto,
  TimelineStepType,
  UpdateQuotationDto,
  UpdateQuotationItemDto,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'
import {
  isPercent,
  isPositive,
  isValidAdditionalCosts,
  isValidDbInteger,
} from '~/utils/validation-helper'

declare type QuotationItemPrice =
  | QuotationItemPriceFixedDto
  | QuotationItemPriceAutoDto

@Module({
  name: 'QuotationStore',
  namespaced: true,
  stateFactory: true,
})
export default class QuotationStore extends BaseRemoteStore {
  readonly quotationsById: RemoteIdDict<Quotation> = {}
  readonly quotationItemsById: RemoteIdDict<QuotationItem> = {}
  readonly quotationsItemsByQuotationId: RemoteFieldQueryDict<QuotationItem> =
    {}

  readonly workflowStepsById: RemoteIdDict<WorkflowStep> = {}
  readonly workflowStepsByQuotationId: RemoteFieldQueryDict<WorkflowStep> = {}

  _itemParentReferenceField: RemoteFieldQuery<QuotationItem> = {
    field: 'quotationId',
    fieldDict: this.quotationsItemsByQuotationId,
  }

  _workflowParentReferenceField: RemoteFieldQuery<WorkflowStep> = {
    field: 'quotationId',
    fieldDict: this.workflowStepsByQuotationId,
  }

  get quotations() {
    return BaseRemoteStore.listModels(this.quotationsById)
  }

  get getQuotationById() {
    return BaseRemoteStore.getById(this.quotationsById)
  }

  get getQuotationsByRequestId() {
    return (quotationRequestId: string) => {
      return this.quotations.filter(
        (quotation) => quotation.requestId === quotationRequestId
      )
    }
  }

  get quotationItems() {
    return BaseRemoteStore.listModels(this.quotationItemsById)
  }

  get getQuotationItemById() {
    return BaseRemoteStore.getById(this.quotationItemsById)
  }

  get getQuotationItemsByQuotationId() {
    return BaseRemoteStore.getListByField(this.quotationsItemsByQuotationId)
  }

  get workflowSteps() {
    return BaseRemoteStore.listModels(this.workflowStepsById)
  }

  get getWorkflowStepById() {
    return BaseRemoteStore.getById(this.workflowStepsById)
  }

  get getWorkflowStepsByQuotationId() {
    return BaseRemoteStore.getListByField(this.workflowStepsByQuotationId)
  }

  @Mutation
  _setQuotation({ model, isLocal }: { model: Quotation; isLocal?: boolean }) {
    BaseRemoteStore.setById(this.quotationsById, model, isLocal ?? true)
  }

  @Mutation
  _setQuotationPartial(quotationPartial: PartialRemoteModel<Quotation>) {
    BaseRemoteStore.setPartialById(this.quotationsById, quotationPartial)
  }

  @Mutation
  _setQuotationDeleted(quotationId: string) {
    BaseRemoteStore.deleteById(this.quotationsById, quotationId)
  }

  @Mutation
  _setQuotationItem({
    model,
    isLocal,
  }: {
    model: QuotationItem
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(
      this.quotationItemsById,
      model,
      isLocal ?? true,
      this._itemParentReferenceField
    )
  }

  @Mutation
  _setQuotationItemPartial(
    quotationItemPartial: PartialRemoteModel<QuotationItem>
  ) {
    BaseRemoteStore.setPartialById(
      this.quotationItemsById,
      quotationItemPartial,
      this._itemParentReferenceField
    )
  }

  @Mutation
  _setQuotationItemDeleted(quotationItemId: string) {
    BaseRemoteStore.deleteById(
      this.quotationItemsById,
      quotationItemId,
      this._itemParentReferenceField
    )
  }

  @Mutation
  _setWorkflowStep({
    model,
    isLocal,
  }: {
    model: WorkflowStep
    isLocal?: boolean
  }) {
    BaseRemoteStore.setById(
      this.workflowStepsById,
      model,
      isLocal ?? true,
      this._workflowParentReferenceField
    )
  }

  @Mutation
  _setWorkflowStepPartial(
    workflowStepPartial: PartialRemoteModel<WorkflowStep>
  ) {
    BaseRemoteStore.setPartialById(
      this.workflowStepsById,
      workflowStepPartial,
      this._workflowParentReferenceField
    )
  }

  @Mutation
  _setWorkflowStepDeleted(workflowStepId: string) {
    BaseRemoteStore.deleteById(
      this.workflowStepsById,
      workflowStepId,
      this._workflowParentReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(
      this.quotationsById,
      this.quotationItemsById,
      this.quotationsItemsByQuotationId,
      this.workflowStepsById,
      this.workflowStepsByQuotationId
    )
  }

  @Action
  async loadQuotation(params: { quotationId: string }) {
    const quotation = await remote.api.quotationsControllerFind(
      params.quotationId
    )
    normalizedUpdator.normalizeAndUpdate(quotation.data, Quotation.schema)

    const quotationData = this.getQuotationById(params.quotationId)!
    BaseRemoteStore.purgeMissingByField(
      quotationData.items.map((item) => item.id),
      params.quotationId,
      this._itemParentReferenceField,
      this._setQuotationItemDeleted
    )
    BaseRemoteStore.purgeMissingByField(
      quotationData.workflowSteps.map((item) => item.id),
      params.quotationId,
      this._workflowParentReferenceField,
      this._setWorkflowStepDeleted
    )
    return params.quotationId
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      Quotation.schema,
      Quotation,
      this._handleQuotationUpdate
    )
    normalizedUpdator.registerSchemaListener(
      QuotationItem.schema,
      QuotationItem,
      this._setQuotationItem
    )
    normalizedUpdator.registerSchemaListener(
      WorkflowStep.schema,
      WorkflowStep,
      this._handleWorkflowStepUpdate
    )
  }

  @Action
  async createQuotationFromQuotationRequest(quotationRequestId: string) {
    const quotationResults = await remote.api.quotationsControllerCreate({
      requestId: quotationRequestId,
    })
    const quotationId = normalizedUpdator.normalizeAndUpdate(
      quotationResults.data,
      Quotation.schema
    )
    this._updateMailboxesForQuotation({
      quotation: this.quotationsById[quotationId]!,
      isCreate: true,
    })
    return quotationId
  }

  @Action
  async createQuotationForTeam(teamId: string) {
    const quotationResults = await remote.api.quotationsControllerCreateForTeam(
      teamId
    )
    const quotationId = normalizedUpdator.normalizeAndUpdate(
      quotationResults.data,
      Quotation.schema
    )
    this._updateMailboxesForQuotation({
      quotation: this.quotationsById[quotationId]!,
      isCreate: true,
    })
    return quotationId
  }

  @Action
  async createQuotationFromCombination({
    requestId,
    requestItemIds,
    quotationItemIds,
  }: {
    requestId: string
    requestItemIds: Array<string>
    quotationItemIds: Array<string>
  }) {
    const quotationResults = await remote.api.quotationsControllerCombine({
      requestId,
      requestItemIds,
      quotationItemIds,
    })
    const quotationId = normalizedUpdator.normalizeAndUpdate(
      quotationResults.data,
      Quotation.schema
    )
    this._updateMailboxesForQuotation({
      quotation: this.quotationsById[quotationId]!,
      isCreate: true,
    })
    return quotationId
  }

  @Action
  async updateQuotationPartial(
    quotationUpdate: PartialRemoteModel<UpdateQuotationDto & { id: string }>
  ) {
    this._setQuotationPartial(quotationUpdate as PartialRemoteModel<Quotation>)
    this._updateMailboxesForQuotation({
      quotation: this.quotationsById[quotationUpdate.id]!,
      isCreate: false,
    })
    await remote.api.quotationsControllerUpdate(
      quotationUpdate.id,
      quotationUpdate as UpdateQuotationDto
    )
  }

  @Action
  async deleteQuotation(quotationId: string) {
    this._setQuotationDeleted(quotationId)
    mailboxStore.handleQuotationDelete(quotationId)
    tabStore.closeQuotationTab(quotationId)
    await remote.api.quotationsControllerRemove(quotationId)
  }

  @Action
  _handleQuotationUpdate({
    model,
    isLocal,
  }: {
    model: Quotation
    isLocal?: boolean
  }) {
    this._setQuotation({ model, isLocal })
    this._updateMailboxesForQuotation({ quotation: model, isCreate: false })
  }

  @Action
  _handleWorkflowStepUpdate({
    model,
    isLocal,
  }: {
    model: WorkflowStep
    isLocal?: boolean
  }) {
    this._setWorkflowStep({ model, isLocal })
    this._updateMailboxesForWorkflowStep(model)
  }

  @Action
  _updateMailboxesForQuotation({
    quotation,
    isCreate,
  }: {
    quotation: Quotation
    isCreate: boolean
  }) {
    // only update after all other updates applied
    setTimeout(() => {
      mailboxStore.handleQuotationUpdate({ quotation, isCreate })
      mailboxStore.handleQuotationRequestUpdate({
        quotationRequest: quotation.request,
        isCreate: false,
      })
    })
  }

  @Action
  _updateMailboxesForWorkflowStep(workflowStep: WorkflowStep) {
    // only update after all other updates applied
    setTimeout(() => {
      const quotation = this.getQuotationById(workflowStep.quotationId)!
      mailboxStore.handleQuotationUpdate({ quotation, isCreate: false })
      mailboxStore.handleQuotationRequestUpdate({
        quotationRequest: quotation.request,
        isCreate: false,
      })
    })
  }

  @Action
  async createQuotationItemFromRequestItem({
    quotationId,
    quotationRequestItemId,
  }: {
    quotationId: string
    quotationRequestItemId: string
  }) {
    const quotationItemResults =
      await remote.api.quotationItemsControllerCreate(quotationId, {
        requestItemId: quotationRequestItemId,
      })
    normalizedUpdator.normalizeAndUpdate(
      quotationItemResults.data,
      QuotationItem.schema
    )
  }

  @Action
  async createQuotationItem(quotationId: string) {
    const quotationItemResults =
      await remote.api.quotationItemsControllerCreate(quotationId, {})
    normalizedUpdator.normalizeAndUpdate(
      quotationItemResults.data,
      QuotationItem.schema
    )
  }

  @Action
  async deleteQuotationItem(quotationItemId: string) {
    this._setQuotationItemDeleted(quotationItemId)
    await remote.api.quotationItemsControllerRemove(quotationItemId)
  }

  @Action
  async updateQuotationItemPartial(
    quotationItemUpdate: UpdateQuotationItemDto & { id: string }
  ) {
    this._setQuotationItemPartial(
      quotationItemUpdate as PartialRemoteModel<QuotationItem>
    )
    await remote.api.quotationItemsControllerUpdate(
      quotationItemUpdate.id,
      quotationItemUpdate
    )
  }

  @Action
  updateItemPrice(priceUpdate: QuotationItemPrice & { id: string }) {
    const partPrice = {
      salePrice: undefined,
      purchasePrice: undefined,
      partName: undefined,
      basePrice: undefined,
      fixedPriceSettings: undefined,
      salesPriceSettings: undefined,
      purchasePriceSettings: undefined,

      ...priceUpdate,
    }

    const partial = {
      id: priceUpdate.id,
      partPrice: partPrice as QuotationItemPrice,
    }

    this._setQuotationItemPartial(partial)
  }

  @Action
  async updateItemFixedPrice(
    fixedPriceUpdate: QuotationItemPriceFixedDto & { id: string }
  ) {
    if (
      !isPositive(fixedPriceUpdate.salePrice) ||
      !isPositive(fixedPriceUpdate.purchasePrice) ||
      !isPositive(fixedPriceUpdate.purchasePrice) ||
      !isValidDbInteger(fixedPriceUpdate.salePrice) ||
      !isValidDbInteger(fixedPriceUpdate.purchasePrice) ||
      !isValidAdditionalCosts(fixedPriceUpdate.additionalCosts)
    ) {
      return
    }

    this.updateItemPrice(fixedPriceUpdate)

    await remote.api.quotationItemsControllerUpdatePriceFixed(
      fixedPriceUpdate.id,
      fixedPriceUpdate
    )
  }

  @Action
  async updateItemAutoPrice(
    autoPriceUpdate: QuotationItemPriceAutoDto & { id: string }
  ) {
    if (
      !isPositive(autoPriceUpdate.basePrice) ||
      !isValidDbInteger(autoPriceUpdate.basePrice) ||
      !isPercent(autoPriceUpdate.fixedPriceSettings.percent, true) ||
      !isPercent(autoPriceUpdate.purchasePriceSettings.percent, true) ||
      !isPercent(autoPriceUpdate.salesPriceSettings.percent, true) ||
      !isValidAdditionalCosts(autoPriceUpdate.additionalCosts)
    ) {
      return
    }

    this.updateItemPrice(autoPriceUpdate)

    await remote.api.quotationItemsControllerUpdatePriceAuto(
      autoPriceUpdate.id,
      autoPriceUpdate
    )
  }

  @Action
  async assignApprover({
    quotationId,
    userId,
  }: {
    quotationId: string
    userId: string
  }) {
    const workflowStepResults = await remote.api.quotationsControllerStep(
      quotationId,
      WorkflowStepDtoTypeEnum.Assigned,
      { assigneeId: userId }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async sendBack({
    step,
    revokeStepsAfter,
    timelineStepType,
  }: {
    step: WorkflowStep
    revokeStepsAfter: WorkflowStep[]
    timelineStepType?: TimelineStepType
  }) {
    const revokedStepIds: string[] = revokeStepsAfter.map((s) => s.id)

    const workflowStepResults = await remote.api.quotationsControllerStep(
      step.quotationId,
      WorkflowStepDtoTypeEnum.Returned,
      {
        assigneeId: step.actorId,
        payload: { workflowStepId: step.id, revokedStepIds, timelineStepType },
      }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async regain({
    step,
    revokeStepsAfter,
  }: {
    step: WorkflowStep
    revokeStepsAfter: WorkflowStep[]
  }) {
    const timelineStepType =
      step.type === WorkflowStepDtoTypeEnum.InProgress
        ? TimelineStepType.RegainedInProgress
        : TimelineStepType.RegainedApproved
    return await this.sendBack({ step, revokeStepsAfter, timelineStepType })
  }

  @Action
  async reassignApprover({
    step,
    revokeStepsAfter,
  }: {
    step: WorkflowStep
    revokeStepsAfter: WorkflowStep[]
  }) {
    return await this.sendBack({
      step,
      revokeStepsAfter,
      timelineStepType: TimelineStepType.RevokedApproved,
    })
  }

  @Action
  async removeApprover({ step }: { step: WorkflowStep }) {
    if (
      [
        WorkflowStepDtoTypeEnum.Returned,
        WorkflowStepDtoTypeEnum.Assigned,
      ].includes(step.type)
    ) {
      const order = [
        WorkflowStepDtoTypeEnum.Returned,
        WorkflowStepDtoTypeEnum.Approved,
        WorkflowStepDtoTypeEnum.Assigned,
      ]
      const stepsToDelete = this.quotationsById[step.quotationId]?.workflowSteps
        .filter(
          (s) =>
            s.actorId === step.actorId &&
            [
              WorkflowStepDtoTypeEnum.Approved,
              WorkflowStepDtoTypeEnum.Assigned,
              WorkflowStepDtoTypeEnum.Returned,
            ].includes(s.type)
        )
        .sort((a, b) => {
          if (a.type !== b.type) {
            return order.indexOf(a.type) > order.indexOf(b.type) ? 1 : -1
          }

          return a.timestampDate.getTime() - b.timestampDate.getTime()
        })

      if (stepsToDelete) {
        const deletionSucceeded = []
        try {
          for (const s of stepsToDelete) {
            await remote.api.quotationsControllerRemoveStep(s.id)
            deletionSucceeded.push(s.id)
          }
        } catch (e) {
          console.error('Cannot delete workflow step:', e)
        } finally {
          for (const id of deletionSucceeded) {
            this._setWorkflowStepDeleted(id)
          }
        }
      }
    } else {
      await this.deleteWorkflowStep(step.id)
    }
  }

  @Action
  async approveQuotation({
    quotationId,
    payload,
  }: {
    quotationId: string
    payload?: { workflowStepId?: string; timelineStepType?: TimelineStepType }
  }) {
    const workflowStepResults = await remote.api.quotationsControllerStep(
      quotationId,
      WorkflowStepDtoTypeEnum.Approved,
      { assigneeId: userStore.ownUserId!, payload }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async readyQuotation(quotationId: string) {
    const workflowStepResults = await remote.api.quotationsControllerStep(
      quotationId,
      WorkflowStepDtoTypeEnum.Ready,
      { assigneeId: userStore.ownUserId! }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async inProgressQuotation(quotationId: string) {
    const workflowStepResults = await remote.api.quotationsControllerStep(
      quotationId,
      WorkflowStepDtoTypeEnum.InProgress,
      { assigneeId: userStore.ownUserId! }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async deleteWorkflowStep(workflowStepId: string) {
    try {
      await remote.api.quotationsControllerRemoveStep(workflowStepId)
      this._setWorkflowStepDeleted(workflowStepId)
    } catch (e) {
      console.error('Cannot delete workflow step:', e)
    }
  }

  @Action
  async resetWorkflowSteps(quotationId: string) {
    try {
      const response = await remote.api.quotationsControllerResetSteps(
        quotationId
      )
      response.data.forEach((stepId) => {
        this._setWorkflowStepDeleted(stepId)
      })
    } catch (e) {
      console.error('Cannot reset workflow steps:', e)
    }
  }

  @Action
  async sendQuotation(quotationId: string) {
    const workflowStepResults = await remote.api.quotationsControllerStep(
      quotationId,
      WorkflowStepDtoTypeEnum.Sent,
      { assigneeId: userStore.ownUserId! }
    )
    return normalizedUpdator.normalizeAndUpdate(
      workflowStepResults.data,
      WorkflowStep.schema
    )
  }

  @Action
  async sendAsEmail(sendData: SendEmailDto & { quotationId: string }) {
    const { quotationId, ...sendEmailDto } = sendData
    const workflowStepResults =
      await remote.api.quotationsControllerSendAsEmail(
        quotationId,
        sendEmailDto
      )
    if (workflowStepResults.data) {
      normalizedUpdator.normalizeAndUpdate(
        workflowStepResults.data,
        WorkflowStep.schema
      )
      if (
        sendData.changeStep &&
        workflowStepResults.data.type === WorkflowStepDtoTypeEnum.Sent
      ) {
        this._setQuotationPartial({
          id: quotationId,
          isPriority: false,
        })
      }
    }
  }
}
