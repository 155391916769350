// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import {
  ItemDetails,
  QuotationItemDto,
  QuotationItemTypeEnum,
  QuotationItemPriceDto,
} from '~/remote/api-spec'
import { quotationRequestStore, quotationStore } from '~/store'
import { CostInformation } from '~/utils/cost-helper'

export type NormalizedQuotationItem = QuotationItemDto

type QuotationItemType = QuotationItemTypeEnum

export default class QuotationItem
  extends RemoteModel
  implements NormalizedQuotationItem
{
  quotationId!: string
  requestItemId?: string
  details!: ItemDetails
  conditions?: string
  partPrice!: QuotationItemPriceDto
  isAS?: boolean
  factoryQuotationId?: string
  estimatedCustomerResponseDate?: string
  estimatedCustomerResponseModifiedDate?: string
  created_at!: string
  updated_at!: string
  computedCost!: CostInformation
  itemType!: QuotationItemType
  expectedDelivery!: string
  requestBody?: string

  static schema: schema.Entity = new schema.Entity<QuotationItemDto>(
    'quotationItems'
  )

  get quotation() {
    return quotationStore.getQuotationById(this.quotationId)!
  }

  get requestItem() {
    return this.requestItemId
      ? quotationRequestStore.getQuotationRequestItemById(this.requestItemId)
      : undefined
  }
}
