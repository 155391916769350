import { PageView, VueGtag } from 'vue-gtag'

// eslint-disable-next-line
import Vue from 'vue'
import Quotation from '~/models/Quotation'
import QuotationRequest from '~/models/QuotationRequest'
import { teamStore, userStore } from '~/store'

export type QuotationTrackingParams = {
  estiamte_request_status?: string
  estimate_request_id?: string
  customer_company?: string
  customer_team?: string
  reseller_company?: string
  reseller_team?: string
  time_since_estimate_request_creation?: number
  time_since_estimate_request_sent?: number

  estimate_status?: string
  time_since_estimate_creation?: number
  time_since_estimate_sent?: number
}

export class Tracking {
  gtag?: VueGtag

  customerSubscription?: any
  internalUserSubscription?: any

  databaseStartTime?: number
  storeInitStartTime?: number

  // eslint-disable-next-line no-use-before-define
  static sharedInstance: Tracking

  constructor(gtag?: VueGtag) {
    this.gtag = gtag
  }

  static install(app: any) {
    const gtag: VueGtag | undefined = app.prototype.$gtag

    const tracking = new Tracking(gtag)
    app.tracking = tracking
    app.prototype.$tracking = tracking

    Tracking.sharedInstance = tracking
  }

  updateAuthData() {
    if (!this.gtag) {
      return
    }

    const user = userStore.ownUser
    const team = teamStore.ownTeam

    if (user) {
      this.gtag.config({
        user_id: user.id,
      })
    }

    if (team) {
      this.gtag.config({
        company: team.companyName,
        team: team.name,
      })
    }
  }

  trackDataLoadStart() {
    this.databaseStartTime = new Date().getTime()
  }

  trackDataReady() {
    if (!this.databaseStartTime) {
      return
    }
    if (!this.gtag) {
      return
    }

    const databaseReadyTime = new Date().getTime() - this.databaseStartTime
    this.gtag!.event('database ready', { load_time: databaseReadyTime })
  }

  trackDataInit(loadTime: number) {
    if (!this.databaseStartTime) {
      return
    }
    if (!this.gtag) {
      return
    }

    this.gtag!.event('database loaded', { load_time: loadTime })
  }

  trackPageview(pageViewParams: PageView) {
    if (!this.gtag) {
      return
    }

    this.gtag.pageview(pageViewParams)
  }

  trackQuotation(
    eventName: string,
    trackedObject: Quotation | QuotationRequest | undefined | null
  ) {
    if (!this.gtag) {
      return
    }

    this.gtag!.event(eventName, this.makeQuotationParams(trackedObject))
  }

  makeQuotationParams(
    trackedObject: Quotation | QuotationRequest | undefined | null
  ) {
    const quotationParams: QuotationTrackingParams = {}

    let quotation: Quotation | undefined
    let quotationRequest: QuotationRequest | undefined

    if (!trackedObject) {
      return
    } else if (trackedObject instanceof Quotation) {
      quotation = trackedObject
      quotationRequest = trackedObject.request
    } else if (trackedObject instanceof QuotationRequest) {
      quotationRequest = trackedObject
    }

    if (quotationRequest) {
      quotationParams.estiamte_request_status = quotationRequest.status
      quotationParams.estimate_request_id = quotationRequest.externalId

      if (quotationRequest.createdOn) {
        const quotationRequestCreationTime = Date.parse(
          quotationRequest.createdOn
        )
        quotationParams.time_since_estimate_request_creation =
          (new Date().getTime() - quotationRequestCreationTime) / 1000
      }

      if (quotationRequest.sentDate) {
        const quotationRequestSentTime = Date.parse(quotationRequest.sentDate)
        quotationParams.time_since_estimate_request_sent =
          (new Date().getTime() - quotationRequestSentTime) / 1000
      }

      quotationParams.customer_company =
        quotationRequest.customerTeam.companyName
      quotationParams.customer_team = quotationRequest.customerTeam.name
    }

    if (quotation) {
      quotationParams.estimate_status = quotation.status

      if (quotation.createdOn) {
        const quotationCreationTime = Date.parse(quotation.createdOn)
        quotationParams.time_since_estimate_creation =
          (new Date().getTime() - quotationCreationTime) / 1000
      }

      if (quotation.sentDate) {
        const quotationSentTime = quotation.sentDate
        quotationParams.time_since_estimate_sent =
          (new Date().getTime() - quotationSentTime.getTime()) / 1000
      }

      quotationParams.reseller_company = quotation.internalTeam.companyName
      quotationParams.reseller_team = quotation.internalTeam.name
    }

    return quotationParams
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $tracking: Tracking
  }
}
