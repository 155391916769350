












import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class SearchTabEmpty extends Vue {
  @Prop({ default: 'bc-primary-1' }) readonly underlineColor!: string
  @Prop({ required: true }) readonly tabindex!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ default: 0 }) readonly totalCount!: number
}
