import { Module, Mutation, Action } from 'vuex-module-decorators'

import Vue from 'vue'
import globalAxios, { CancelTokenSource, AxiosRequestConfig } from 'axios'
import BaseRemoteStore, {
  RemoteFieldQuery,
  RemoteFieldQueryDict,
  RemoteIdDict,
} from './BaseRemoteStore'
import Attachment from '~/models/Attachment'
import {
  AttachmentDtoParentTypeEnum,
  AttachmentDtoVisibilityEnum,
  UpdateAttachmentDto,
} from '~/remote/api-spec'
import normalizedUpdator from '~/utils/normalizedUpdator'
import remote from '~/remote'
import { PartialRemoteModel } from '~/utils/store'
import { OnProgressCallbackFn } from '~/modules'

@Module({
  name: 'AttachmentStore',
  namespaced: true,
  stateFactory: true,
})
export default class AttachmentStore extends BaseRemoteStore {
  readonly attachmentsById: RemoteIdDict<Attachment> = {}
  readonly attachmentsByParentId: RemoteFieldQueryDict<Attachment> = {}

  readonly cancellationTokens: Record<string, CancelTokenSource> = {}

  _parentReferenceField: RemoteFieldQuery<Attachment> = {
    field: 'parentId',
    fieldDict: this.attachmentsByParentId,
  }

  get getAttachmentsForIds() {
    return (ids: string[]) => ids.map((id) => this.attachmentsById[id]!)
  }

  get getAttachmentById() {
    return BaseRemoteStore.getById(this.attachmentsById)
  }

  get getAttachmentsByParentId() {
    return BaseRemoteStore.getListByField(this.attachmentsByParentId)
  }

  get getAttachmentsByMessageId() {
    return BaseRemoteStore.getListByField(this.attachmentsByParentId)
  }

  get getAttachmentsByQuotationId() {
    return (quotationId: string) =>
      BaseRemoteStore.getListByField(this.attachmentsByParentId)(
        quotationId
      ).filter(
        (attachment) =>
          attachment.parentType === AttachmentDtoParentTypeEnum.Quotation
      )
  }

  get getAttachmentsByQuotationRequestItemId() {
    return BaseRemoteStore.getListByField(this.attachmentsByParentId)
  }

  get getAttachmentsByQuotationItemId() {
    return BaseRemoteStore.getListByField(this.attachmentsByParentId)
  }

  @Mutation
  _setAttachment({
    model,
    isLocal,
  }: {
    model: Attachment
    isLocal?: boolean
  }): void {
    BaseRemoteStore.setById(
      this.attachmentsById,
      model,
      isLocal ?? true,
      this._parentReferenceField
    )
  }

  @Mutation
  _setAttachmentPartial(attachment: PartialRemoteModel<Attachment>): void {
    BaseRemoteStore.setPartialById(
      this.attachmentsById,
      attachment,
      this._parentReferenceField
    )
  }

  @Mutation
  _setAttachmentDeleted(attachmentId: string): void {
    BaseRemoteStore.deleteById(
      this.attachmentsById,
      attachmentId,
      this._parentReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.attachmentsById, this.attachmentsByParentId)
  }

  @Mutation
  _addCancelationToken(key: string) {
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = globalAxios.CancelToken.source()
    Vue.set(this.cancellationTokens, key, cancelTokenSource)
  }

  @Mutation
  _removeCancelationToken(key: string) {
    Vue.delete(this.cancellationTokens, key)
  }

  @Action
  async loadAttachmentsForParent({
    parentId,
    parentType,
  }: {
    parentId: string
    parentType: AttachmentDtoParentTypeEnum
  }) {
    const attachments = await remote.api.attachmentsControllerFindByParent(
      parentType,
      parentId
    )
    const attachmentIds = normalizedUpdator.normalizeAndUpdateArray(
      attachments.data,
      Attachment.arraySchema
    )
    BaseRemoteStore.purgeMissingByField(
      attachmentIds,
      parentId,
      this._parentReferenceField,
      this._setAttachmentDeleted
    )
    return attachmentIds
  }

  @Action
  async loadAttachmentsForMessage(messageId: string) {
    return await this.loadAttachmentsForParent({
      parentId: messageId,
      parentType: AttachmentDtoParentTypeEnum.Message,
    })
  }

  @Action
  async loadAttachmentsForQuotation(quotationId: string) {
    return await this.loadAttachmentsForParent({
      parentId: quotationId,
      parentType: AttachmentDtoParentTypeEnum.Quotation,
    })
  }

  @Action
  async loadAttachmentsForQuotationItem(quotationItemId: string) {
    return await this.loadAttachmentsForParent({
      parentId: quotationItemId,
      parentType: AttachmentDtoParentTypeEnum.QuotationItem,
    })
  }

  @Action
  async loadAttachmentsForQuotationRequestItem(quotationRequestItemId: string) {
    return await this.loadAttachmentsForParent({
      parentId: quotationRequestItemId,
      parentType: AttachmentDtoParentTypeEnum.RequestItem,
    })
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      Attachment.schema,
      Attachment,
      this._setAttachment
    )
  }

  @Action
  async createAttachmentForParent({
    name,
    key,
    parentId,
    parentType,
    customerVisible,
    file,
    onUploadProgress,
  }: {
    name: string
    key: string
    parentId?: string
    parentType: AttachmentDtoParentTypeEnum
    customerVisible: Boolean
    file: File
    onUploadProgress?: OnProgressCallbackFn
  }) {
    const options: AxiosRequestConfig = {}
    this._addCancelationToken(key)
    try {
      if (onUploadProgress) {
        options.onUploadProgress = onUploadProgress
      }
      const attachmentResults = await remote.api.attachmentsControllerCreate(
        parentType,
        name,
        key,
        file,
        parentId,
        customerVisible
          ? AttachmentDtoVisibilityEnum.Customer
          : AttachmentDtoVisibilityEnum.Internal,
        options
      )
      return normalizedUpdator.normalizeAndUpdate(
        attachmentResults.data,
        Attachment.schema
      )
    } finally {
      this._removeCancelationToken(key)
    }
  }

  @Action
  cancelUpload(key: string) {
    const token = this.cancellationTokens[key]
    if (token) {
      token.cancel()
      this._removeCancelationToken(key)
      return true
    }

    return false
  }

  @Action
  async getDownloadLink(id: string) {
    const result = await remote.api.attachmentsControllerDownload(id)
    return result.data
  }

  @Action
  handleAttachmentUpdate(attachmentUpdate: PartialRemoteModel<Attachment>) {
    this._setAttachmentPartial(
      attachmentUpdate as any as PartialRemoteModel<Attachment>
    )
  }

  @Action
  async updateAttachmentPartial(
    attachmentUpdate: UpdateAttachmentDto & { id: string }
  ) {
    this._setAttachmentPartial(
      attachmentUpdate as any as PartialRemoteModel<Attachment>
    )
    await remote.api.attachmentsControllerUpdate(
      attachmentUpdate.id,
      attachmentUpdate
    )
  }

  @Action
  async deleteAttachment(attachmentId: string) {
    this._setAttachmentDeleted(attachmentId)
    await remote.api.attachmentsControllerRemove(attachmentId)
  }
}
