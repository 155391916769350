// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import { PhoneDto, PhoneDtoTypeEnum } from '~/remote/api-spec'

export type NormalizedPhone = PhoneDto

const phoneSchema = new schema.Entity<PhoneDto>('phones')

export default class PhoneNumber
  extends RemoteModel
  implements NormalizedPhone
{
  id!: string
  number!: string
  type!: PhoneDtoTypeEnum
  primary!: boolean

  static schema: schema.Entity = phoneSchema
  static arraySchema: schema.Array = new schema.Array(phoneSchema)
}
