import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { mailboxStore } from '.'
import QuotationMailboxItem from '~/models/QuotationMailboxItem'
import QuotationRequestMailboxItem from '~/models/QuotationRequestMailboxItem'
import { authStore, quotationRequestStore } from '~/utils/store-accessor'

export enum OpenTabType {
  QuotationDraft = 'QuotationDraft',
  Quotation = 'Quotation',
  QuotationRequest = 'QuotationRequest',
}
export class OpenTab {
  itemId: string
  itemType: OpenTabType

  constructor(id: string, type: OpenTabType) {
    this.itemId = id
    this.itemType = type
  }

  get id() {
    return `${this.itemId}_${this.itemType}`
  }
}

function processTabByType<T = void>(
  tab: OpenTab,
  processQuotation: (quotation: QuotationMailboxItem | undefined) => T,
  processQuotationRequest: (
    quotationRequest: QuotationRequestMailboxItem | undefined
  ) => T,
  processQuotationDraft: () => T,
  other: () => T
) {
  if (tab.itemType === OpenTabType.Quotation) {
    const quotation = mailboxStore.getQuotationMailboxItemsByQuotationId(
      tab.itemId
    )
    return processQuotation(quotation)
  } else if (tab.itemType === OpenTabType.QuotationRequest) {
    const quotationRequest =
      mailboxStore.getQuotationRequestMailboxItemsByQuotationRequestId(
        tab.itemId
      )
    return processQuotationRequest(quotationRequest)
  } else if (tab.itemType === OpenTabType.QuotationDraft) {
    return processQuotationDraft()
  } else {
    return other()
  }
}

@Module({
  name: 'TabStore',
  namespaced: true,
  stateFactory: true,
})
export default class TabStore extends VuexModule {
  mailboxFilterTab = 0
  mailboxOpenTray = -1
  activeTabIndex = -1
  tabs: OpenTab[] = []

  get getTabTitle() {
    const defaultTitle = 'NO TITLE'
    return (tab: OpenTab) =>
      processTabByType(
        tab,
        (quotation) => {
          if (quotation) {
            return quotation.externalId
          }
          return defaultTitle
        },
        (quotationRequest) => {
          if (quotationRequest) {
            return quotationRequest.externalId
          }
          return defaultTitle
        },
        () => {
          return '下書き'
        },
        () => defaultTitle
      )
  }

  get getUIClass() {
    return (tab: OpenTab) =>
      processTabByType(
        tab,
        (quotationMailboxItem) => {
          return quotationMailboxItem?.colorClass
        },
        (quotationRequestMailboxItem) => {
          return quotationRequestMailboxItem?.colorClass
        },
        () => {
          return 'in-progress'
        },
        () => undefined
      )
  }

  get getActiveTabUIClass() {
    const tab = this.getActiveTab
    if (!tab) return undefined
    return this.getUIClass(tab)
  }

  get getActiveTab() {
    if (this.activeTabIndex > -1 && this.activeTabIndex < this.tabs.length) {
      return this.tabs[this.activeTabIndex]
    }
    return undefined
  }

  get getActiveItem() {
    if (!this.getActiveTab) return undefined
    return processTabByType<
      QuotationMailboxItem | QuotationRequestMailboxItem | undefined
    >(
      this.getActiveTab,
      (mailboxItem) => mailboxItem,
      (mailboxItem) => mailboxItem,
      () => undefined,
      () => undefined
    )
  }

  @Mutation
  setMailboxOpenTray(index: number) {
    this.mailboxOpenTray = index
  }

  @Mutation
  setMailboxFilterTab(index: number) {
    this.mailboxFilterTab = index
  }

  @Mutation
  setActiveTabIndex(index: number) {
    this.activeTabIndex = index
  }

  @Mutation
  removeTabOnIndex({
    index,
    setActiveIndexOrTab = undefined,
  }: {
    index: number
    setActiveIndexOrTab: number | OpenTab | undefined
  }) {
    this.tabs = this.tabs.filter((_value, itemIndex) => itemIndex !== index)
    if (setActiveIndexOrTab) {
      if (setActiveIndexOrTab instanceof OpenTab) {
        this.activeTabIndex = this.tabs.findIndex(
          (tab) => tab.id === setActiveIndexOrTab.id
        )
      } else {
        this.activeTabIndex = setActiveIndexOrTab
      }
    }
  }

  @Mutation
  updateTabOnIndex({ index, tab }: { index: number; tab: OpenTab }) {
    this.tabs = this.tabs.map((t, i) => {
      if (i === index) {
        return tab
      }
      return t
    })
  }

  @Mutation
  addNewActiveTab({ id, type }: { id: string; type: OpenTabType }) {
    const newOpenTab = new OpenTab(id, type)
    this.tabs = [...this.tabs, newOpenTab]
    this.activeTabIndex = this.tabs.findIndex(
      (item) => item.id === newOpenTab.id
    )
  }

  @Mutation
  clearTabs() {
    this.tabs = []
  }

  @Mutation
  clearData(): void {
    this.mailboxFilterTab = 0
    this.mailboxOpenTray = -1
    this.activeTabIndex = -1
    this.tabs = []
  }

  @Action
  openQuotationDraftTab() {
    this.openTab({ id: '', type: OpenTabType.QuotationDraft })
  }

  @Action
  updateQuotationDraftTab(quotationId: string) {
    const tabIndex = this.tabs.findIndex(
      (tab) => tab.itemType === OpenTabType.QuotationDraft
    )

    this.updateTabOnIndex({
      index: tabIndex,
      tab: new OpenTab(quotationId, OpenTabType.Quotation),
    })
  }

  /**
   * For the internal users,
   *    When a request has already been accepted, open the quotation tab.
   *    If not, open the request tab.
   * For the customer users,
   *    When a request has already been resolved, open the quotation tab.
   *    If not, open the request tab.
   */
  @Action
  async openQuotationOrRequestTab(requestId: string) {
    await quotationRequestStore.loadQuotationRequest(requestId)
    const request = quotationRequestStore.getQuotationRequestById(requestId)
    if (!request) {
      console.error(`Request does not exist. requestId: ${requestId}`)
      return
    }
    if (request.canOpenQuotation(authStore.getIsInternal)) {
      const originalQuotation = request.originalQuotation
      if (originalQuotation) {
        this.openQuotationTab(originalQuotation.id)
      } else {
        console.error(
          `Original quotation does not exist. requestId: ${requestId}`
        )
      }
    } else {
      this.openQuotationRequestTab(request.id)
    }
  }

  @Action
  openQuotationTab(quotationId: string) {
    this.openTab({ id: quotationId, type: OpenTabType.Quotation })
  }

  @Action
  openQuotationRequestTab(quotationRequestId: string) {
    this.openTab({ id: quotationRequestId, type: OpenTabType.QuotationRequest })
  }

  @Action
  openTab({ id, type }: { id: string; type: OpenTabType }) {
    const tabIndex = this.tabs.findIndex(
      (tab) => tab.itemId === id && tab.itemType === type
    )
    if (tabIndex > -1) {
      this.setActiveTabIndex(tabIndex)
      return
    }
    this.addNewActiveTab({ id, type })
  }

  @Action
  closeDraftQuotationTab() {
    for (const tab of this.tabs) {
      if (tab.itemType === OpenTabType.QuotationDraft) {
        this.closeTab(tab)
      }
    }
  }

  @Action
  closeQuotationTab(quotationId: string) {
    for (const tab of this.tabs) {
      if (
        tab.itemType === OpenTabType.Quotation &&
        tab.itemId === quotationId
      ) {
        this.closeTab(tab)
      }
    }
  }

  @Action
  closeQuotationRequestTab(quotationRequestId: string) {
    for (const tab of this.tabs) {
      if (
        tab.itemType === OpenTabType.QuotationRequest &&
        tab.itemId === quotationRequestId
      ) {
        this.closeTab(tab)
      }
    }
  }

  @Action
  closeTab(tab: OpenTab) {
    const activeTab = this.getActiveTab
    if (activeTab && activeTab.id === tab.id) {
      const index = this.activeTabIndex
      this.removeTabOnIndex({
        index,
        setActiveIndexOrTab:
          index + 1 === this.tabs.length ? index - 1 : undefined,
      })
    } else {
      const index = this.tabs.findIndex((item) => item.id === tab.id)
      this.removeTabOnIndex({ index, setActiveIndexOrTab: activeTab })
    }
  }

  @Action
  clearStore() {
    this.setActiveTabIndex(-1)
    this.setMailboxFilterTab(0)
    this.setMailboxOpenTray(-1)
    this.clearTabs()
  }
}
