




















































import { Vue, Component } from 'nuxt-property-decorator'
import { authStore } from '~/store'

@Component
export default class Settings extends Vue {
  async logout() {
    authStore.clearAuthState()
    await this.$auth.logout()
    this.$auth.redirect('login')
  }
}
