import Vuetify from 'vuetify'

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 960,
      sm: 1024,
      md: 1366,
      lg: 1440,
    },
  },
})
