// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import RemoteModel from './RemoteModel'
import {
  UserDto,
  UserDtoRoleEnum,
  UserName,
  UserDtoStatusEnum,
} from '~/remote/api-spec'
import { teamStore } from '~/store'

export type NormalizedUser = UserDto

export default class User extends RemoteModel implements NormalizedUser {
  managedTeamsIds!: Array<string>
  teamId!: string
  email!: string
  name!: UserName
  role!: UserDtoRoleEnum
  seal?: string
  toCustomerEmails!: boolean
  ccTeamIds!: Array<string>
  status!: UserDtoStatusEnum

  static schema: schema.Entity<UserDto> = new schema.Entity<UserDto>('users')
  static arraySchema = new schema.Array(User.schema)

  get fullName() {
    return [this.name.familyName, this.name.givenNames]
      .filter((namePart) => !!namePart)
      .join(' ')
  }

  get givenNames() {
    return this.name.givenNames
  }

  get familyName() {
    return this.name.familyName
  }

  get initials() {
    return this.name.initials
  }

  get managedTeams() {
    return this.managedTeamsIds.map(
      (teamId: string) => teamStore.getTeamByTeamId(teamId)!
    )
  }
}
