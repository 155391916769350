import { render, staticRenderFns } from "./SearchTabContent.vue?vue&type=template&id=317659ae&scoped=true&"
import script from "./SearchTabContent.vue?vue&type=script&lang=ts&"
export * from "./SearchTabContent.vue?vue&type=script&lang=ts&"
import style0 from "./SearchTabContent.vue?vue&type=style&index=0&id=317659ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317659ae",
  null
  
)

/* custom blocks */
import block0 from "./SearchTabContent.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MailboxItem: require('/codebuild/output/src3077078224/src/frontend/components/mailbox/MailboxItem.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VVirtualScroll})
