import { Module, Mutation, Action } from 'vuex-module-decorators'
import BaseRemoteStore from './BaseRemoteStore'
import PhoneNumber from '~/models/PhoneNumber'
import Team from '~/models/Team'
import Response from '~/remote/response'
import remote from '~/remote'
import normalizedUpdator from '~/utils/normalizedUpdator'
import { CreateTeamDto, TeamDto, UpdateTeamDto } from '~/remote/api-spec'
import { PartialRemoteModel } from '~/utils/store'

@Module({
  name: 'TeamStore',
  namespaced: true,
  stateFactory: true,
})
export default class TeamStore extends BaseRemoteStore {
  readonly teamsById: { [key: string]: Team } = {}
  readonly phoneNumbersById: { [key: string]: PhoneNumber } = {}
  ownTeamId: string | null = null

  get ownTeam() {
    return this.ownTeamId ? this.teamsById[this.ownTeamId] : undefined
  }

  get getTeamByTeamId() {
    return BaseRemoteStore.getById(this.teamsById)
  }

  get allTeams() {
    return BaseRemoteStore.listModels(this.teamsById)
  }

  get getPhoneNumberById() {
    return BaseRemoteStore.getById(this.phoneNumbersById)
  }

  @Mutation
  _setTeam({ model, isLocal }: { model: Team; isLocal?: boolean }) {
    return BaseRemoteStore.setById(this.teamsById, model, isLocal ?? true)
  }

  @Mutation
  _setTeamPartial(teamPartial: PartialRemoteModel<Team>) {
    BaseRemoteStore.setPartialById(this.teamsById, teamPartial)
  }

  @Mutation
  _setOwnTeamId(teamId: string) {
    this.ownTeamId = teamId
  }

  @Mutation
  _setTeamDeleted(teamId: string) {
    return BaseRemoteStore.deleteById(this.teamsById, teamId)
  }

  @Mutation
  _setPhoneNumber({
    model,
    isLocal,
  }: {
    model: PhoneNumber
    isLocal?: boolean
  }) {
    return BaseRemoteStore.setById(
      this.phoneNumbersById,
      model,
      isLocal ?? true
    )
  }

  @Mutation
  _setPhoneNumberDeleted(phoneNumberId: string) {
    return BaseRemoteStore.deleteById(this.phoneNumbersById, phoneNumberId)
  }

  @Mutation
  clearOwnTeam() {
    this.ownTeamId = null
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.teamsById, this.phoneNumbersById)
    this.ownTeamId = null
  }

  @Action
  async createTeam(createTeam: CreateTeamDto) {
    try {
      const teamObj = await remote.api.teamsControllerCreate(createTeam)
      const newTeamId = normalizedUpdator.normalizeAndUpdate(
        teamObj.data,
        Team.schema
      )
      const resultTeam: Team = BaseRemoteStore.getById(this.teamsById)(
        newTeamId
      )!
      return new Response(resultTeam)
    } catch (e: any) {
      return new Response<Team>(e)
    }
  }

  @Action
  async updateTeamPartial(teamUpdate: UpdateTeamDto & { id: string }) {
    try {
      await remote.api.teamsControllerUpdate(teamUpdate.id, teamUpdate)
      this._setTeamPartial(teamUpdate as PartialRemoteModel<Team>)
      return new Response<TeamDto>(teamUpdate as any as TeamDto)
    } catch (e: any) {
      return new Response<TeamDto>(e)
    }
  }

  @Action
  async loadTeam(teamId: string) {
    const teamResult = await remote.api.teamsControllerFind(teamId)
    return normalizedUpdator.normalizeAndUpdate(teamResult.data, Team.schema)
  }

  @Action
  async loadOwnTeam(teamId: string) {
    const teamResult = await remote.api.teamsControllerFind(teamId)
    normalizedUpdator.normalizeAndUpdate(teamResult.data, Team.schema)
    this._setOwnTeamId(teamId)
  }

  @Action
  async loadAllTeams() {
    const teamResult = await remote.api.teamsControllerFindAll()
    normalizedUpdator.normalizeAndUpdate(teamResult.data, Team.arraySchema)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(Team.schema, Team, this._setTeam)
    normalizedUpdator.registerSchemaListener(
      PhoneNumber.schema,
      PhoneNumber,
      this._setPhoneNumber
    )
  }
}
