// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import Phone from './PhoneNumber'
import RemoteModel from './RemoteModel'
import User from './User'
import { teamStore, userStore } from '~/store'
import {
  Address,
  TeamDto,
  TeamDtoTypeEnum,
  PhoneDtoTypeEnum,
  QuotationTerms,
  UserDtoStatusEnum,
} from '~/remote/api-spec'

export type NormalizedTeam = Omit<
  TeamDto,
  'phones' | 'managedBy' | 'representative'
> & {
  phoneIds: string[]
  managedByIds: string[] | undefined
  representativeId: string | undefined
}

const teamSchema = new schema.Entity<TeamDto>(
  'teams',
  {
    phoneIds: [Phone.schema],
    managedByIds: [User.schema],
    representativeId: User.schema,
    users: [User.schema],
  },
  {
    processStrategy: (value) => ({
      ...value,
      phoneIds: value.phones,
      managedByIds: value.managedBy,
      representativeId: value.representative,
    }),
  }
)

export default class Team extends RemoteModel implements NormalizedTeam {
  phoneIds!: Array<string>
  managedByIds!: Array<string>
  id!: string
  name!: string
  companyName!: string
  prefix!: string
  address!: Address
  defaultQuotationTerms!: QuotationTerms
  representativeId: string | undefined
  type!: TeamDtoTypeEnum
  ccUserIds!: Array<string>

  static schema: schema.Entity = teamSchema
  static arraySchema: schema.Array = new schema.Array(teamSchema)

  get phoneNumbers() {
    return this.phoneIds.map(
      (phoneId) => teamStore.getPhoneNumberById(phoneId)!
    )
  }

  get managedBy() {
    if (!this.managedByIds) return []
    return this.managedByIds.map((userId) => userStore.getUserById(userId)!)
  }

  get users() {
    return userStore
      .getTeamUsersById(this.id)
      .filter((u) => u.status === UserDtoStatusEnum.Active)
  }

  get userIds() {
    return this.users.map((u) => u.id)
  }

  get representative() {
    return this.representativeId
      ? userStore.getUserById(this.representativeId)
      : undefined
  }

  get isAddressOnly() {
    return this.type === TeamDtoTypeEnum.AddressBook
  }

  get invitedUsers() {
    return userStore
      .getTeamUsersById(this.id)
      .filter((u) => u.status === UserDtoStatusEnum.Invited)
  }

  get blockedUsers() {
    return userStore
      .getTeamUsersById(this.id)
      .filter((u) => u.status === UserDtoStatusEnum.Blocked)
  }

  get phones() {
    return this.phoneNumbers
      .map((phone) => {
        if (
          phone.number === undefined ||
          phone.number === null ||
          phone.number.trim().length === 0
        ) {
          return ''
        }

        let typeString: string
        switch (phone.type) {
          case PhoneDtoTypeEnum.CellPhone:
            typeString = 'CEL'
            break
          case PhoneDtoTypeEnum.Fax:
            typeString = 'FAX'
            break
          case PhoneDtoTypeEnum.IpPhone:
          case PhoneDtoTypeEnum.LandLine:
          default:
            typeString = 'TEL'
        }

        return `${typeString}: ${phone.number}`
      })
      .filter((p) => p.length !== 0)
  }
}
