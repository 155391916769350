// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import { PartPriceItemDto, TeamPartsPriceDto } from '~/remote/api-spec'
import RemoteModel from '~/models/RemoteModel'
import User from '~/models/User'

const teamPartsPriceSchema = new schema.Entity<TeamPartsPriceDto>(
  'customerPartsPrice',
  {
    customer: User.schema,
  }
)

export default class TeamPartsPrice
  extends RemoteModel
  implements TeamPartsPriceDto
{
  teamId!: string
  fixedCostsName!: string
  fixedCosts!: number
  showFixedCosts!: boolean
  showSpecialDiscount!: boolean
  showSalesRate!: boolean
  items!: Array<PartPriceItemDto>
  created_at!: string

  static schema: schema.Entity = teamPartsPriceSchema
  static arraySchema: schema.Array = new schema.Array(teamPartsPriceSchema)
}
