import { Plugin } from '@nuxt/types'
import {
  CognitoUser,
  CognitoUserPool,
  ICognitoUserData,
} from 'amazon-cognito-identity-js'
import { CognitoStrategyOptions } from '~/types'

declare module 'vue/types/vue' {
  // this.$getCognitoUser inside Vue components
  interface Vue {
    $getCognitoUser(email: string): CognitoUser
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$getCognitoUser inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $getCognitoUser(email: string): CognitoUser
  }
  // nuxtContext.$getCognitoUser
  interface Context {
    $getCognitoUser(email: string): CognitoUser
  }
}

declare module 'vuex/types/index' {
  // this.$getCognitoUser inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $getCognitoUser(email: string): CognitoUser
  }
}

const cognitoPlugin: Plugin = (context, inject) => {
  inject('getCognitoUser', (email: string) => {
    const cognitoOptions = context.$auth.strategies.cognito
      ?.options as any as CognitoStrategyOptions
    const poolData = {
      UserPoolId: cognitoOptions.credentials.userPoolId,
      ClientId: cognitoOptions.credentials?.userPoolWebClientId, // Your client id here
    }
    const userPool = new CognitoUserPool(poolData)

    const userData: ICognitoUserData = {
      Username: email,
      Pool: userPool,
    }

    return new CognitoUser(userData)
  })
}

export default cognitoPlugin
