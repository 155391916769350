









import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class SearchTabEmpty extends Vue {}
