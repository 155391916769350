import { Module, Mutation, Action } from 'vuex-module-decorators'
import { plainToInstance } from 'class-transformer'
import BaseRemoteStore, { RemoteIdDict } from './BaseRemoteStore'
import { teamStore, userStore } from '.'
import Quotation from '~/models/Quotation'
import QuotationMailboxItem, {
  mailboxSchema,
  MailboxStatus,
} from '~/models/QuotationMailboxItem'
import QuotationRequest from '~/models/QuotationRequest'
import QuotationRequestMailboxItem from '~/models/QuotationRequestMailboxItem'
import remote from '~/remote'
import {
  QuotationRequestDtoStatusEnum,
  WorkflowStepDtoTypeEnum,
} from '~/remote/api-spec'
import normalizedUpdator from '~/utils/normalizedUpdator'
import { PartialRemoteModel } from '~/utils/store'

@Module({
  name: 'MailboxStore',
  namespaced: true,
  stateFactory: true,
})
export default class MailboxStore extends BaseRemoteStore {
  isLoaded: boolean = false
  readonly quotationMailboxItemsById: RemoteIdDict<QuotationMailboxItem> = {}

  readonly quotationRequestMailboxItemsById: RemoteIdDict<QuotationRequestMailboxItem> =
    {}

  get quotationMailboxItems() {
    return BaseRemoteStore.listModels(this.quotationMailboxItemsById)
  }

  get getQuotationMailboxItemsByQuotationId() {
    return BaseRemoteStore.getById(this.quotationMailboxItemsById)
  }

  get quotationRequestMailboxItems() {
    return BaseRemoteStore.listModels(this.quotationRequestMailboxItemsById)
  }

  get getQuotationRequestMailboxItemsByQuotationRequestId() {
    return BaseRemoteStore.getById(this.quotationRequestMailboxItemsById)
  }

  get customerInboxTray() {
    return this.quotationMailboxItems
  }

  get customerInProgressTray() {
    return this.quotationRequestMailboxItems.filter(
      (mailboxItem) =>
        mailboxItem.status === QuotationRequestDtoStatusEnum.Creating
    )
  }

  get customerSentTray() {
    return this.quotationRequestMailboxItems.filter(
      (mailboxItem) =>
        mailboxItem.status === QuotationRequestDtoStatusEnum.Complete ||
        mailboxItem.status === QuotationRequestDtoStatusEnum.Quoting
    )
  }

  get internalInboxTray() {
    return this.quotationRequestMailboxItems.filter(
      (mailboxItem) =>
        mailboxItem.status === QuotationRequestDtoStatusEnum.Complete
    )
  }

  get internalInProgressTray() {
    return this.quotationMailboxItems.filter(
      (mailboxItem) => mailboxItem.mailboxStatus === MailboxStatus.IN_PROGRESS
    )
  }

  get internalWaitingTray() {
    return this.quotationMailboxItems.filter(
      (mailboxItem) => mailboxItem.mailboxStatus === MailboxStatus.READY_TO_SEND
    )
  }

  get internalSentTray() {
    return this.quotationMailboxItems.filter(
      (mailboxItem) => mailboxItem.mailboxStatus === MailboxStatus.SENT
    )
  }

  @Mutation
  _setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded
  }

  @Mutation
  _setQuotationMailboxItem({
    model,
    isLocal,
  }: {
    model: QuotationMailboxItem
    isLocal?: boolean
  }) {
    return BaseRemoteStore.setById(
      this.quotationMailboxItemsById,
      model,
      isLocal ?? true
    )
  }

  @Mutation
  _setQuotationMailboxItemPartial(
    quotationMailboxItemPartial: PartialRemoteModel<QuotationMailboxItem>
  ) {
    BaseRemoteStore.setPartialById(
      this.quotationMailboxItemsById,
      quotationMailboxItemPartial
    )
  }

  @Mutation
  _setQuotationMailboxItemDeleted(quotationId: string) {
    return BaseRemoteStore.deleteById(
      this.quotationMailboxItemsById,
      quotationId
    )
  }

  @Mutation
  _setQuotationRequestMailboxItem({
    model,
    isLocal,
  }: {
    model: QuotationRequestMailboxItem
    isLocal?: boolean
  }) {
    return BaseRemoteStore.setById(
      this.quotationRequestMailboxItemsById,
      model,
      isLocal ?? true
    )
  }

  @Mutation
  _setQuotationRequestMailboxItemPartial(
    quotationRequestMailboxItemPartial: PartialRemoteModel<QuotationRequestMailboxItem>
  ) {
    BaseRemoteStore.setPartialById(
      this.quotationRequestMailboxItemsById,
      quotationRequestMailboxItemPartial
    )
  }

  @Mutation
  _setQuotationRequestMailboxItemDeleted(quotationRequestId: string) {
    return BaseRemoteStore.deleteById(
      this.quotationRequestMailboxItemsById,
      quotationRequestId
    )
  }

  @Mutation
  clearData(): void {
    this.isLoaded = false

    BaseRemoteStore.clearDicts(
      this.quotationMailboxItemsById,
      this.quotationRequestMailboxItemsById
    )
  }

  @Action
  handleQuotationMessageReadUpdate(quotationId: string) {
    const existingItem = this.quotationMailboxItemsById[quotationId]
    if (existingItem) {
      this._setQuotationMailboxItemPartial({
        id: quotationId,
        unreadMessages: Math.max(existingItem.unreadMessages - 1, 0),
      })
    }
  }

  @Action
  handleQuotationRequestMessageReadUpdate(quotationRequestId: string) {
    const existingItem =
      this.quotationRequestMailboxItemsById[quotationRequestId]
    if (existingItem) {
      this._setQuotationRequestMailboxItemPartial({
        id: quotationRequestId,
        unreadMessages: Math.max(existingItem.unreadMessages - 1, 0),
      })
    }
  }

  @Action
  handleQuotationUpdate({
    quotation,
    isCreate,
  }: {
    quotation: Quotation
    isCreate: boolean
  }) {
    const existingItem = this.quotationMailboxItemsById[quotation.id]
    if (!existingItem && isCreate) {
      // best effort
      const team = teamStore.getTeamByTeamId(quotation.request.customer.teamId)
      const companyName = team?.companyName ?? ''
      this._setQuotationMailboxItem({
        model: plainToInstance(QuotationMailboxItem, {
          id: quotation.id,
          externalId: quotation.externalId,
          referenceId: quotation.request.referenceId,
          unreadMessages: 0,
          customerId: quotation.request.customer.id,
          customerName: quotation.request.customer.fullName,
          createdOn: new Date().toISOString(),
          sentDate: '',
          isPriority: false,
          archived: false,
          companyName,
          status: WorkflowStepDtoTypeEnum.InProgress,
        }),
      })
    }
    if (existingItem) {
      this._setQuotationMailboxItemPartial({
        id: quotation.id,
        archived: quotation.request.archived,
        externalId: quotation.externalId,
        referenceId: quotation.request.referenceId,
        isPriority: quotation.isPriority,
        sentDate: quotation.sentDate?.toISOString() ?? '',
        status: quotation.status,
      })
    }
  }

  @Mutation
  handleQuotationDelete(quotationId: string) {
    const existingItem = this.quotationMailboxItemsById[quotationId]
    if (existingItem) {
      return BaseRemoteStore.deleteById(
        this.quotationRequestMailboxItemsById,
        quotationId
      )
    }
  }

  @Action
  handleQuotationRequestUpdate({
    quotationRequest,
    isCreate,
  }: {
    quotationRequest: QuotationRequest
    isCreate: boolean
  }) {
    const existingItem =
      this.quotationRequestMailboxItemsById[quotationRequest.id]
    if (!existingItem && isCreate) {
      // best effort
      const team = teamStore.getTeamByTeamId(quotationRequest.customer.teamId)
      const companyName = team?.companyName ?? ''
      this._setQuotationRequestMailboxItem({
        model: plainToInstance(QuotationRequestMailboxItem, {
          id: quotationRequest.id,
          externalId: quotationRequest.externalId,
          referenceId: quotationRequest.referenceId,
          unreadMessages: 0,
          customerId: userStore.ownUserId,
          customerName: userStore.ownUser!.fullName,
          createdOn: new Date().toISOString(),
          sentDate: '',
          isPriority: false,
          archived: false,
          companyName,
          status: QuotationRequestDtoStatusEnum.Creating,
        }),
      })
    }
    if (existingItem) {
      this._setQuotationRequestMailboxItemPartial({
        id: quotationRequest.id,
        archived: quotationRequest.archived,
        externalId: quotationRequest.externalId,
        referenceId: quotationRequest.referenceId,
        sentDate: quotationRequest.sentDate ?? '',
        status: quotationRequest.status,
      })
    }
  }

  @Mutation
  handleQuotationRequestDelete(quotationRequestId: string) {
    const existingItem =
      this.quotationRequestMailboxItemsById[quotationRequestId]
    if (existingItem) {
      return BaseRemoteStore.deleteById(
        this.quotationRequestMailboxItemsById,
        quotationRequestId
      )
    }
  }

  @Action
  async loadMailboxes() {
    const mailboxResults = await remote.api.mailboxControllerGet()
    normalizedUpdator.normalizeAndUpdate(mailboxResults.data, mailboxSchema)
    this._setIsLoaded(true)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      QuotationMailboxItem.schema,
      QuotationMailboxItem,
      this._setQuotationMailboxItem
    )
    normalizedUpdator.registerSchemaListener(
      QuotationRequestMailboxItem.schema,
      QuotationRequestMailboxItem,
      this._setQuotationRequestMailboxItem
    )
  }
}
