// eslint-disable-next-line import/named
import { schema } from 'normalizr'
import {
  NotificationDto,
  NotificationDtoParentTypeEnum,
  NotificationDtoTypeEnum,
  User,
} from '~/remote/api-spec'
import RemoteModel from '~/models/RemoteModel'

const notificationSchema = new schema.Entity<NotificationDto>(
  'notification',
  {}
)

export default class Notification
  extends RemoteModel
  implements NotificationDto
{
  id!: string
  created_at!: string
  updated_at?: string
  userId!: string
  user!: User
  operatorName!: string
  companyName!: string
  type!: NotificationDtoTypeEnum
  parentId!: string
  parentType!: NotificationDtoParentTypeEnum
  message?: string | null
  isRead!: boolean

  static schema: schema.Entity = notificationSchema
  static arraySchema: schema.Array = new schema.Array(notificationSchema)
}
