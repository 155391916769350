import { Module, Mutation, Action } from 'vuex-module-decorators'

import BaseRemoteStore, {
  RemoteIdDict,
  RemoteFieldQueryDict,
  RemoteFieldQuery,
} from './BaseRemoteStore'
import { attachmentStore, mailboxStore, quotationStore } from '.'
import Message from '~/models/Message'
import normalizedUpdator from '~/utils/normalizedUpdator'
import {
  CreateMessageDtoParentTypeEnum,
  CreateMessageDtoStatusEnum,
  MessageDtoParentTypeEnum,
} from '~/remote/api-spec'
import remote from '~/remote'
import { PartialRemoteModel } from '~/utils/store'

@Module({
  name: 'MessageStore',
  namespaced: true,
  stateFactory: true,
})
export default class MessageStore extends BaseRemoteStore {
  readonly messagesById: RemoteIdDict<Message> = {}
  readonly messagesByParentId: RemoteFieldQueryDict<Message> = {}

  _parentReferenceField: RemoteFieldQuery<Message> = {
    field: 'parentId',
    fieldDict: this.messagesByParentId,
  }

  get getMessages() {
    return BaseRemoteStore.listModels(this.messagesById)
  }

  get getMessageById() {
    return BaseRemoteStore.getById(this.messagesById)
  }

  get getMessagesByQuotationRequestId() {
    return BaseRemoteStore.getListByField(this.messagesByParentId)
  }

  get getInternalMessagesByQuotationId() {
    return BaseRemoteStore.getListByField(this.messagesByParentId)
  }

  @Mutation
  _setMessage({ model, isLocal }: { model: Message; isLocal?: boolean }): void {
    BaseRemoteStore.setById(this.messagesById, model, isLocal ?? true, {
      field: 'parentId',
      fieldDict: this.messagesByParentId,
    })
  }

  @Mutation
  _setMessageRemote(message: Message): void {
    BaseRemoteStore.setById(this.messagesById, message, false, {
      field: 'parentId',
      fieldDict: this.messagesByParentId,
    })
  }

  @Mutation
  _setMessagePartial(messagePartial: PartialRemoteModel<Message>): void {
    BaseRemoteStore.setPartialById(
      this.messagesById,
      messagePartial,
      this._parentReferenceField
    )
  }

  @Mutation
  _setMessageDeleted(messageId: string): void {
    BaseRemoteStore.deleteById(
      this.messagesById,
      messageId,
      this._parentReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.messagesById, this.messagesByParentId)
  }

  @Action
  async loadMessagesForQuotationRequest(quotationRequestId: string) {
    const messages = await remote.api.messagesControllerFindByParent(
      MessageDtoParentTypeEnum.Request,
      quotationRequestId
    )
    const messageIds = normalizedUpdator.normalizeAndUpdateArray(
      messages.data,
      Message.arraySchema
    )
    BaseRemoteStore.purgeMissingByField(
      messageIds,
      quotationRequestId,
      this._parentReferenceField,
      this._setMessageDeleted
    )
    return messageIds
  }

  @Action
  async loadInternalMessagesForQuotation(quotationId: string) {
    const messages = await remote.api.messagesControllerFindByParent(
      MessageDtoParentTypeEnum.Quotation,
      quotationId
    )
    const messageIds = normalizedUpdator.normalizeAndUpdateArray(
      messages.data,
      Message.arraySchema
    )
    BaseRemoteStore.purgeMissingByField(
      messageIds,
      quotationId,
      this._parentReferenceField,
      this._setMessageDeleted
    )
    return messageIds
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      Message.schema,
      Message,
      this._setMessage
    )
  }

  @Action
  async createInternalMessageForQuotation({
    quotationId,
    text,
    attachmentIds,
  }: {
    quotationId: string
    text: string
    attachmentIds: string[]
  }) {
    const messageResults = await remote.api.messagesControllerCreate({
      parentId: quotationId,
      parentType: CreateMessageDtoParentTypeEnum.Quotation,
      text,
      status: CreateMessageDtoStatusEnum.Sent,
    })
    if (attachmentIds.length > 0) {
      await remote.api.messagesControllerUpdate(messageResults.data.id, {
        attachmentIds,
      })
    }
    return normalizedUpdator.normalizeAndUpdate(
      messageResults.data,
      Message.schema
    )
  }

  @Action
  async createMessageForQuotationRequest({
    quotationRequestId,
    text,
    attachmentIds,
  }: {
    quotationRequestId: string
    text: string
    attachmentIds: string[]
  }) {
    const messageResults = await remote.api.messagesControllerCreate({
      parentId: quotationRequestId,
      parentType: CreateMessageDtoParentTypeEnum.Request,
      text,
      status: CreateMessageDtoStatusEnum.Sent,
    })
    if (attachmentIds.length > 0) {
      await remote.api.messagesControllerUpdate(messageResults.data.id, {
        attachmentIds,
      })
    }
    const messageId = normalizedUpdator.normalizeAndUpdate(
      messageResults.data,
      Message.schema
    )
    for (const attachmentId of attachmentIds) {
      attachmentStore.handleAttachmentUpdate({
        id: attachmentId,
        parentId: messageId,
      })
    }
    return messageId
  }

  @Action
  async editMessage({ messageId, text }: { messageId: string; text: string }) {
    this._setMessagePartial({
      id: messageId,
      text,
    })
    await remote.api.messagesControllerUpdate(messageId, {
      text,
    })
  }

  @Action
  async markMessageRead(messageId: string) {
    const message = this.getMessageById(messageId)
    if (
      message?.read !== true &&
      message?.parentType === MessageDtoParentTypeEnum.Quotation
    ) {
      mailboxStore.handleQuotationMessageReadUpdate(message.parentId)
    } else if (
      message?.read !== true &&
      message?.parentType === MessageDtoParentTypeEnum.Request
    ) {
      mailboxStore.handleQuotationRequestMessageReadUpdate(message.parentId)
      const quotations = quotationStore.getQuotationsByRequestId(
        message.parentId
      )
      quotations.forEach((quotation) => {
        mailboxStore.handleQuotationMessageReadUpdate(quotation.id)
      })
    }
    this._setMessagePartial({
      id: messageId,
      read: true,
    })
    await remote.api.messagesControllerRead(messageId)
  }

  @Action
  async deleteMessage(messageId: string) {
    this._setMessageDeleted(messageId)
    await remote.api.messagesControllerRemove(messageId)
  }
}
