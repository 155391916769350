import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseRemoteStore, {
  RemoteFieldQuery,
  RemoteFieldQueryDict,
  RemoteIdDict,
} from '~/store/BaseRemoteStore'
import normalizedUpdator from '~/utils/normalizedUpdator'
import remote from '~/remote'
import { TeamTemplate, Template } from '~/components/templates/Template'
import {
  stripId,
  TemplateTemporaryIdPrefix,
} from '~/components/templates/template-utils'

function setTeamId(templates: Template[], teamId: string): void {
  templates.forEach((t) => {
    t.teamId = teamId
  })
}

@Module({
  name: 'TeamTemplateStore',
  namespaced: true,
  stateFactory: true,
})
export default class TeamTemplateStore extends BaseRemoteStore {
  readonly templatesById: RemoteIdDict<TeamTemplate> = {}
  readonly templatesByTeamId: RemoteFieldQueryDict<TeamTemplate> = {}

  _teamReferenceField: RemoteFieldQuery<TeamTemplate> = {
    field: 'teamId',
    fieldDict: this.templatesByTeamId,
  }

  get getTemplatesByTeamId() {
    return BaseRemoteStore.getListByField(this.templatesByTeamId)
  }

  @Action
  registerListeners() {
    normalizedUpdator.registerSchemaListener(
      TeamTemplate.schema,
      TeamTemplate,
      this._setTemplate
    )
  }

  @Mutation
  _setTemplate({ model, isLocal }: { model: TeamTemplate; isLocal?: boolean }) {
    BaseRemoteStore.setById(
      this.templatesById,
      model,
      isLocal ?? true,
      this._teamReferenceField
    )
  }

  @Action
  async loadTeamTemplates(teamId: string): Promise<string[]> {
    const templates = await remote.api.templatesControllerGetTeam(teamId)
    setTeamId(templates.data, teamId)

    const templateIds = normalizedUpdator.normalizeAndUpdateArray(
      templates.data,
      TeamTemplate.arraySchema
    )

    BaseRemoteStore.purgeMissingByField(
      templateIds,
      teamId,
      this._teamReferenceField,
      this._setTemplateDeleted
    )
    return templateIds
  }

  @Action({ rawError: true })
  async saveTeamTemplates({
    teamId,
    templates,
  }: {
    teamId: string
    templates: TeamTemplate[]
  }): Promise<string[]> {
    const updatedTemplates = await remote.api.templatesControllerUpdateTeam(
      teamId,
      stripId(templates, TemplateTemporaryIdPrefix)
    )
    setTeamId(updatedTemplates.data, teamId)

    const templateIds = normalizedUpdator.normalizeAndUpdateArray(
      updatedTemplates.data,
      TeamTemplate.arraySchema
    )

    BaseRemoteStore.purgeMissingById(
      templateIds,
      this.templatesById,
      this._setTemplateDeleted
    )
    return templateIds
  }

  @Mutation
  _setTemplateDeleted(partId: string): void {
    BaseRemoteStore.deleteById(
      this.templatesById,
      partId,
      this._teamReferenceField
    )
  }

  @Mutation
  clearData(): void {
    BaseRemoteStore.clearDicts(this.templatesById)
  }
}
