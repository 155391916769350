//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Footer from '~/components/Footer.vue'

export default {
  name: 'Simple',
  components: {
    Footer,
  },
}
