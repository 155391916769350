var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications-widget-wrapper"},[_c('v-container',{staticClass:"notifications-widget m pl-0",staticStyle:{"text-align":"right","white-space":"nowrap"}},[_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"6","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[(_vm.counter > 0)?_c('div',{staticClass:"marker"},[_c('div',{staticClass:"xxs bold"},[_vm._v("\n              "+_vm._s(_vm.counter)+"\n            ")])]):_vm._e(),_vm._v(" "),_c('img',{staticClass:"mr-1",attrs:{"src":require("assets/notificationswidget/notifications.svg"),"alt":"Notification Icon"}}),_vm._v("\n          "+_vm._s(_vm.$t('notifications'))+"\n        ")])]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_vm._v(" "),_c('v-container',{staticClass:"notification-panel white pa-0"},[_c('v-row',{staticClass:"notification-tabs mx-0 mb-2 pt-3"},[_c('v-col',{staticClass:"pa-0 notification-tabs__tab",class:{
              'notification-tabs__tab--active':
                _vm.selectedFilteringType === 'quotation',
              'notification-tabs__tab--unread': _vm.unreadExists('quotation'),
            }},[_c('v-btn',{staticClass:"full px-0",attrs:{"text":""},on:{"click":function($event){_vm.selectedFilteringType = 'quotation'}}},[_vm._v(_vm._s(_vm.$t('type-quotation'))+"\n            ")])],1),_vm._v(" "),(_vm.isInternal)?_c('v-col',{staticClass:"pa-0 notification-tabs__tab",class:{
              'notification-tabs__tab--active':
                _vm.selectedFilteringType === 'check',
              'notification-tabs__tab--unread': _vm.unreadExists('check'),
            }},[_c('v-btn',{staticClass:"full px-0",attrs:{"text":""},on:{"click":function($event){_vm.selectedFilteringType = 'check'}}},[_vm._v(_vm._s(_vm.$t('type-check'))+"\n            ")])],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"pa-0 notification-tabs__tab",class:{
              'notification-tabs__tab--active':
                _vm.selectedFilteringType === 'customerMessage',
              'notification-tabs__tab--unread':
                _vm.unreadExists('customerMessage'),
            }},[_c('v-btn',{staticClass:"full px-0",attrs:{"text":""},on:{"click":function($event){_vm.selectedFilteringType = 'customerMessage'}}},[_vm._v(_vm._s(_vm.$t('type-message'))+"\n            ")])],1),_vm._v(" "),(_vm.isInternal)?_c('v-col',{staticClass:"pa-0 notification-tabs__tab",class:{
              'notification-tabs__tab--active':
                _vm.selectedFilteringType === 'internalMessage',
              'notification-tabs__tab--unread':
                _vm.unreadExists('internalMessage'),
            }},[_c('v-btn',{staticClass:"full px-0",attrs:{"text":""},on:{"click":function($event){_vm.selectedFilteringType = 'internalMessage'}}},[_vm._v(_vm._s(_vm.$t('type-internal-message')))])],1):_vm._e()],1),_vm._v(" "),_c('v-row',{staticClass:"ma-0 pa-0 mb-2 px-3"},[_c('v-col',{staticClass:"text-body-2 center flex-grow-1 ma-0 pa-0",attrs:{"align-self":"center"}},[_vm._v(_vm._s(_vm.$t('limit-90-days')))]),_vm._v(" "),_c('v-col',{staticClass:"flex-grow-0 ma-0 pa-0"},[_c('v-btn',{attrs:{"outlined":"","text":"","elevation":"0"},on:{"click":_vm.readByTypes}},[_vm._v(_vm._s(_vm.$t('read-all')))])],1)],1),_vm._v(" "),_c('div',{staticClass:"notifications"},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.notifications),function(item){return _c('notifications-list-item',{key:item.id,attrs:{"notification":item,"loaded":_vm.itemLoaded[item.id]},on:{"update:loaded":function($event){return _vm.$set(_vm.itemLoaded, item.id, $event)},"on-click":_vm.readAndOpen,"unread":_vm.unread,"read":_vm.read,"delete-notification":_vm.deleteNotification}})}),_vm._v(" "),_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({ handler: _vm.infiniteScrolling }),expression:"{ handler: infiniteScrolling }",modifiers:{"quiet":true}}]})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }