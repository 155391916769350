import { Middleware, Context } from '@nuxt/types'
import { Tracking } from '~/modules/tracking'
import { authStore, teamStore, userStore } from '~/store'
import { AuthorizationLevel } from '~/store/AuthStore'

const authenticationMiddleWare: Middleware = async (context: Context) => {
  let isAuthorized: boolean

  try {
    if (context.$auth.$state.loggedIn) {
      const { tokenExpired, refreshTokenExpired, isRefreshable } =
        context.$auth.check(true)

      if (refreshTokenExpired) {
        context.$auth.reset()
      } else if (tokenExpired) {
        if (isRefreshable) {
          try {
            await context.$auth.refreshTokens()
          } catch (error) {
            context.$auth.reset()
          }
        } else {
          context.$auth.reset()
        }
      }

      authStore.handleAuthStateChange(
        (context.$auth.strategy as any).token.session
      )

      if (!userStore.ownUser || teamStore.ownTeam) {
        await userStore.loadOwnUser()
        await teamStore.loadOwnTeam(userStore.ownUser!.teamId)
        Tracking.sharedInstance.updateAuthData()
      }

      const authLevel: AuthorizationLevel = context.route!.meta![0].auth
      if (!authStore.getIsAuthorizedForLevel(authLevel)) {
        console.log('authorization level too low')
        isAuthorized = false
      } else {
        isAuthorized = true
      }
    } else {
      console.log('no authenticated user')
      isAuthorized = false
    }
  } catch (e) {
    console.error('Authorization middleware failed')
    console.error(e)
    isAuthorized = false
  }

  if (!isAuthorized && !context.$auth.$state.busy) {
    console.log('not authorized')

    context.$auth.logout()
    authStore.clearAuthState()
    context.$auth.redirect('login')
  }
}

export default authenticationMiddleWare
