//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '~/components/Logo.vue'
import Footer from '~/components/Footer.vue'

export default {
  components: {
    Logo,
    Footer,
  },
}
