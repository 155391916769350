






































































import { Component, Emit, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import Notification from '~/models/Notification'
import {
  NotificationDtoParentTypeEnum,
  NotificationDtoTypeEnum,
} from '~/remote/api-spec'
import {
  authStore,
  quotationRequestStore,
  quotationStore,
} from '~/utils/store-accessor'
import Quotation from '~/models/Quotation'
import QuotationRequest from '~/models/QuotationRequest'

const CHARACTER_LIMIT = 72

@Component
export default class NotificationsListItem extends Vue {
  @Prop({ type: Object })
  public notification!: Notification

  @PropSync('loaded', { type: Boolean }) public isLoaded!: boolean

  public requestId: string = ''
  public externalId: string = ''
  public referenceId: string = ''

  public quotation: Quotation | null = null
  public request: QuotationRequest | null = null

  get title(): string {
    return this.$t('title-' + this.notification.type).toString()
  }

  get notificationClass(): string {
    return this.notification.type
  }

  get isInternal(): boolean {
    return authStore.getIsInternal
  }

  get iconClass(): string {
    return this.notification.type
  }

  get label(): string | null {
    if (this.notification.type === NotificationDtoTypeEnum.PriorityChange) {
      return 'important'
    }

    return null
  }

  get company(): string {
    return this.notification.companyName
  }

  get name(): string {
    return this.notification.operatorName
  }

  get message(): string | null {
    if (!this.notification.message) {
      return null
    }
    const message = this.notification.message
    if (message.length < CHARACTER_LIMIT) {
      return message
    }
    return message.substring(0, CHARACTER_LIMIT - 1) + '…'
  }

  get isUnread(): boolean {
    return !this.notification.isRead
  }

  get icon(): string {
    switch (this.notification.type) {
      case NotificationDtoTypeEnum.NewQuotationRequest:
        return 'mdi-email'
      case NotificationDtoTypeEnum.CustomerMessage:
        return 'mdi-email'
      case NotificationDtoTypeEnum.InternalMessage:
        return 'mdi-email'
      case NotificationDtoTypeEnum.CompleteQuotation:
        return 'mdi-email'
      case NotificationDtoTypeEnum.PriorityChange:
        return 'mdi-alert-circle'
      case NotificationDtoTypeEnum.UserAssignedNew:
        return 'mdi-alert-circle'
      case NotificationDtoTypeEnum.UserAssignedBack:
        return 'mdi-alert-circle'
    }
    return 'mdi-alert-circle'
  }

  get disabled(): boolean {
    return !this.isLoaded
  }

  async mounted() {
    switch (this.notification.parentType) {
      case NotificationDtoParentTypeEnum.Request:
        // TODO: there is a situation that no longer request states
        await quotationRequestStore.loadQuotationRequest(
          this.notification.parentId
        )
        this.request = quotationRequestStore.getQuotationRequestById(
          this.notification.parentId
        )!
        this.externalId = this.request.externalId
        break
      case NotificationDtoParentTypeEnum.Quotation:
        await quotationStore.loadQuotation({
          quotationId: this.notification.parentId,
        })
        this.quotation = quotationStore.getQuotationById(
          this.notification.parentId
        )!
        this.request = this.quotation.request
        this.externalId = this.quotation.externalId
        break
      default:
        return
    }
    this.isLoaded = true
    this.referenceId = this.request.referenceId
  }

  markAsUnread() {
    this.unread()
  }

  markAsRead() {
    this.read()
  }

  @Emit()
  onClick() {
    return {
      notification: this.notification,
      parent: this.quotation || this.request,
    }
  }

  @Emit()
  unread() {
    return this.notification
  }

  @Emit()
  read() {
    return this.notification
  }

  @Emit()
  deleteNotification() {
    return this.notification
  }
}
